import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { logoutUser } from "../../store/actions"
import { Col, Container, FormFeedback, Input, Label, Modal, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateAPIKey,getServerIP } from "helpers/auth_helper";
import { toast } from "react-toastify";

const DeltaSetup = () => {
  const history = useHistory()
  const dispatch = useDispatch();  
  //const translate = useTranslation()
  const [isOpen, setisOpen] = useState(true);
  const [serverIP, setServerIP] = useState("");
  const [formLoader, setformLoader] = useState(false);
  getServerIP().then((res) => {
    if(res && res.data && res.data.response && res.data.response.server_details && res.data.response.server_details.ip_address)
    {
      if(res.data.response.server_details.ip_address !== '')
      {
        setServerIP(res.data.response.server_details.ip_address)
      }
    }
  }).catch(err => {
    console.log("err",err)
  })
  const validateDeltaSetupForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      api_key: '',
      api_secret: '',      
    },
    validationSchema: Yup.object().shape({
      api_key: Yup.string().required(
        "Delta API key is required"
      ),
      api_secret: Yup.string().required(
        "Delta API secret is required"
      ),
      exchange_type: Yup.string().required(
        "Please select an exchange region"
      )
    }),
    onSubmit: async (values) => {
      try
      {
        setformLoader(true);        
        const bodyParams = {          
            appKey : values.api_key,
            appSecret : values.api_secret
        }      
      
        await updateAPIKey(bodyParams).then((res) => {          
          setformLoader(false);                  
          let user = JSON.parse(localStorage.getItem("user"));          
          user.is_deltakey_updated = true;
          localStorage.setItem("user", JSON.stringify(user));            
          toast.success("Welcome "+(user.profile.first_name?user.profile.first_name:"Dood"));
          history.push("/dashboard");
        }).catch(err => {          
          throw err
        })    

    }
    catch(err)
    {      
        setformLoader(false); 
        if (err && err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else if (err.message) {
          toast.error(err.message);
        } else {
          toast.error(err);
        }            
    }
    }
  });
  return (
    <>
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>              
              <div>                  
                  <Modal size="lg" isOpen={isOpen} >
                  <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Delta Exchange API Credentials</h5>                          
                    </div>
                    <form onSubmit={(e) => {
                      e.preventDefault();
                      validateDeltaSetupForm.handleSubmit();
                      return false;
                    }}>

                    <div className="modal-body"> 
                    <div className="mb-3">
                        <div className="alert alert-info mb-3">Make sure you have whitelisted our IP address <b>{serverIP}</b> in your  Delta Exchange API key settings </div> 
                            <Label
                              htmlFor="formrow-exchange-type"
                              md="2"
                              className="col-form-label"
                            >
                              Exchange Region
                            </Label>
                            <Col md="10" className="mt-2">
                            <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exchange_type"
                                  id="india"
                                  value="India"
                                  onChange={validateDeltaSetupForm.handleChange}
                                  onBlur={validateDeltaSetupForm.handleBlur}
                                  checked={validateDeltaSetupForm.values.exchange_type === "India"}
                                />
                                <label className="form-check-label" htmlFor="india">
                                  India
                                </label>

                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exchange_type"
                                  id="global"
                                  value="Global"
                                  onChange={validateDeltaSetupForm.handleChange}
                                  onBlur={validateDeltaSetupForm.handleBlur}
                                  checked={validateDeltaSetupForm.values.exchange_type === "Global"}
                                />
                                <label className="form-check-label" htmlFor="global">
                                  Global
                                </label>
                              </div>
                              
                              {validateDeltaSetupForm.errors.exchange_type ? (
                    <div style={{color:"#f46a6a",width:"100%",marginTop:"0.25rem",fontSize:"80%"}}>{validateDeltaSetupForm.errors.exchange_type}</div>
                  ) : null}
                            </Col>
                            {
                              console.log(validateDeltaSetupForm.errors,"validationnnnnnn")
                            }
                          </div>        
                                    
                      <div className="mb-3">
                        {/*get client ip and display here*/}
                        <div className="alert alert-info mb-3">Make sure you have whitelisted our IP address <b>{serverIP}</b> in your  Delta Exchange API key settings </div> 
                       
                        <Label className="form-label">Delta API key</Label>
                        <Input
                          name="api_key"                          
                          type="text"
                          onChange={validateDeltaSetupForm.handleChange}
                          onBlur={validateDeltaSetupForm.handleBlur}
                          value={validateDeltaSetupForm.values.api_key || ""}
                          invalid={
                            validateDeltaSetupForm.touched.api_key && validateDeltaSetupForm.errors.api_key ? true : false
                          }
                        />
                        {validateDeltaSetupForm.touched.api_key && validateDeltaSetupForm.errors.api_key ? (
                          <FormFeedback type="invalid">{validateDeltaSetupForm.errors.api_key}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                        <Label className="form-label">Delta API Secret</Label>
                        <Input
                          name="api_secret"                          
                          type="text"
                          onChange={validateDeltaSetupForm.handleChange}
                          onBlur={validateDeltaSetupForm.handleBlur}
                          value={validateDeltaSetupForm.values.api_secret || ""}
                          invalid={
                            validateDeltaSetupForm.touched.api_secret && validateDeltaSetupForm.errors.api_secret ? true : false
                          }
                        />
                        {validateDeltaSetupForm.touched.api_secret && validateDeltaSetupForm.errors.api_secret ? (
                          <FormFeedback type="invalid">{validateDeltaSetupForm.errors.api_secret}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal"><Link to="" onClick={()=>{dispatch(logoutUser(history))}}  className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
                      <span>Logout</span>
                    </Link></button>
                        <button type="submit" className="btn btn-primary">{formLoader && <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> }Submit</button>
                    </div>
                    </form>
                  </Modal>
              </div>                
            </Col>
          </Row>         
        </Container>
      </div>
      </React.Fragment>
    </>
  )
}

export default DeltaSetup;
