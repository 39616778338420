import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form, 
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    Table,
    TabContent,
    TabPane,
    CardHeader,
    CardText,
    UncontrolledTooltip,
    CardTitle, Spinner, Alert,Modal,FormGroup
} from "reactstrap";
import parse from 'html-react-parser';
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { Link } from "react-router-dom";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import api from '../../../services/api.service';
import { formatUTCDate,getSecondsFromTime, get_actual_index,counterRenderer,capitalizeFirst,getExpiryDates } from "../../../services/common.service";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { toast } from 'react-toastify';
import Countdown from "react-countdown"
import Switch from "react-switch";
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import Select from "react-select"
import DatePicker from "react-flatpickr";
// import ToolTipData from '../../Settings/Settings.json'
const { default: axios } = require("axios");
import { MinimumTakeProfit,TaillingTakeProfit,MidPrize } from "../../../helpers/help-msg";
import { useForm } from "react-hook-form";
const ListOptions = (props) => {
    firebase.auth();
    const {
        register,
        handleSubmit,
        trigger,
        setValue,
        formState: { errors },
      } = useForm({
        mode: "all",});

    const currentUser = JSON.parse(localStorage.getItem('user'));
    let exchangeType = currentUser.profile.exchangeType && currentUser.profile.exchangeType=="Global"?0:1;
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
      //tooltip
   const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
   const [ToolTipData, setToolTipData] = useState([]);
   useEffect(()=>{
     try{
       const gettoottipData = async () => {
       
           const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
           // console.log(response)
             setToolTipData(JSON.parse(JSON.stringify(response.data)))
         
         }
 
         gettoottipData()
       }catch(error){
           console.log(error)
       }
 
   },[apiUrl])
   //tooltip
    let uid = currentUser.uid;
    const [activeTab, setActiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [expiryDates, setExpiryDates] = useState([]);
    const [bestStrikes, setBestStrikes] = React.useState("");
    const [selectedExpiryDate, setSelectedExpiryDate] = useState("")
    const [validateStrikes, setValidateStrikes] = useState("")
    //for settings
    const [settings, setSettings] = useState([]);
    const [stranglebot, setStranglebot] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [selectionType, setSelectionType] = useState('all-users');
    const [selectedUsers, setSelectedUsers] = useState([]);	
    const [failedUserDetails,setfailedUserDetails]= useState([]);
    const [visibleMin, setVisibleMin] = useState(false);
    const [visibleTailling, setVisibleTailling] = useState(false);
    const [MidPrizeMsg,setMidPrizeMsg] = useState();
    const [visibleMidPrize, setVisibleMidPrize] = useState(false);
    const [MintakeProfitMsg,setMintakeProfitMsg] = useState();
    const [TaillingTakeProfitMsg,setTaillingTakeProfitMsg] = useState();
    const [SlTriggerStrangles, setSlTriggerStrangles]=useState(true);
    const [TraillingStrangles,setTraillingStrangles]=useState(false);
    const [exchange_type, setExchange_type] = useState(exchangeType)
    //const [balancearr, setBalancearr] = useState([]);	
    //for settings -end
    const [strangleswait_time_reentry, setstrangleswait_time_reentry] = useState(0);
    const [reentry_enabled, setreentry_enabled] = useState(false);
    const [time_to_close_orders_before_expiry, settime_to_close_orders_before_expiry] = useState(0);
    const [strangleAdvancedSettings,setStrangleAdvancedSettings]=useState(false)
    const [StrangleMidPrice, setStrangleMidPrice] = useState("");

    const validation = {
        required: "This field is required",
        validate: (value) => {
          return value > 0 || "value > 0";
        },
      }
      const [rows, setRows] = useState([
        {name:"",botSettingsvalue:'',orgSettingsName:'',tooltip:'',target:'ASV', label: 'Approximate Strike Values', settings: null, calls: 1133, puts: 1123,formula: <><p><b>Calls</b> = ((Calls Best Bid + Calls Best Ask)/2)</p><p> <b>Puts</b> = (Puts Best Bid + Puts Best Ask)/2</p></> },
        {name:"make_limit_orders_at",botSettingsvalue:'',orgSettingsName:'Make Limit Orders x% from Mid/MarkPrice',tooltip:'visibleMidPrize',target:'ONCLICK', label: 'Make Limit Orders at', settings: StrangleMidPrice, calls: 0, puts: 0,max: 1000,step:0.5,formula: <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2 </p><p> <b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p> <p><b>Calls x%</b> = (Calls Best Ask - Calls Mid Price) * (Setting Values/100)</p><p><b> Puts x%</b> = (Puts Best Ask - Puts Mid Price) * (Setting Values/100)</p> <p><b>Calls</b> = Calls Mid Price + Calls x%</p><p> <b>Puts</b> = Puts Mid Price + Puts x%</p></> },
        {name:"trigger_percentage",botSettingsvalue:'trigger_percentage',orgSettingsName:'Stop Loss Activate Percentage',tooltip:'trigger',target:'stoploss', label: 'Activate SL at', settings:  settings.trigger_percentage ||0, calls: 0, puts: 0,min:0,max:100,step:0.5,formula:  <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2 </p><p> <b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p><p><b>Calls</b> = (Calls Mid Price + (Calls Mid Price * (Setting Values/100)))</p><p> <b>Puts</b> = (Puts Mid Price + (Puts Mid Price * (Setting Values/100)))</p></>,validation:validation},
        {name:"stop_loss_percentage",botSettingsvalue:'stop_loss_percentage',orgSettingsName:'Market Stop Loss Percentage',tooltip:'stop_loss',target:'stoploss1', label: 'Market SL at', settings: settings.stop_loss_percentage ||0, calls: 0, puts: 0,min:0,max:100,step:0.5,formula:<><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2 </p><p><b> Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p><p><b>Calls</b> = ((Calls Sell Price * (Setting Values/100)) + (Calls.Sell Price))</p><p><b>Puts</b> = ((Puts Sell Price * (Setting Values/100)) + (Puts.Sell Price))</p></>,validation:validation },
        {name:"stop_loss_index_percentage",botSettingsvalue:'stop_loss_index_percentage',orgSettingsName:'Index Price Range % for Stop Loss',tooltip:'index_price_range',target:'stoploss2', label: 'Activate SL if index changes to', settings: settings.stop_loss_index_percentage ||0, calls: 0, puts: 0,min:0,max:100,step:0.5,formula: <><p><b>Calls</b> = ((Index Price)-((Index Price) * (Setting Values/100)))</p><p> <b>Puts</b> = ((Index Price)+((Index Price) * (Setting Values/100))</p></> },
        {name:"min_take_profit_percentage",botSettingsvalue:'min_take_profit_percentage',orgSettingsName:'Trailing Take Profit Percentage',tooltip:'visibleMin',target:'ONCLICK', label: 'Activate Trailing at', settings: settings.min_take_profit_percentage ||0, calls: 0, puts: 0,min:0,max:100,step:0.5,formula: <><p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p> <b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></> ,validation:validation},
        {name:"trailing_take_profit_percentage",botSettingsvalue:'trailing_take_profit_percentage',orgSettingsName:'Trailing Stop Price Percentage',tooltip:'visibleTailling',target:'ONCLICK', label: 'Trail Exit at', settings:settings.trailing_take_profit_percentage ||0, calls: 0, puts: 0,min:0,max: 100,step:0.5,formula: <> <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p> <b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></>,validation:validation },
        {name:"take_profit_percentage",botSettingsvalue:'take_profit_percentage',orgSettingsName:'Take Profit Percentage',tooltip:'take_profit',target:'take-profit', label: 'Take Profit at', settings:settings.take_profit_percentage ||0, calls: 0, puts: 0,min:0,max: 100,step:0.5,formula: <> <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p> <b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></> },
        {name:"Sl_trigger_take_profit",botSettingsvalue:'Sl_trigger_take_profit',orgSettingsName:'Activate trailing take profit when stop loss triggered on one leg',tooltip:'stop_loss_trigger_market_take_profit',target:'SSLTMTP', label: 'Activate Trailing', 'ATTPWSLTOOL': '(radio buttons)', calls: '', puts: 'After SL hits one of the position',formula: <> <p><b>After SL hits one of the positions</b>– Activates trailing and take profit after stop loss hit on one leg.</p></> },
        {name:"combined_sl_percentage",botSettingsvalue:'combined_sl_percentage',orgSettingsName:'Combined Stop Loss %',tooltip:'stop_loss_combined',target:'strangles-combinedsl-trigger', label: 'Activate Combined SL at', settings: settings.combined_sl_percentage ||0, calls: '', puts: 0,min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) + (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
        {name:"combined_trailing_profit_percentage",botSettingsvalue:'combined_trailing_profit_percentage',orgSettingsName:'Combined Trailing Take Profit %',tooltip:'combined_trailing_profit_percentage',target:'strangles-combined-trail-pro-trigger', label: 'Activate Combined Trailing at', settings: settings.combined_trailing_profit_percentage ||0, calls: '', puts: 0,min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
        {name:"combined_trailing_stop_price_percentage",botSettingsvalue:'combined_trailing_stop_price_percentage',orgSettingsName:'Combined Trailing Stop Price %',tooltip:'combined_trailing_stop_price_percentage',target:'strangles-combined-trail-stop-trigger', label: 'Combined Trail Exit at', settings: settings.combined_trailing_stop_price_percentage ||0, calls: '', puts: 0,min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
        {name:"combined_take_profit_percentage",botSettingsvalue:'combined_take_profit_percentage',orgSettingsName:'Combined Take Profit %',tooltip:'combined_take_profit_percentage',target:'strangles-combined-take-profit-trigger', label: 'Combined Take Profit at', settings: settings.combined_take_profit_percentage ||0, calls: '', puts: 0,min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
        {name:"waiting_time_to_fill_qty",botSettingsvalue:'waiting_time_to_fill_qty',orgSettingsName:'Waiting Time To Fill Quantity',tooltip:'waiting_time_to_fill_qty',target:'WTFQTY', label: 'Waiting time to fill Qty (H : M)', settings: settings.waiting_time_to_fill_qty ||0},
        {name:"automatic_market_fill",botSettingsvalue:'automatic_market_fill',orgSettingsName:'Automatic Market Fill',tooltip:'automatic_market_fill',target:'automatic-market-fill', label: 'Automatic Market Fill', settings: settings.autoMarketFill ||0},
        {name:"universal_stop",botSettingsvalue:'universal_stop',orgSettingsName:'Universal Stop',tooltip:'universal_stop',target:'universal-stop', label: 'Universal Stop', settings: settings.universal_stop ||0},
      ]);

    function toSeconds(hours, minutes, seconds) {
        return hours * 3600 + minutes * 60 + seconds;
      }
    const [loading, setLoading] = useState({
        loading: false
    });

    const emptyUsers = (e) => {
        setSelectionType('all-users')
        setSelectedUsers([]);
        let value = e.target.value=="Global"? 0:1;
        get_users(value);
        setExchange_type(value);
    }

    const Offsymbol = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            No
          </div>
        );
      }; 
      
      const OnSymbol = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            Yes
          </div>
        );
      };
      function toHoursAndMinutes(totalSeconds) {
        const totalMinutes = Math.floor(totalSeconds / 60);
      
        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
      
        return hours + ":" + minutes ;
      }
      const handleDH = (e,strategy = '') => {
        var time = e[0]
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var H_M = toSeconds(hours, minutes, 0);
        if (strategy == 'strangleswait_time_reentry'){
          setstrangleswait_time_reentry(H_M)
        }else if (strategy == 'time_to_close_orders_before_expiry'){
            settime_to_close_orders_before_expiry(H_M)
          }
      }
    const handleError = (err) => {
        if (err.response && err.response.data) {
            toast.error(err.response.data.message);
        } else if (err.message) {
            toast.error(err.message);
        } else if (err.request) {
        } else {
        }

    }
    async function toggleTab(tab) {
        if (activeTab == 1) {
            if(usersList.length == 0){
                toast.error('No users available');
                return false;
            }
            if(selectionType === 'selected-users' && selectedUsers.length ===0) {
                toast.error('No users selected');
                   return false;
            }
            setValidateStrikes("");
            setLoading({
                loading: false
            });
            setBestStrikes("")
            //get best strikes
            let exp_date = document.getElementById('exp_date').value;
            let index_percentage = document.getElementById('index_percentage').value;
            if (index_percentage > 0) {
                setLoading({
                    ...loading,
                    best_strikes: true,
                });
                let bodyParams; 
               
                if(exchangeType == exchange_type){
                     bodyParams = {
                        settlement_time: exp_date,
                        index_percentage: index_percentage
                    };
                }else{
                    console.log(usersList,"userlist");
                 bodyParams = {
                        exchangeType : exchange_type,
                        u_id:usersList[0].value,
                        settlement_time: exp_date,
                        index_percentage: index_percentage
                    };
                }
                
                setSelectedExpiryDate(exp_date)
                api({ contentType: true, auth: true }).post('/delta/get-best-strikes', bodyParams).then(res => {
                    setBestStrikes(res.data.response.best_strikes)
                    UpdateDbSetingsinRow(res.data.response.best_strikes)
                        // setLoading({
                    //     ...loading,
                    //     best_strikes: false,
                    // });
                }).catch(err => {
                    handleError(err)
                    // setLoading({
                    //     ...loading,
                    //     best_strikes: false,
                    // });
                }).finally(() => {
                    setLoading({
                        ...loading,
                        best_strikes: false,
                    });
                });
               
                var modifiedSteps = [...new Set(passedSteps), tab];
                 modifiedSteps = modifiedSteps.filter(element => element > tab);
                setActiveTab(tab);
                setPassedSteps(modifiedSteps)
            } else {
                toast.error('Enter Index Percentage');
                return false;

            }
        } else if (activeTab == 3 && tab > activeTab) {
        //   await setStrangleAdvancedSettings(true); 
    
                let isValid = await trigger()
                
                if (!isValid && errors && Object.keys(errors).length > 0) {
                    return;
                }
            
                window.scrollTo(0, document.body.scrollHeight);
                //start bot
                startBot(tab);
        } else if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                var modifiedSteps = [...new Set(passedSteps), tab];
                setActiveTab(tab);
                modifiedSteps = modifiedSteps.filter(element => element > tab);
                setPassedSteps(modifiedSteps)

            }
        }
    }
    const startBot = async (tab) => {
        if (stranglebot) {            
            setfailedUserDetails([]);
            //settings
         
              
                    setLoading({
                        ...loading,
                        start_bot: true,
                    });
                    //console.log("loading.best_strikes_validation", loading.best_strikes_validation)

                    settings.roll_status = settings.roll_status ? 1 : 0;
                    settings.universal_stop = settings.universal_stop ? 1 : 0;
                    if ((validateStrikes != '' && !loading.best_strikes_validation)) {
                        //console.log("symbol is already running")
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false;
                    } else if (settings.quantity == '') {
                        toast.error('Please enter quantity');
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false;
                    } else if (settings.roll_status==1 && ((!settings.roll_percentage && !settings.index_distance) || (!settings.roll_percentage && settings.index_distance) || (settings.roll_percentage && !settings.index_distance))) {
                        toast.error('Must Enter Roll percentage and distance from index');
                        setLoading({
                            ...loading,
                            start_bot: false,
                        });

                        return false;
                    } else {
                        //starting bot
                        const calls_margin = Number(bestStrikes.calls.order_margin);
                        const puts_margin = Number(bestStrikes.puts.order_margin);
                        const required_margin = Number(calls_margin) + Number(puts_margin);
                        settings.roll_percentage=(settings.roll_status==1)?settings.roll_percentage:'0';
                        settings.required_margin = required_margin;
                        settings.current_index_price = Number(bestStrikes.index_price).toFixed(2);
                        settings.expiry_date = bestStrikes.expiry_date;
                        settings.calls_amount = bestStrikes.calls.best_bid;
                        settings.puts_amount = bestStrikes.puts.best_bid;
                        settings.call_symbol = bestStrikes.calls.symbol;
                        settings.call_symbol_id = bestStrikes.calls.product_id;
                        settings.put_symbol = bestStrikes.puts.symbol;
                        settings.put_symbol_id = bestStrikes.puts.product_id;
                        settings.waitTimeForFillAllQty = getSecondsFromTime(settings.Qty_time_clock || '00:00');
                        settings.waitTimeForRoll = getSecondsFromTime(settings.Roll_time_clock || '00:00');
                        settings.combined_trailing_profit = TraillingStrangles == true ? 1 : 0;
                        settings.reentry_enabled = reentry_enabled == true ? 1 : 0;
                        settings.wait_time_reentry = strangleswait_time_reentry; 
                        settings.time_to_close_orders_before_expiry = time_to_close_orders_before_expiry;
                        settings.mid_price_percentage = StrangleMidPrice ? StrangleMidPrice : 0;
                        let selected_qty={};
                        
                            //Selected users
                            let user_selection_type = selectionType;
                            let selected_user_uids;
                            if(user_selection_type === 'selected-users') {
                            let selected_user_uids_arr = selectedUsers.map(selected_user => selected_user.value);
                            if (selected_user_uids_arr.length) {
                                selected_user_uids = selected_user_uids_arr.join(",")
                            }          
                            }
                            let start_for_all;
                            let selected_users;
                            if (selectionType=='all-users') {
                                start_for_all =1;
                                selected_users='';
                            }else{
                                start_for_all =0;
                                selected_users=selected_user_uids;
                        
                            } 
                            settings.selected_users=selected_users;
                            settings.selected_qty=selected_qty;
						    settings.start_for_all=start_for_all;
                            settings.created_by=uid;
                            settings.exchangeType = exchange_type;
                            //////
                            await api({ contentType: true, auth: true }).post('/abotex/delta/start-bot', settings).then( async (res) => {                
                                toast.success('Orders Placed successully');  
                                if (res.data.response.failed_user_details && res.data.response.failed_user_details.length > 0) {  
                                    setfailedUserDetails(res.data.response.failed_user_details);
                                } 
                                var modifiedSteps = [...new Set(passedSteps), tab];
                                setActiveTab(tab);
                                modifiedSteps = modifiedSteps.filter(element => element > tab);
                                setPassedSteps(modifiedSteps)
                                setLoading({
                                    ...loading,
                                    start_bot: false,
                                });
                                
                                setLoading({
                                    ...loading,
                                    start_bot: false,
                                });
                              
                            }).catch(err => {                        
                              handleError(err)
                                      if (err && err.response && err.response.data && err.response.data.data && err.response.data.data.failed_user_details && err.response.data.data.failed_user_details.length) { 
                                        setfailedUserDetails(res.data.response.failed_user_details);
                                        var modifiedSteps = [...new Set(passedSteps), tab];
                                        setActiveTab(tab);
                                        modifiedSteps = modifiedSteps.filter(element => element > tab);
                                        setPassedSteps(modifiedSteps)
                                        setLoading({
                                            ...loading,
                                            start_bot: false,
                                        });
                                    }else{
                                        setfailedUserDetails([]);
                                    }
                                    setLoading({
                                        ...loading,
                                        start_bot: false,
                                    });
                            })

                            //////
                    }
                
           
        } 
    }
    const handleModel = (e, row) =>{
        if(row.target === 'ONCLICK'){
          if(row.tooltip === 'visibleMin'){
            setVisibleMin(!visibleMin)
          }else if (row.tooltip === 'visibleTailling'){
            setVisibleTailling(!visibleTailling)
          }else if (row.tooltip === 'visibleMidPrize'){
            setVisibleMidPrize(!visibleMidPrize)
          }
        }
      }

    const UpdateDbSetingsinRow = (stranglesStrikes) => {
        const updatedRows = rows.map((row, i) => {
          
          const updatedRow = { ...row};
          const puts_mid_value = (stranglesStrikes.puts.best_bid + stranglesStrikes.puts.best_ask)/2
          const calls_mid_value = (stranglesStrikes.calls.best_bid + stranglesStrikes.calls.best_ask)/2
          
          if(row.botSettingsvalue === '' && row.orgSettingsName ===''){
            updatedRow.calls = (stranglesStrikes.calls.best_bid + stranglesStrikes.calls.best_ask)/2
            updatedRow.puts = (stranglesStrikes.puts.best_bid + stranglesStrikes.puts.best_ask)/2
            updatedRow.formula = <>
                <p><b>Calls</b> = (Calls Best Bid + Calls Best Ask)/2</p> 
                <p><b>Calls</b> = ({stranglesStrikes.calls.best_bid} + {stranglesStrikes.calls.best_ask})/2</p>
                <p><b>Puts</b> = (Puts Best Bid + Puts Best Ask)/2</p> 
                <p><b>Puts</b> = ({stranglesStrikes.puts.best_bid} + {stranglesStrikes.puts.best_ask})/2</p>
                </>
          }
          if (row.botSettingsvalue === 'stop_loss_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.calls = ((stranglesStrikes.calls.sell_price * (Number(settings.stop_loss_percentage || 0) /100)) + Number(stranglesStrikes.calls.sell_price)).toFixed(2)
            updatedRow.puts = ((stranglesStrikes.puts.sell_price * (Number(settings.stop_loss_percentage || 0) /100)) + Number(stranglesStrikes.puts.sell_price)).toFixed(2)
            updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) * (Setting Values/100)) + (Calls Sell Price)</p>
                <p><b>Calls</b> = (({stranglesStrikes.calls.sell_price}) * ({settings[row.botSettingsvalue] || 0}/100)) + ({stranglesStrikes.calls.sell_price})</p>
                <p><b>Puts</b> = ((Puts Sell Price) * (Setting Values/100)) + (Puts Sell Price)</p> 
                <p><b>Puts</b> = (({stranglesStrikes.puts.sell_price}) * ({settings[row.botSettingsvalue] || 0}/100)) + ({stranglesStrikes.puts.sell_price})</p>
                </>
          } 
            if (row.botSettingsvalue === 'min_take_profit_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.calls = (Number(stranglesStrikes.calls.sell_price)-(stranglesStrikes.calls.sell_price * (Number(settings.min_take_profit_percentage || 0) /100))).toFixed(2)
            updatedRow.puts = (Number(stranglesStrikes.puts.sell_price)-(stranglesStrikes.puts.sell_price * (Number(settings.min_take_profit_percentage || 0) /100))).toFixed(2)
            updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
                <p><b>Calls</b> = (({stranglesStrikes.calls.sell_price}) -({stranglesStrikes.calls.sell_price} * ({settings.min_take_profit_percentage || 0 }/100)))</p>
                <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
                <p><b>Puts</b> = (({stranglesStrikes.puts.sell_price}) -({stranglesStrikes.puts.sell_price} * ({settings.min_take_profit_percentage || 0 }/100)))</p>
            </>
          }
          if (row.botSettingsvalue === 'trailing_take_profit_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.calls = (Number(stranglesStrikes.calls.sell_price)-(stranglesStrikes.calls.sell_price * (Number(settings.trailing_take_profit_percentage || 0) /100))).toFixed(2)
            updatedRow.puts = (Number(stranglesStrikes.puts.sell_price)-(stranglesStrikes.puts.sell_price * (Number(settings.trailing_take_profit_percentage || 0) /100))).toFixed(2)
            updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
                <p><b>Calls</b> = (({stranglesStrikes.calls.sell_price}) -({stranglesStrikes.calls.sell_price} * ({settings.trailing_take_profit_percentage || 0 }/100)))</p>
                <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
                <p><b>Puts</b> = (({stranglesStrikes.puts.sell_price}) -({stranglesStrikes.puts.sell_price} * ({settings.trailing_take_profit_percentage || 0 }/100)))</p>
                </>
          }
          if(row.botSettingsvalue === 'take_profit_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.calls = (Number(stranglesStrikes.calls.sell_price)-(stranglesStrikes.calls.sell_price * (Number(settings.take_profit_percentage || 0) /100))).toFixed(2)
            updatedRow.puts = (Number(stranglesStrikes.puts.sell_price)-(stranglesStrikes.puts.sell_price * (Number(settings.take_profit_percentage || 0) /100))).toFixed(2)
            updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
                <p><b>Calls</b> = (({stranglesStrikes.calls.sell_price}) -({stranglesStrikes.calls.sell_price} * ({settings.take_profit_percentage || 0 }/100)))</p>
                <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
                <p><b>Puts</b> = (({stranglesStrikes.puts.sell_price}) -({stranglesStrikes.puts.sell_price} * ({settings.take_profit_percentage || 0 }/100)))</p>
                </>
          }
          if (row.botSettingsvalue === 'combined_sl_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.puts = ((Number(stranglesStrikes.calls.sell_price)+Number(stranglesStrikes.puts.sell_price))+((Number(stranglesStrikes.calls.sell_price)+Number(stranglesStrikes.puts.sell_price)) * (Number(settings[row.botSettingsvalue] || 0 )/100))).toFixed(2)
            updatedRow.formula = <>
                <p>(((Calls Sell Price) + (Puts Sell Price)) + (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
                <p>((({stranglesStrikes.calls.sell_price}) + ({stranglesStrikes.puts.sell_price})) + ((({stranglesStrikes.calls.sell_price}) + ({stranglesStrikes.puts.sell_price})) * ({settings[row.botSettingsvalue] || 0 }/100)))</p>
                </>
          }
          if (row.botSettingsvalue === 'combined_trailing_profit_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.puts = ((Number(stranglesStrikes.calls.sell_price)+Number(stranglesStrikes.puts.sell_price))-((Number(stranglesStrikes.calls.sell_price)+Number(stranglesStrikes.puts.sell_price)) * (Number(settings[row.botSettingsvalue] || 0 )/100))).toFixed(2)
            updatedRow.formula = <>
                <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
                <p>((({stranglesStrikes.calls.sell_price}) + ({stranglesStrikes.puts.sell_price})) - ((({stranglesStrikes.calls.sell_price}) + ({stranglesStrikes.puts.sell_price})) * ({settings[row.botSettingsvalue] || 0 }/100)))</p>
                </>
          }
          if (row.botSettingsvalue === 'combined_trailing_stop_price_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.puts = ((Number(stranglesStrikes.calls.sell_price)+Number(stranglesStrikes.puts.sell_price))-((Number(stranglesStrikes.calls.sell_price)+Number(stranglesStrikes.puts.sell_price)) * (Number(settings[row.botSettingsvalue] || 0 )/100))).toFixed(2)
            updatedRow.formula = <>
                <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
                <p>((({stranglesStrikes.calls.sell_price}) + ({stranglesStrikes.puts.sell_price})) - ((({stranglesStrikes.calls.sell_price}) + ({stranglesStrikes.puts.sell_price})) * ({settings[row.botSettingsvalue] || 0 }/100)))</p>
                </>
          }
          if (row.botSettingsvalue === 'stop_loss_index_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.calls = ((Number(stranglesStrikes.index_price)-(Number(stranglesStrikes.index_price)*(Number(settings[row.botSettingsvalue] || 0 )/100)))).toFixed(2)
            updatedRow.puts = ((Number(stranglesStrikes.index_price)+(Number(stranglesStrikes.index_price)*(Number(settings[row.botSettingsvalue] || 0 )/100)))).toFixed(2)
            updatedRow.formula = <>
           <p><b>Calls</b> = ((Index Price)-((Index Price) * (Setting Values/100)))</p>
           <p><b>Calls</b> = (({ stranglesStrikes.index_price})-(({ stranglesStrikes.index_price}) * ({ settings[row.botSettingsvalue] || 0 }/100)))</p>
           <p><b>Puts</b> = ((Index Price)+((Index Price) * (Setting Values/100)))</p>
           <p><b>Puts</b> = (({ stranglesStrikes.index_price})+(({ stranglesStrikes.index_price}) * ({ settings[row.botSettingsvalue] || 0 }/100)))</p>
           </>
          }
          if (row.botSettingsvalue === 'trigger_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.calls = (Number((stranglesStrikes.calls.sell_price * (Number(settings.trigger_percentage || 0) /100))) + Number(stranglesStrikes.calls.sell_price)).toFixed(2) 
            updatedRow.puts = (Number((stranglesStrikes.puts.sell_price * (Number(settings.trigger_percentage || 0) /100))) + Number(stranglesStrikes.puts.sell_price)).toFixed(2)
            updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) * (Setting Values/100)) + (Calls Sell Price)</p>
                <p><b>Calls</b> = (({stranglesStrikes.calls.sell_price}) * ({settings.trigger_percentage || 0 }/100)) + ({stranglesStrikes.calls.sell_price})</p>
                <p><b>Puts</b> = ((Puts Sell Price) * (Setting Values/100)) + (Puts Sell Price)</p>
                <p><b>Puts</b> = (({stranglesStrikes.puts.sell_price}) * ({settings.trigger_percentage || 0 }/100)) + ({stranglesStrikes.puts.sell_price})</p>
                </>
          }
          if(row.botSettingsvalue === 'combined_take_profit_percentage'){
            updatedRow.settings = settings[row.botSettingsvalue]
            updatedRow.puts = ((Number(stranglesStrikes.calls.sell_price) + Number(stranglesStrikes.puts.sell_price))- ((Number(stranglesStrikes.calls.sell_price) + Number(stranglesStrikes.puts.sell_price)) * (Number(settings.combined_take_profit_percentage || 0) / 100))).toFixed(2)
            updatedRow.formula = <>
                <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
                <p>((({stranglesStrikes.calls.sell_price}) + ({stranglesStrikes.puts.sell_price})) - ((({stranglesStrikes.calls.sell_price}) + ({stranglesStrikes.puts.sell_price})) * ({settings.combined_take_profit_percentage || 0 }/100)))</p>
                </>
          }
          if (row.tooltip === 'visibleMidPrize'){
            updatedRow.settings = StrangleMidPrice
            const puts_75_percent = (stranglesStrikes.puts.best_ask-puts_mid_value)*(Number(StrangleMidPrice || 0) /100)
            const calls_75_percent = (stranglesStrikes.calls.best_ask-calls_mid_value)*(Number(StrangleMidPrice || 0) /100)
            updatedRow.calls = Number(calls_mid_value+calls_75_percent).toFixed(2)
            updatedRow.puts = Number(puts_mid_value+puts_75_percent).toFixed(2)
            updatedRow.formula = <>
                <p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2</p> 
                <p><b>Calls Mid Price</b> = ({stranglesStrikes.calls.best_bid} + {stranglesStrikes.calls.best_ask})/2</p>
                <p><b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p> 
                <p><b>Puts Mid Price</b> = ({stranglesStrikes.puts.best_bid} + {stranglesStrikes.puts.best_ask})/2</p>
                <p><b>Calls x%</b> = (Calls Best Ask - Calls Mid Price) * (Setting Values/100)</p>
                <p><b>Calls x%</b> = ({stranglesStrikes.calls.best_ask} - {calls_mid_value}) * ({StrangleMidPrice || 0 }/100)</p>
                <p><b>Puts x%</b> = (Puts Best Ask - Puts Mid Price) * (Setting Values/100)</p>
                <p><b>Puts x%</b> = ({stranglesStrikes.puts.best_ask} - {puts_mid_value}) * ({StrangleMidPrice || 0 }/100)</p>
                <p><b>Calls</b> = Calls Mid Price + Calls x%</p>
                <p><b>Calls</b> = ({((stranglesStrikes.calls.best_bid + stranglesStrikes.calls.best_ask)/2).toFixed(2)}) + {((stranglesStrikes.calls.best_ask - calls_mid_value) * (StrangleMidPrice/100)).toFixed(2)}</p>
                <p><b>Puts</b> = Puts Mid Price + Puts x%</p>
                <p><b>Puts</b> = ({((stranglesStrikes.puts.best_bid + stranglesStrikes.puts.best_ask)/2).toFixed(2)}) + {((stranglesStrikes.puts.best_ask - puts_mid_value) * (StrangleMidPrice/100)).toFixed(2)}</p>
                </>
            // return 
          }

          if(row.name){
            setValue(row.name, updatedRow.settings || 0, { shouldValidate: true })
          }
          return updatedRow;
        })
        setRows(updatedRows);
      }

    const handleInputChange = (e,index, tooltip,botSettingsvalue) => {
        console.log("handleInputChange",botSettingsvalue);
        
        const updatedRows = rows.map((row, i) => {
          
          if (i === index) {
            console.log(e.target.getAttribute('max'),tooltip)
            let newvalue = e.target.value === null || e.target.value === undefined || e.target.value === '' ? '' : Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
            const updatedRow = { ...row, settings: newvalue };
            const puts_mid_value = (bestStrikes.puts.best_bid + bestStrikes.puts.best_ask)/2
            const calls_mid_value = (bestStrikes.calls.best_bid + bestStrikes.calls.best_ask)/2
            if (tooltip === 'visibleMidPrize'){
              e.target.value <= 1000 ? setStrangleMidPrice(Number(newvalue||0)) : setStrangleMidPrice(1000)  
              e.target.value <= 1000 ? setStrangleMidPrice(Number(newvalue||0)) : setStrangleMidPrice(100) 
              
              const puts_75_percent = (bestStrikes.puts.best_ask-puts_mid_value)*(Number(newvalue||0)/100)
              const calls_75_percent = (bestStrikes.calls.best_ask-calls_mid_value)*(Number(newvalue||0)/100)
              console.log(calls_mid_value,puts_75_percent, calls_75_percent,(puts_mid_value+puts_75_percent).toFixed(2),(calls_mid_value+calls_75_percent).toFixed(2))
              updatedRow.calls = Number(calls_mid_value+calls_75_percent).toFixed(2)
              updatedRow.puts = Number(puts_mid_value+puts_75_percent).toFixed(2)
              updatedRow.formula = <>
                <p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2</p> 
                <p><b>Calls Mid Price</b> = ({bestStrikes.calls.best_bid} + {bestStrikes.calls.best_ask})/2</p>
                <p><b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p> 
                <p><b>Puts Mid Price</b> = ({bestStrikes.puts.best_bid} + {bestStrikes.puts.best_ask})/2</p>
                <p><b>Calls x%</b> = (Calls Best Ask - Calls Mid Price) * (Setting Values/100)</p>
                <p><b>Calls x%</b> = ({bestStrikes.calls.best_ask} - {calls_mid_value}) * ({Number(newvalue||0) || 0 }/100)</p>
                <p><b>Puts x%</b> = (Puts Best Ask - Puts Mid Price) * (Setting Values/100)</p>
                <p><b>Puts x%</b> = ({bestStrikes.puts.best_ask} - {puts_mid_value}) * ({Number(newvalue||0) || 0 }/100)</p>
                <p><b>Calls</b> = Calls Mid Price + Calls x%</p>
                <p><b>Calls</b> = ({((bestStrikes.calls.best_bid + bestStrikes.calls.best_ask)/2).toFixed(2)}) + {((bestStrikes.calls.best_ask - calls_mid_value) * (Number(newvalue||0)/100)).toFixed(2)}</p>
                <p><b>Puts</b> = Puts Mid Price + Puts x%</p>
                <p><b>Puts</b> = ({((bestStrikes.puts.best_bid + bestStrikes.puts.best_ask)/2).toFixed(2)}) + {((bestStrikes.puts.best_ask - puts_mid_value) * (Number(newvalue||0)/100)).toFixed(2)}</p>
                </>
              // return 
            }
            if (botSettingsvalue === 'stop_loss_percentage'){
              updatedRow.calls = ((bestStrikes.calls.sell_price * (Number(newvalue||0)/100)) + Number(bestStrikes.calls.sell_price)).toFixed(2)
              updatedRow.puts = ((bestStrikes.puts.sell_price * (Number(newvalue||0)/100)) + Number(bestStrikes.puts.sell_price)).toFixed(2)
              updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) * (Setting Values/100)) + (Calls Sell Price)</p>
                <p><b>Calls</b> = (({bestStrikes.calls.sell_price}) * ({Number(newvalue||0)}/100)) + ({bestStrikes.calls.sell_price})</p>
                <p><b>Puts</b> = ((Puts Sell Price) * (Setting Values/100)) + (Puts Sell Price)</p> 
                <p><b>Puts</b> = (({bestStrikes.puts.sell_price}) * ({Number(newvalue||0)}/100)) + ({bestStrikes.puts.sell_price})</p>
                </>
            }
            if (botSettingsvalue === 'min_take_profit_percentage'){
              updatedRow.calls = (Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (Number(newvalue||0)/100))).toFixed(2)
              updatedRow.puts = (Number(bestStrikes.puts.sell_price)-(bestStrikes.puts.sell_price * (Number(newvalue||0)/100))).toFixed(2)
              updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
                <p><b>Calls</b> = (({bestStrikes.calls.sell_price}) -({bestStrikes.calls.sell_price} * ({Number(newvalue||0) }/100)))</p>
                <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
                <p><b>Puts</b> = (({bestStrikes.puts.sell_price}) -({bestStrikes.puts.sell_price} * ({Number(newvalue||0) }/100)))</p>
            </>
            }
            if (botSettingsvalue === 'trailing_take_profit_percentage'){
              updatedRow.calls =(Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (Number(newvalue||0)/100))).toFixed(2)
              updatedRow.puts = (Number(bestStrikes.puts.sell_price)-(bestStrikes.puts.sell_price * (Number(newvalue||0)/100))).toFixed(2)
              updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
                <p><b>Calls</b> = (({bestStrikes.calls.sell_price}) -({bestStrikes.calls.sell_price} * ({Number(newvalue||0) }/100)))</p>
                <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
                <p><b>Puts</b> = (({bestStrikes.puts.sell_price}) -({bestStrikes.puts.sell_price} * ({Number(newvalue||0) }/100)))</p>
                </>
            }
            if(botSettingsvalue === 'take_profit_percentage'){
              updatedRow.calls = (Number(bestStrikes.calls.sell_price)-(bestStrikes.calls.sell_price * (Number(newvalue||0)/100))).toFixed(2)
              updatedRow.puts = (Number(bestStrikes.puts.sell_price)-(bestStrikes.puts.sell_price * (Number(newvalue||0)/100))).toFixed(2)
              updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
                <p><b>Calls</b> = (({bestStrikes.calls.sell_price}) -({bestStrikes.calls.sell_price} * ({Number(newvalue||0) }/100)))</p>
                <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
                <p><b>Puts</b> = (({bestStrikes.puts.sell_price}) -({bestStrikes.puts.sell_price} * ({Number(newvalue||0) }/100)))</p>
                </>
            }
            if (botSettingsvalue === 'combined_sl_percentage'){
              updatedRow.puts = ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))+((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (Number(newvalue||0)/100))).toFixed(2)
              updatedRow.formula = <>
                <p>(((Calls Sell Price) + (Puts Sell Price)) + (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
                <p>((({bestStrikes.calls.sell_price}) + ({bestStrikes.puts.sell_price})) + ((({bestStrikes.calls.sell_price}) + ({bestStrikes.puts.sell_price})) * ({Number(newvalue||0)}/100)))</p>
                </>
            }
            if (botSettingsvalue === 'combined_trailing_profit_percentage'){
            updatedRow.puts = ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))-((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (Number(newvalue||0)/100))).toFixed(2)
            updatedRow.formula = <>
                <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
                <p>((({bestStrikes.calls.sell_price}) + ({bestStrikes.puts.sell_price})) - ((({bestStrikes.calls.sell_price}) + ({bestStrikes.puts.sell_price})) * ({Number(newvalue||0) }/100)))</p>
                </>
            }
            if (botSettingsvalue === 'combined_trailing_stop_price_percentage'){
            updatedRow.puts = ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))-((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (Number(newvalue||0)/100))).toFixed(2)
            updatedRow.formula = <>
                <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
                <p>((({bestStrikes.calls.sell_price}) + ({bestStrikes.puts.sell_price})) - ((({bestStrikes.calls.sell_price}) + ({bestStrikes.puts.sell_price})) * ({Number(newvalue||0) }/100)))</p>
                </>
            }
            if(botSettingsvalue === 'combined_take_profit_percentage'){
            updatedRow.puts = ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price))- ((Number(bestStrikes.calls.sell_price)+Number(bestStrikes.puts.sell_price)) * (Number(newvalue||0)/100))).toFixed(2)
            updatedRow.formula = <>
                <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
                <p>((({bestStrikes.calls.sell_price}) + ({bestStrikes.puts.sell_price})) - ((({bestStrikes.calls.sell_price}) + ({bestStrikes.puts.sell_price})) * ({Number(newvalue||0) }/100)))</p>
                </>
            }
            if (botSettingsvalue === 'stop_loss_index_percentage'){
              updatedRow.calls = ((Number(bestStrikes.index_price)-(Number(bestStrikes.index_price)*(Number(newvalue||0)/100)))).toFixed(2)
              updatedRow.puts = ((Number(bestStrikes.index_price)+(Number(bestStrikes.index_price)*(Number(newvalue||0)/100)))).toFixed(2)
              updatedRow.formula = <>
                <p><b>Calls</b> = ((Index Price)-((Index Price) * (Setting Values/100)))</p>
                <p><b>Calls</b> = (({ bestStrikes.index_price})-(({ bestStrikes.index_price}) * ({ Number(newvalue||0) }/100)))</p>
                <p><b>Puts</b> = ((Index Price)+((Index Price) * (Setting Values/100)))</p>
                <p><b>Puts</b> = (({ bestStrikes.index_price})+(({ bestStrikes.index_price}) * ({ Number(newvalue||0) }/100)))</p>
                </>
            }
            if (botSettingsvalue === 'trigger_percentage'){
              updatedRow.calls = (Number((bestStrikes.calls.sell_price * (Number(newvalue||0)/100))) + Number(bestStrikes.calls.sell_price)).toFixed(2) 
              updatedRow.puts = (Number((bestStrikes.puts.sell_price * (Number(newvalue||0)/100))) + Number(bestStrikes.puts.sell_price)).toFixed(2)
              updatedRow.formula = <>
                <p><b>Calls</b> = ((Calls Sell Price) * (Setting Values/100)) + (Calls Sell Price)</p>
                <p><b>Calls</b> = (({bestStrikes.calls.sell_price}) * ({Number(newvalue||0) }/100)) + ({bestStrikes.calls.sell_price})</p>
                <p><b>Puts</b> = ((Puts Sell Price) * (Setting Values/100)) + (Puts Sell Price)</p>
                <p><b>Puts</b> = (({bestStrikes.puts.sell_price}) * ({Number(newvalue||0) }/100)) + ({bestStrikes.puts.sell_price})</p>
                </>
            }
            console.log(newvalue,' ======= ',' ++ ', botSettingsvalue)
            if (tooltip !== 'visibleMidPrize'){
              setSettings({ ...settings, [botSettingsvalue]:  newvalue});
            }
            
            console.log(updatedRow,' updatedRow ')
            setValue(row.name, e.target.value, { shouldValidate: true })
            trigger(row.name)
            return updatedRow;
          }
          return row;
        });
        setRows(updatedRows);
      };
    const getExpiryDateValues = useCallback(async () => {
        try {
            const bodyParams = {
              currency: 'BTC'
            };
            await getExpiryDates(bodyParams).then((res) => {
                setExpiryDates(res.data.response.expiry_dates);  
            }).catch(e => {
              throw e
            })
          } catch (e) {
            handleError(e);
          }
    });
    const get_settings = async () => {
        await api({ contentType: true, auth: true }).get('/delta/get-site-settings?type=bot-settings').then(res => {
            if(res.data.response && res.data.response.settings) {
                setSettings(res.data.response.settings);
                setStrangleMidPrice(res.data.response.settings.mid_price_percentage? res.data.response.settings.mid_price_percentage : "0");
                setSlTriggerStrangles(res.data.response.settings && res.data.response.settings.Sl_trigger_take_profit !== undefined ? res.data.response.settings.Sl_trigger_take_profit : true);
                setreentry_enabled(res.data.response.settings.reentry_enabled? (res.data.response.settings.reentry_enabled == 1 ? true : false):false);
              setstrangleswait_time_reentry(res.data.response.settings.wait_time_reentry? res.data.response.settings.wait_time_reentry : "0");
              settime_to_close_orders_before_expiry(res.data.response.settings &&  res.data.response.settings.time_to_close_orders_before_expiry ? res.data.response.settings.time_to_close_orders_before_expiry : "0");
            }
        });
        // var docRef = firebase.firestore().collection("site-settings").doc('bot-settings');
        // await docRef.get().then((doc) => {
        //     if (doc.exists) {
        //         const order_settings = doc.data();
        //         setSettings(order_settings);
        //     }
        // })
    }
    const get_users = async(type)=>{  
        try {
          const res = await api({ contentType: true, auth: true}).post('/abotex/delta/get-users-list',{exchangeType:type});

          if (res.data.response.users_list) {
            let usersList=res.data.response.users_list
            let userids=[];
            usersList.forEach(async (doc) => {
				let userobj={}
				userobj.value=doc.uid
				userobj.label=capitalizeFirst(doc.display_text); 
				userids.push(userobj)
			});
            setUsersList(userids)
            setSelectedUsers(userids)
          }
          /*  const balance_query = await firebase.firestore().collectionGroup("balance_details");
            let balancearr=[]
            await balance_query.get().then((balances) => {
              balances.forEach(function(doc) {
                 
                  if (doc.exists) {
                    const balance = doc.data();
                   // console.log(balance,doc.ref.path.split('/')[1])
                    const uid = doc.ref.path.split('/')[1];        
                    balancearr[uid]=balance;
                  }
               });
            });
           
            setBalancearr(balancearr); */
        } catch (e) {
        setUsersList([])
        setSelectedUsers([])
          handleError(e)
        }    
      }
    const getSiteSettings = async () => {
        setLoading({
            ...loading,
            site_setting: true,
        });
        await api({ contentType: true, auth: true }).get('/delta/get-site-settings').then(res => {
            if(res.data.response && res.data.response.settings) {
                setStranglebot(res.data.response.settings.enable_strangles);
            }
          }).catch(err => {
            throw err;
          })
          setLoading({
            ...loading,
            site_setting: false,
        })

        // var get_site_settings = await firebase.firestore().collection("site-settings").doc('data');
        // get_site_settings.get().then((res) => {
        //     if (res.exists) {
        //         const site_settings = res.data();
        //         setStranglebot(site_settings.enable_strangles)
        //     }
        //     setLoading({
        //         ...loading,
        //         site_setting: false,
        //     });
        // });
    }
    const handleSelectionType = (e) => {
        setSelectedUsers("")
    if (e.target.value === 'all-users') {
       setSelectedUsers(usersList);
     }
     setSelectionType(e.target.value);
     
   }
   function failedUserInfoDisplay(){
      let failedUserInfo=  failedUserDetails.map((detail,index) => { 
        return (<li  key={index}><span className="py-2 d-block"><i className="mdi mdi-chevron-right"></i> {detail.profile.first_name+' '+detail.profile.last_name} - <a className="text-danger">{detail.error}</a></span></li>)
        }) 
        return failedUserInfo;
   }
   function handleSelect(selectedList, selectedItem){
    setSelectedUsers(selectedList);
    }
    useEffect(() => {
        getSiteSettings();
        getExpiryDateValues();
        get_settings();
        get_users(exchangeType);
        setTaillingTakeProfitMsg(TaillingTakeProfit());
    setMintakeProfitMsg(MinimumTakeProfit())   
    setMidPrizeMsg(MidPrize())
}, []);

       
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="DeriSnap" breadcrumbItem="Create Strangle" />
                    {loading.site_setting ? 
                     <Row>
                     <Col lg="12">
                         <Card>
                             <CardBody>
                    <Row>
                    <Col xs="12">
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-hourglass bx-spin me-2" />
                          Loading
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  </CardBody>
                  </Card>
                  </Col>
                  </Row>
                    :<>
                    {stranglebot ? (
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">Create Strangles</h4>
                                        <div className="wizard clearfix">
                                            <div className="steps clearfix">
                                                <ul>
                                                    <NavItem
                                                        className={classnames({
                                                            current: activeTab === 1 || (loading.best_strikes || loading.best_strikes_validation),
                                                        })}>
                                                        <NavLink
                                                            className={classnames({
                                                                active: activeTab === 1,
                                                            })}
                                                            onClick={() => {
                                                                toggleTab(1);
                                                            }}
                                                        >
                                                            <span className="number">1.</span>{" "}
                                                            Choose Expiry
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({
                                                            current: activeTab === 2 && !(loading.best_strikes || loading.best_strikes_validation),
                                                        })}>
                                                        <NavLink
                                                            disabled={!(passedSteps || []).includes(2)}
                                                            className={classnames({
                                                                active: activeTab === 2,
                                                            })}
                                                            onClick={() => {
                                                                toggleTab(2);
                                                            }}
                                                        >
                                                            <span className="number">2.</span>{" "}
                                                            <span>Confirm Strikes</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({
                                                            current: activeTab === 3,
                                                        })}>
                                                      <NavLink
                                                        disabled={!((passedSteps || []).includes(3))}
                                                        className={classnames(
                                                            { active: activeTab === 3 },
                                                            'done'
                                                        )}
                                                        onClick={() => {
                                                            toggleTab(3);
                                                        }}
                                                        >
                                                            <span className="number">3.</span>{" "}
                                                            Bot Setup
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem
                                                        className={classnames({
                                                            current: activeTab === 4,
                                                        })}>
                                                        <NavLink
                                                            disabled={!(passedSteps || []).includes(4)}
                                                            className={classnames(
                                                                {
                                                                active: activeTab === 4}, 'done'
                                                            )}
                                                            onClick={() => {
                                                                toggleTab(4);
                                                            }}
                                                        >
                                                            <span className="number">4.</span>{" "}
                                                            Bot Success
                                                        </NavLink>
                                                    </NavItem>
                                                </ul>
                                            </div>
                                            <div className="content clearfix">
                                                <TabContent
                                                    activeTab={activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation) ? 1 : activeTab}
                                                    className="body"
                                                >
                                                    <TabPane tabId={1}>

                                                        <Row>
                                                            <Col >
                                                             <Label
                                                            htmlFor="formrow-exchange-type"
                                                            md="2"
                                                            className="col-form-label"
                                                            >
                                                            Exchange Region
                                                            </Label>
                                                                <div className="mb-3">

                                                                    <Row className="mb-3">
                                                                    <Col lg="6">
                                                                    <div className="form-check form-check-inline">
                                                                    <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="exchange_type"
                                                                    id="india"
                                                                    value="India"
                                                                    defaultChecked={exchangeType === 1}
                                                                    onChange={(e)=>emptyUsers(e)}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="india">
                                                                    India
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="exchange_type"
                                                                    id="global"
                                                                    value="Global"
                                                                    defaultChecked={exchangeType === 0}
                                                                    onChange={(e)=>emptyUsers(e)}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="global">
                                                                    Global
                                                                    </label>
                                                                </div>
                                                                
                                                                    </Col>                  

                                                                    </Row>
                                                                    <Row className="mb-3">
                                                                    <Col lg="6">
                                                                    <label className="form-label">Users</label>{" "}<i className="bx bx-info-circle" id ="users" />
                                                                        <UncontrolledTooltip placement="top" target="users"> 
                                                                            {ToolTipData.users}
                                                                        </UncontrolledTooltip>
                                                                    <select className="form-select" id="selection_type"  onChange={(e)=>handleSelectionType(e)} value={selectionType}>
                                                                        <option value='all-users'> All Users </option>
                                                                        <option value='selected-users'> Specific Users </option>
                                                                    </select>
                                                                   
                                                                    </Col>
                                                                    {selectionType === 'selected-users'?
                                                                    <Col lg="6">
                                                                        <label className="form-label">Select users</label>  
                                                                        {/*<Multiselect options={usersList} displayValue="display_text" onSelect={handleSelect} onRemove={handleSelect} hidePlaceholder="true" emptyRecordMsg="No users available" /> */}
                                                                        <Select
                                                                            value={selectedUsers}
                                                                            isMulti={true}
                                                                            onChange={handleSelect}
                                                                            options={usersList}
                                                                            classNamePrefix="select2-selection"
                                                                            />
                                                                    </Col>                  
                                                                    :''}
                                                                    </Row>
                                                                    <Row>
                                                                     <Col sm="6" xl="3">   
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Expiration date
                                                                    </Label>{" "}<i className="bx bx-info-circle" id ="SED" />
                                                                        <UncontrolledTooltip placement="top" target="SED"> 
                                                                            {ToolTipData.expiration_date}
                                                                        </UncontrolledTooltip>
                                                                    <select className="form-select" id="exp_date">
                                                                        {
                                                                            expiryDates.map((index) => (
                                                                                <React.Fragment key={index}>
                                                                                    <option value={index}>{formatUTCDate(index)}</option>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    </Col>
                                                                    <Col sm="6" xl="3">   
                                                                    <Label for="basicpill-firstname-input1">
                                                                        Select Index Percentage
                                                                    </Label>{" "}<i className="bx bx-info-circle" id ="Index" />
                                                                    <UncontrolledTooltip placement="top" target="Index"> 
                                                                        {ToolTipData.index_percentage}
                                                                    </UncontrolledTooltip>
                                                                    <div className="input-group">
                                                                        <Input autoComplete="off" 
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="index_percentage"
                                                                            placeholder="Enter Index %"  value={settings.index_percentage} onChange={(event) => setSettings({
                                                                                ...settings,
                                                                                index_percentage: event.target.value
                                                                            })}
                                                                        />
                                                                        <Label className="input-group-text" htmlFor="index_percentage">%</Label>
                                                                    </div>
                                                                    </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>

                                                        </Row>


                                                    </TabPane>
                                                    <TabPane tabId={2}>
                                                        {!loading.best_strikes ? (
                                                            <>
                                                                {bestStrikes ?
                                                                    <div>

                                                                        <Row>
                                                                            <Col lg="4">
                                                                                <Card outline color="primary" className="border">
                                                                                    <CardHeader className="bg-transparent">
                                                                                        <h5 className="my-0 text-primary">
                                                                                            <i className="mdi mdi-arrow-up-bold-outline me-1" />Calls
                                                                                        </h5>
                                                                                    </CardHeader>
                                                                                    <CardBody>
                                                                                        <CardTitle className="mt-0">{bestStrikes.calls.symbol}</CardTitle>
                                                                                        <CardText>
                                                                                            <ul className="list-group">
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{bestStrikes.calls.best_ask}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{bestStrikes.calls.best_bid}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {bestStrikes.calls.sell_price}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(bestStrikes.calls.order_margin).toFixed(2)} (1 cont)</li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {bestStrikes.calls.leverage} </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Actual Index Percentage</b> : {get_actual_index(bestStrikes.calls.strike_price, bestStrikes.index_price)}</li></ul>
                                                                                        </CardText>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>

                                                                            <Col lg="4">
                                                                                <Card outline color="primary" className="border">
                                                                                    <CardHeader className="bg-transparent">
                                                                                        <h5 className="my-0 text-primary">
                                                                                            <i className="mdi mdi-arrow-down-bold-outline me-1" />Puts
                                                                                        </h5>
                                                                                    </CardHeader>
                                                                                    <CardBody>
                                                                                        <CardTitle className="mt-0">{bestStrikes.puts.symbol}</CardTitle>
                                                                                        <CardText >
                                                                                        <ul className="list-group">
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{bestStrikes.puts.best_ask}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{bestStrikes.puts.best_bid}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {bestStrikes.puts.sell_price}</span> </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(bestStrikes.puts.order_margin).toFixed(2)} (1 cont)</li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {bestStrikes.puts.leverage} </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Actual Index Percentage</b> : {get_actual_index(bestStrikes.puts.strike_price, bestStrikes.index_price)}</li></ul>
                                                                                        </CardText>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                            <Col lg="4">
                                                                                <Card outline color="primary" className="border" style={{ "height": "94%" }}>
                                                                                    <CardHeader className="bg-transparent">
                                                                                        <h5 className="my-0 text-primary">
                                                                                            <i className="mdi mdi-information-outline me-1" />Other Informations
                                                                                        </h5>
                                                                                    </CardHeader>
                                                                                    <CardBody>
                                                                                        <CardTitle className="mt-0"></CardTitle>
                                                                                        <CardText>
                                                                                            <ul className="list-group">
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Selected Index Percentage</b> : {bestStrikes.index_percentage}% </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Contract Index</b> : {bestStrikes.contract_index} </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Current Index Price</b>: {Number(bestStrikes.index_price).toFixed(2)} </li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Required Margin</b>: {Number(Number(bestStrikes.puts.order_margin) + Number(bestStrikes.calls.order_margin)).toFixed(2)} (1 cont)</li>
                                                                                                <li className="list-group-item"><b className="text-body fw-bold">Time to expire</b>:  <Countdown date={selectedExpiryDate} renderer={counterRenderer} /></li>

                                                                                            </ul>
                                                                                        </CardText>
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </Col>
                                                                        </Row>


                                                                    </div> : <Alert color="danger" role="alert">
                                                                        No strikes found, please check with some other expiry date / index percentage
                                                                    </Alert>}</>
                                                        ) : <Spinner className="ms-2" color="primary" />}
                                                    </TabPane>
                                                    <TabPane tabId={3}>
                                                    <div>
                                                                        <Form>
                                                                            <Row >
                                                                        <Col lg="12" >
                                                                                                <Row className="mt-3">
                                                                                                        <Col lg="4">
                                                                                                            <Label for="basicpill-namecard-input11" className="mt-3">
                                                                                                                Quantity
                                                                                                            </Label>{" "}<i className="bx bx-info-circle" id ="QTY" />
                                                                                                            <UncontrolledTooltip placement="top" target="QTY"> 
                                                                                                                {ToolTipData.quantity}
                                                                                                            </UncontrolledTooltip>
                                                                                                        {/* </Col>
                                                                                                        <Col> */}
                                                                                                            {/* <Slider value={settings.quantity} min={1} max={1000} onChange={value => setSettings({
                                                                                                                ...settings,
                                                                                                                quantity: value
                                                                                                            })} /> */}
                                                                                                            <div className="input-group">

                                                                                                                <Input
                                                                                                                    type="number" step="1" min="1" max="100"
                                                                                                                    className="form-control"
                                                                                                                    id="qty" value={settings.quantity} onChange={(event) => setSettings({
                                                                                                                        ...settings,
                                                                                                                        quantity: event.target.value
                                                                                                                    })}
                                                                                                                />
                                                                                                                <Label className="input-group-text" htmlFor="qty">Cont</Label>
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    {/* </Row> */}
                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                        
                                                                                                    {/* </Row> */}

                                                                                                    {/* <Row className="mt-3"> */}
                                                                                                       
                                                                                                   
                                                                                                    </Row>
                                                                                                    <Row  className="border opacity-75 bg-light mt-2 ">
                                                                                                            <Col lg="2">
                                                                                                             <h5 className="font-size-15 mt-5 mb-3">
                                                                                                    Re-entry Settings
                                                                                                        </h5>
                                                                                                        </Col>
                                                                                                      <Col lg="2"  className="mt-4 mb-3">
                                                                                                        {/* <FormGroup className="mb-0"> */}
                                                                                                          <Label htmlFor="formrow-firstname-Input">
                                                                                                          Enable Re-Entry
                                                                                                          </Label>{" "}
                                                                                                          <i className="bx bx-info-circle" id ="reentry_enabledreentry_enabled" />
                                                                                                            <UncontrolledTooltip placement="top" target="reentry_enabledreentry_enabled">
                                                                                                                {ToolTipData.reentry_enabled}
                                                                                                            </UncontrolledTooltip>
                                                                                                            {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                                                                                              <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                                                                                         {/* </FormGroup> */}
                                                                                                      {/* </Col> */}
                                                                                                      {/* <Col md="1"> */}
                                                                                                      <div>
                                                                                                              <Switch
                                                                                                              uncheckedIcon={<Offsymbol />}
                                                                                                              checkedIcon={<OnSymbol />}
                                                                                                              className="mb-sm-8 mb-0"                                      
                                                                                                              onChange={(e) => setreentry_enabled(e)} 
                                                                                                              checked={reentry_enabled}
                                                                                                            />
                                                                                                            </div>
                                                                                                      </Col>
                                                                                                      {reentry_enabled ?<>
                                                                                                          <Col lg="3"  className="mt-4 mb-3">
                                                                                                          <FormGroup className="mb-0">
                                                                                                                <Label htmlFor="formrow-firstname-Input">
                                                                                                                Waiting time for Re-Entry (H : M)
                                                                                                                </Label> {" "} 
                                                                                                      <i className="bx bx-info-circle" id ="DHINTERVALSTRANGLESwait_time_reentry" />
                                                                                                            <UncontrolledTooltip placement="top" target="DHINTERVALSTRANGLESwait_time_reentry"> 
                                                                                                                {ToolTipData.wait_time_reentry }
                                                                                                            </UncontrolledTooltip> 
                                                                                                                <Flatpickr
                                                                                                                  className="form-control d-block"
                                                                                                                  placeholder="Select time"
                                                                                                                  name="wait_time_reentry"
                                                                                                                  onChange={(e) => handleDH(e,'strangleswait_time_reentry')}
                                                                                                                  value={toHoursAndMinutes(strangleswait_time_reentry)} 
                                                                                                                  options={{                                      
                                                                                                                    enableTime: true,
                                                                                                                    noCalendar: true,
                                                                                                                    dateFormat: "H:i",
                                                                                                                    time_24hr: true,
                                                                                                                  }}
                                                                                                                />
                                                                                                            </FormGroup>  
                                                                                                          </Col>
                                                                                                          
                                                                                                          {/* </Row>
                                                                                                          <Row> */}
                                                                                                            <Col lg="3"  className="mt-4 mb-3">
                                                                                                          <FormGroup className="mb-0">
                                                                                                                <Label htmlFor="formrow-firstname-Input">
                                                                                                                Time to Close Orders Before Expiry (H : M)
                                                                                                                </Label> {" "} 
                                                                                                      <i className="bx bx-info-circle" id ="time_to_close_orders_before_expiry1" />
                                                                                                            <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry1"> 
                                                                                                                {ToolTipData.time_to_close_orders_before_expiry }
                                                                                                            </UncontrolledTooltip> 
                                                                                                                <Flatpickr
                                                                                                                  className="form-control d-block"
                                                                                                                  placeholder="Select time"
                                                                                                                  name="wait_time_reentry"
                                                                                                                  onChange={(e) => handleDH(e,'time_to_close_orders_before_expiry')}
                                                                                                                  value={toHoursAndMinutes(time_to_close_orders_before_expiry)} 
                                                                                                                  options={{                                      
                                                                                                                    enableTime: true,
                                                                                                                    noCalendar: true,
                                                                                                                    dateFormat: "H:i",
                                                                                                                    time_24hr: true,
                                                                                                                    maxTime: "12:00"
                                                                                                                  }}
                                                                                                                />
                                                                                                            </FormGroup>  
                                                                                                          </Col>
                                                                                                          <Col lg="2"  className="mt-4 mb-3">
                                                                                                          <FormGroup className="mb-0">
                                                                                                          <Label htmlFor="formrow-firstname-Input">
                                                                                                                 Date to stop reentry
                                                                                                                </Label> {" "} 
                                                                                                      <i className="bx bx-info-circle" id ="stopdate_reentry" />
                                                                                                            <UncontrolledTooltip placement="top" target="stopdate_reentry"> 
                                                                                                                {ToolTipData.stop_date_reentry }
                                                                                                            </UncontrolledTooltip> 
                                                                                                            {/*must select only future date*/   }
                                                                                                            <DatePicker
                                                                                                                className="form-control d-block"
                                                                                                                placeholder="Select date"
                                                                                                                name="stopdate_reentry"
                                                                                                                format= "yyyy-MM-dd"
                                                                                                                onChange={(val) => {
                                                                                                                    const date = new Date(val);
                                                                                                                    const dateOnly = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
                                                                                                                    setSettings({
                                                                                                                        ...settings,
                                                                                                                        stopdate_reentry: dateOnly,
                                                                                                                    });
                                                                                                                }}
                                                                                                                value={settings.stopdate_reentry}
                                                                                                                options={{
                                                                                                                    minDate: tomorrow // Set to current date to disable past dates
                                                                                                                }}
                                                                                                            />

                                                                                                          </FormGroup>
                                                                                                          </Col>
                                                                                                          </>:''}
                                                                                                          </Row>
                                                                                                    </Col>
                                                                                                    </Row>
                                                                                                    {!reentry_enabled &&
                                                                                                      
                                                                                                      <Row className="border opacity-75">
                                                                                                          <Col lg="2">
                                                                                                          <h5 className="font-size-15 mt-5 mb-3 ">
                                                                                                          Roll Settings
                                                                                                          </h5>
                                                                                                          </Col>
                                                                                                          <Col lg="2"  className="mt-4 mb-3">
                                                                                                              <Label for="basicpill-namecard-input11">
                                                                                                                  Enable Roll
                                                                                                              </Label>{" "}<i className="bx bx-info-circle" id ="enableroll" />
                                                                                                              <UncontrolledTooltip placement="top" target="enableroll"> 
                                                                                                                  {ToolTipData.roll_enable}
                                                                                                              </UncontrolledTooltip>
                                                                                                          {/* </Col> */}
                                                                                                          {/* <Col lg="4"> */}
                                                                                                              {/* <div className="mb-3"> */}
                                                                                                              {/* <Col md="1"> */}
                                                                                                              <div>
                                                                                                                  <Switch
                                                                                                                      uncheckedIcon={<Offsymbol />}
                                                                                                                      checkedIcon={<OnSymbol />}
                                                                                                                      className="me-1 mb-sm-8 mb-2"
                                                                                                                      checked={Boolean(Number(settings.roll_status))}
                                                                                                                      onChange={(e) => setSettings({
                                                                                                                          ...settings,
                                                                                                                          roll_status: !settings.roll_status
                                                                                                                      })}
                                                                                                                  />
                                                                                                                  </div>
                                                                                                                  </Col>
                                                                                                              {/* </div> */}
                                                                                                          {/* </Col> */}
                                                                                                      {/* </Row> */}
                                                                                                      
                                                                                                      {settings.roll_status ?
                                                                                                          <>
                                                                                                              {/* <Row> */}
                                                                                                                  {/* <Col> */}
                                                                                                                      {/* <Row className="mt-3"> */}
                                                                                                                      <Col lg="2"  className="mt-4 mb-3">
  
                                                                                                                              <Label for="basicpill-namecard-input11" className="mb-2">
                                                                                                                                  Distance from Index
                                                                                                                              </Label>{" "}<i className="bx bx-info-circle" id ="DFX" />
                                                                                                                              <UncontrolledTooltip placement="top" target="DFX"> 
                                                                                                                                  {ToolTipData.roll_index_min_distance}
                                                                                                                              </UncontrolledTooltip>
                                                                                                                          {/* </Col>
                                                                                                                          <Col> */}
                                                                                                                              <div className="input-group">
  
                                                                                                                                  <Input
                                                                                                                                      type="number" step="100" min="0"
                                                                                                                                      className="form-control"
                                                                                                                                      id="roll_index_distance" value={settings.index_distance } onChange={(event) => setSettings({
                                                                                                                                          ...settings,
                                                                                                                                          index_distance: event.target.value
                                                                                                                                      })}
                                                                                                                                  />
  
                                                                                                                              </div>
  
                                                                                                                          </Col>
                                                                                                                      {/* </Row> */}
  
                                                                                                                      {/* <Row className="mt-3"> */}
                                                                                                                          <Col lg="2"  className="mt-4 mb-3">
                                                                                                                              <Label for="basicpill-namecard-input11" >
                                                                                                                                  When to Roll? (H : M)
                                                                                                                              </Label>{" "}<i className="bx bx-info-circle" id ="Roll" />
                                                                                                                              <UncontrolledTooltip placement="top" target="Roll"> 
                                                                                                                                  {ToolTipData.roll_wait_time}
                                                                                                                              </UncontrolledTooltip>
                                                                                                                          {/* </Col>
                                                                                                                          <Col> */}
                                                                                                                              <div className="mb-3">
                                                                                                                                  <Flatpickr
                                                                                                                                      className="form-control d-block"
                                                                                                                                      placeholder="Select time" value={settings.Roll_time_clock ? settings.Roll_time_clock : Bot_settings_default.Strangles.Roll_time_clock} onChange={(e) => setSettings({
                                                                                                                                          ...settings,
                                                                                                                                          Roll_time_clock: new Date(e).getHours() + ':' + new Date(e).getMinutes()
                                                                                                                                      })}
                                                                                                                                      options={{
                                                                                                                                          enableTime: true,
                                                                                                                                          noCalendar: true,
                                                                                                                                          dateFormat: "H:i",
                                                                                                                                          time_24hr: true
                                                                                                                                      }}
                                                                                                                                  />
                                                                                                                              </div>
                                                                                                                          </Col>
                                                                                                                          <Col lg="2"  className="mt-4 mb-3">
                                                                                                                              <Label for="basicpill-namecard-input11" className="">
                                                                                                                                  Premium Roll Percentage
                                                                                                                              </Label>{" "}<i className="bx bx-info-circle" id ="Premium_Roll" />
                                                                                                                                      <UncontrolledTooltip placement="top" target="Premium_Roll"> 
                                                                                                                                          {ToolTipData.roll_premium_min_percentage}
                                                                                                                                      </UncontrolledTooltip>
                                                                                                                          {/* </Col>
                                                                                                                          <Col> */}
                                                                                                                              {/* <Slider value={settings.roll_percentage } step={0.5} min={1} max={100} onChange={value => setSettings({
                                                                                                                                  ...settings,
                                                                                                                                  roll_percentage: value
                                                                                                                              })} /> */}
                                                                                                                              <div className="input-group">
  
                                                                                                                                  <Input
                                                                                                                                      type="number" step="0.5"  max="100"
                                                                                                                                      className="form-control"
                                                                                                                                      id="roll_percentage" value={settings.roll_percentage } onChange={(event) => setSettings({
                                                                                                                                          ...settings,
                                                                                                                                          roll_percentage: event.target.value
                                                                                                                                      })}
                                                                                                                                  />
                                                                                                                                  <Label className="input-group-text" htmlFor="roll_percentage">%</Label>
                                                                                                                              </div>
  
                                                                                                                          </Col>
                                                                                                                      {/* </Row> */}
                                                                                                                      {/* <Row className="mt-3"> */}
                                                                                                                          
                                                                                                                      {/* </Row> */}
                                                                                                                  {/* </Col> */}
                                                                                                              {/* </Row> */}
                                                                                                          </> : ''}
                                                                                                          </Row>
                                                                                                         
  }
                                                                            {/* <Row> */}
                                                                            <div  className="mb-1 mt-1" >
                                                                            <a href="#"
                                                                                                           onClick={() => {
                                                                                                             setStrangleAdvancedSettings(!strangleAdvancedSettings);
                                                                                                           }}
                                                                                                       >
                                                                                                           Advanced settings
                                                                                                         </a>
                                                                                                         </div>

                                                                                <Col lg="12">

                                                                                    <Row>
                                                                                        <Col lg="12">
                                                                                      
                                                                                                
                                                                                                    {strangleAdvancedSettings ? <>
                                                                                                    {strangleAdvancedSettings && bestStrikes ? <> <Row className="border">
                                        <div className="panel-body table-responsive">
                                        {/* <table className="table table-bordered"> */}
                                        <Table className={"table table-bordered table align-middle table-nowrap table-check table-responsive"}>
                                          <thead>
                                          <tr style={{textAlign:'left'}}>
                                              <th>Settings</th>
                                              <th>Setting Values</th>
                                              <th>Calls ({bestStrikes.calls.symbol})</th>
                                              <th>Puts ({bestStrikes.puts.symbol})</th>
                                              <th>Info</th>
                                            </tr>
                                          </thead>
                                          <tbody style={{textAlign: 'left'}}>
                                            {rows.map((row, index) => (
                                             <> 
                                             { index === 1 || index === 2  || index === 5 || index == 9 || index == 13 || index == 14 || index == 15 ? <tr  className="table-light"><td ></td><td></td><td></td><td></td><td></td></tr>:''}
                                              <tr key={index}>
                                                <td>{row.label}{ row.label === 'Waiting time to fill Qty (H : M)' || row.label === 'Automatic Market Fill' || row.label === 'Universal Stop' ? ' ' :  " %  "}{row.target === 'ASV'  ? "" : <i className="bx bx-info-circle" id={row.target} onClick={(e) => { handleModel(e, row); 
                                                } }/>}{ row.target === 'ONCLICK' || row.target === 'ASV' ? '': <UncontrolledTooltip placement="top" target={row.target}>
                                                                                 <div className="tooltip-content">
                                                                          <div className="tooltip-header"><h6>{row.orgSettingsName}</h6></div>
                                                                      <hr className="m-2 p-0"/>
                                                                      <div style={{textAlign:"left"}} className="tooltip-body" dangerouslySetInnerHTML={{ __html: ToolTipData[row.tooltip] }}></div></div>
                                                                              </UncontrolledTooltip>}</td>
                                                                              {row.label === 'Activate Trailing' || row.label === 'Waiting time to fill Qty (H : M)' || row.label === 'Automatic Market Fill' || row.label === 'Universal Stop' ? "" : (
                                                                                  <td>
                                                                                  {row.label === 'Approximate Strike Values' ? (
                                                                                    'Index Price - ' + Number(bestStrikes.index_price || 0).toFixed(2)
                                                                                  ) : row.label === 'Activate Trailing' ? '' : (
                                                                                    <div className="d-flex align-items-center" style={{ width: 'auto', maxWidth: '80px' }}>
                                                                                      <Input
                                                                                        type="number"
                                                                                        max={row.max}
                                                                                        step={row.step}
                                                                                        min={row.min}
                                                                                        invalid={errors[row.name]?.message ? true : false}
                                                                                        {...register(row.name, row.validation || {})}
                                                                                        className="form-control"
                                                                                        id={row.tooltip}
                                                                                        name={row.name}
                                                                                        value={row.settings}
                                                                                        style={{ width: '80px', backgroundImage: 'none' }}
                                                                                        onChange={(e) => handleInputChange(e, index, row.tooltip, row.botSettingsvalue)}
                                                                                        onInput={(e) => handleInputChange(e, index, row.tooltip, row.botSettingsvalue)}
                                                                                      />
                                                                                      
                                                                                      {errors[row.name]?.message && (
                                                                                        <div style={{marginLeft: '10px'}}>
                                                                                          <small className="text-danger">{errors[row.name]?.message}</small>
                                                                                        </div>
                                                                                      )}
                                                                                    </div>
                                                                                  )}
                                                                                </td>
                                                                                )}
                                                {row.label === 'Activate Combined SL at' || row.label === 'Activate Combined Trailing at' || row.label === 'Combined Trail Exit at' || row.label === 'Combined Take Profit at'  || row.label === 'Activate SL if index changes to' || row.label === 'Activate Trailing' ? '' :<td >{row.label === 'Waiting time to fill Qty (H : M)' ? 
                                                                                (<><Flatpickr
                                                                                    style={{ width: '80px', backgroundImage: 'none' }}
                                                                                    className="form-control d-block"
                                                                                    placeholder="Select time" value={settings.Qty_time_clock ? settings.Qty_time_clock : Bot_settings_default.Strangles.waiting_time_to_fill_qty} onChange={(e) => setSettings({
                                                                                        ...settings,
                                                                                        Qty_time_clock: new Date(e).getHours() + ':' + new Date(e).getMinutes()
                                                                                    })}
                                                                                    options={{
                                                                                        enableTime: true,
                                                                                        noCalendar: true,
                                                                                        dateFormat: "H:i",
                                                                                        time_24hr: true
                                                                                    }}
                                                                                /></>)
                                                                                : 
                                                                                row.label === 'Automatic Market Fill' ? 
                                                                                <Switch
                                                                                    uncheckedIcon={<Offsymbol />}
                                                                                    checkedIcon={<OnSymbol />}
                                                                                    className="me-1 mb-sm-8 mb-2"
                                                                                    checked={Boolean(Number(settings.autoMarketFill))}
                                                                                    onChange={(e) => setSettings({
                                                                                    ...settings,
                                                                                    autoMarketFill: !settings.autoMarketFill
                                                                                    })}
                                                                                />:
                                                                                row.label === 'Universal Stop' ? 
                                                                                <Switch
                                                                                    uncheckedIcon={<Offsymbol />}
                                                                                    checkedIcon={<OnSymbol />}
                                                                                    className="me-1 mb-sm-8 mb-2"
                                                                                    checked={Boolean(Number(settings.universal_stop))}
                                                                                    onChange={(e) => setSettings({
                                                                                    ...settings,
                                                                                    universal_stop: !settings.universal_stop
                                                                                    })}    
                                                                                />                                      
                                                                                :
                                                                                row.calls }</td>}
                                                <td colSpan={row.label === 'Activate Combined SL at' || row.label === 'Activate Combined Trailing at' || row.label === 'Combined Trail Exit at' || row.label === 'Combined Take Profit at'  || row.label === 'Activate SL if index changes to' || row.label === 'Waiting time to fill Qty (H : M)' || row.label === 'Automatic Market Fill' || row.label === 'Universal Stop'  ? 2 : row.label === 'Activate Trailing' ? 3 : 1} style={row.label === 'Activate Combined SL at' || row.label === 'Activate Combined Trailing at' || row.label === 'Combined Trail Exit at' || row.label === 'Combined Take Profit at' || row.label === 'Activate SL if index changes to' ? {textAlign:'center'}:{}} >{row.label === 'Activate Trailing' ? (<><input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  id="straddlesMin"
                                                                                  checked={SlTriggerStrangles}
                                                                                  onChange={(event) => {setSlTriggerStrangles(event.target.checked);setSettings({
                                                                                    ...settings,
                                                                                    Sl_trigger_take_profit: event.target.checked
                                                                                })}}
                                                                                />{" "}<label className="form-check-label font-size-10" htmlFor="straddlesMin">{row.puts}</label><p style={{fontSize:'8px'}}>Activates trailing and take profit after stop loss hit on one leg.</p></>): ( row.label === 'Activate SL if index changes to' ? 
                                                                                  <tr className="d-flex justify-content-between space-between-equal align-items-center" >
                                                                                    <td >{row.calls}</td>
                                                                                    <td ><b>or</b></td>
                                                                                    <td >{row.puts}</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                  </tr>
                                                                                :
                                                                                row.label === 'Activate Combined SL at' || row.label === 'Activate Combined Trailing at' || row.label === 'Combined Trail Exit at' || row.label === 'Combined Take Profit at'  || row.label === 'Activate SL if index changes to' ? <tr className="d-flex justify-content-between space-between-equal align-items-center" >
                                                                                <td ></td>
                                                                                <td >{row.puts}</td>
                                                                                <td ></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                              </tr> : row.puts)}</td>
                                                                                <td>
                                                                                {row.label === 'Waiting time to fill Qty (H : M)' || row.label === 'Automatic Market Fill' || row.label === 'Universal Stop' ? '' :  <><i style={{ cursor: 'pointer', fontSize: '1rem', padding: '0.5rem' }} className="bx bx-detail" id={(row.target)+(row.tooltip)+(row.botSettingsvalue)} />
                                                                                <UncontrolledTooltip style={{maxWidth: "none",width: "auto"}} placement="top" target={(row.target)+(row.tooltip)+(row.botSettingsvalue)}>
                                                                                 <div className="tooltip-content">
                                                                          <div className="tooltip-header"><h6>Details</h6></div>
                                                                      <hr className="m-2 p-0"/>
                                                                      <div className="tooltip-body" style={{textAlign:'left'}}>{row.formula}</div></div>
                                                                              </UncontrolledTooltip></> }
                                                            {/* <span className="d-inline-block" tabIndex={index}  data-toggle="tooltip" title={row.formula}>
                                                            <button className="btn btn-secondary" style={{pointerEvents: 'none'}} type="button" disabled>Disabled button</button>
                                                          </span> */}
                                                         
                                                      </td>
                                                                              
                                              </tr></>
                                            ))}
                                            
                                          </tbody>
                                        </Table>
                                        
                                        {/* </table> */}
                                        </div>
                                          
                                          
                                        </Row>

                                      </> : ""} 
                                                                                            </>:""}
                                                                                          
                                                                                        </Col>
                                                                                    </Row>
                                                                                    





                                                                                </Col>


                                                                               
                                                                            {/* </Row> */}

                                                                        </Form>
                                                                    </div>
                                                    </TabPane>
                                                    <TabPane tabId={4}>
                                                        <div className="row justify-content-center">
                                                            <Col lg="6">
                                                                <div className="text-center">
                                                                    <div className="mb-4">
                                                                        <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                                    </div>
                                                                    
                                                                    <div>
                                                                        <h5>Bot started successfully</h5>
                                                                        {failedUserDetails.length > 0 ?
                                                                            <p>
                                                                            <Alert className="alert-danger" role="alert">
                                                                                <h4 className="alert-heading">Orders failed for below user(s):</h4>
                                                                                <ul className="list-unstyled fw-medium">                                                                        { failedUserInfoDisplay() }
                                                                                <span className="badge badge-soft-danger badge-pill float-end ms-1 font-size-12">{failedUserDetails.length > 9 ? failedUserDetails.length : '0' + failedUserDetails.length}</span>
                                                                                </ul>
                                                                            </Alert>
                                                                            </p>
                                                                            : ""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </div>
                                                    </TabPane>
                                                </TabContent>
                                                {(activeTab == 2 && (!loading.best_strikes_validation && validateStrikes != '')) && <>
                                                    <Alert color="danger" role="alert">
                                                        {validateStrikes}
                                                    </Alert></>}
                                            </div>
                                            <div className="actions clearfix">

                                                <ul>
                                                {
                                                errors && Object.keys(errors).length > 0 ?
                                                (
                                                <li className="text-center text-danger fw-semibold">Kindly fill all the required fields above.*</li>
                                                )
                                                : ''
                                                }  
                                                {   activeTab !== 1 && !(activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation)) ?
                                                    <li
                                                        className={
                                                            activeTab === 1
                                                                ? "previous disabled"
                                                                : "previous"
                                                        }
                                                    >
                                                        <Link
                                                            to="#"
                                                            onClick={() => {
                                                                toggleTab(activeTab - 1);
                                                            }}
                                                        >
                                                            Previous
                                                        </Link>
                                                    </li>
                                                        : '' }
                                                    <li
                                                        className={
                                                            activeTab === 4 || (activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation || validateStrikes != '' || !bestStrikes)) || (activeTab == 3 && loading.start_bot)
                                                                ? "next disabled"
                                                                : "next"
                                                        }
                                                    >
                                                       
                                                            {(activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation)) || (activeTab == 3 && loading.start_bot) ?(<Link><span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing</span></Link> ) :
                                                            <Link
                                                            to="#"
                                                            onClick={() => {
                                                                (activeTab === 4 || (activeTab == 2 && (loading.best_strikes || loading.best_strikes_validation || validateStrikes != '' || !bestStrikes)) || (activeTab == 3 && loading.start_bot)) ? '' :
                                                                activeTab == 3 ? setStrangleAdvancedSettings(true): '';
                                                                toggleTab(activeTab + 1);
                                                            }}
                                                        >
                                                            {activeTab === 3 ? "Start bot" : "Next"}
                                                            </Link>
                                                        }
                                                       
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>




                        </Row>
                    ):  <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                   <Row>
                   <Col xs="12">
                     <div className="text-center my-3">
                     <Alert color="danger" role="alert">
                     <i className="mdi mdi-block-helper me-2"></i>Strangles in disabled status. You can enable it using <a href="/settings">Settings</a>
                      </Alert>
                     </div>
                   </Col>
                 </Row>
                 </CardBody>
                 </Card>
                 </Col>
                 </Row> } </> }
                </Container>
            </div>
            {/*Listing orders */}
            <Modal
        isOpen={visibleMin}
        toggle={() => {
        setVisibleMin(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
        setVisibleTailling(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleMidPrize}
        toggle={() => {
        setVisibleMidPrize(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMidPrize(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MidPrizeMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMidPrize(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
        </React.Fragment>
    );
}
export default ListOptions;
