import React from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import { Link } from "react-router-dom";

const IndianAdminWallet = ({ userBalance }) => {
  return (
    <Col xl="6">
      <Card style={{ minHeight: "92%" }}>
        <CardBody>
          <h4 className="card-title text-capitalize">Admin Wallet Details</h4>
          <Row>
            <Col lg="4">
              <div className="border p-3 rounded mt-4">
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-warning text-white font-size-18">
                      <i className="mdi mdi-bitcoin" />
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">BTC</h5>
                </div>

                <Row>
                  <div className="col-lg-6">
                    <div className="mt-3">
                      <p>Wallet</p>
                      <h4
                        className={
                          userBalance &&
                          userBalance.btc &&
                          userBalance.btc.balance
                            ? "text-warning"
                            : "text-white"
                        }
                      >
                        {userBalance &&
                        userBalance.btc &&
                        userBalance.btc.balance
                          ? parseFloat(userBalance.btc.balance).toFixed(2)
                          : 0}
                      </h4>
                      {/* <p className="mb-0">0.00745 BTC</p> */}
                    </div>
                  </div>

                  <div className="col-lg-6 align-self-end">
                    <div className="float-end mt-3">
                      <p>Available</p>
                      <h4
                        className={
                          userBalance &&
                          userBalance.btc &&
                          userBalance.btc.available_balance
                            ? "text-warning"
                            : "text-white"
                        }
                      >
                        {userBalance &&
                        userBalance.btc &&
                        userBalance.btc.available_balance
                          ? parseFloat(
                              userBalance.btc.available_balance
                            ).toFixed(2)
                          : 0}
                      </h4>
                      {/* <p className="mb-0">0.00745 BTC</p> */}
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
            <Col lg="4">
              <div className="border p-3 rounded mt-4">
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-success text-white font-size-18">
                      <i className="mdi mdi-alpha-t-circle" />
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">USD</h5>
                </div>

                <Row>
                  <div className="col-lg-6">
                    <div className="mt-3">
                      <p>Wallet</p>
                      <h4
                        className={
                          userBalance &&
                          userBalance.usd &&
                          userBalance.usd.balance
                            ? "text-success"
                            : "text-block"
                        }
                      >
                        {userBalance &&
                        userBalance.usd &&
                        userBalance.usd.balance
                          ? parseFloat(userBalance.usd.balance).toFixed(2)
                          : 0}
                      </h4>
                    </div>
                  </div>

                  <div className="col-lg-6 align-self-end">
                    <div className="float-end mt-3">
                      <p>Available</p>
                      <h4
                        className={
                          userBalance &&
                          userBalance.usd &&
                          userBalance.usd.available_balance
                            ? "text-success"
                            : "text-block"
                        }
                      >
                        {userBalance &&
                        userBalance.usd &&
                        userBalance.usd.available_balance
                          ? parseFloat(
                              userBalance.usd.available_balance
                            ).toFixed(2)
                          : 0}
                      </h4>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
            <Col lg="4">
              <div className="border p-3 rounded mt-4">
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary text-white font-size-18">
                      <i className="mdi mdi-ethereum" />
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">ETH</h5>
                </div>

                <Row>
                  <div className="col-lg-6">
                    <div className="mt-3">
                      <p>Wallet</p>
                      <h4
                        className={
                          userBalance &&
                          userBalance.eth &&
                          userBalance.eth.balance
                            ? "text-primary"
                            : "text-white"
                        }
                      >
                        {userBalance &&
                        userBalance.eth &&
                        userBalance.eth.balance
                          ? parseFloat(userBalance.eth.balance).toFixed(2)
                          : 0}
                      </h4>
                    </div>
                  </div>

                  <div className="col-lg-6 align-self-end">
                    <div className="float-end mt-3">
                      <p>Available</p>
                      <h4
                        className={
                          userBalance &&
                          userBalance.eth &&
                          userBalance.eth.available_balance
                            ? "text-primary"
                            : "text-white"
                        }
                      >
                        {userBalance &&
                        userBalance.eth &&
                        userBalance.eth.available_balance
                          ? parseFloat(
                              userBalance.eth.available_balance
                            ).toFixed(2)
                          : 0}
                      </h4>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default IndianAdminWallet;
