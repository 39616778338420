import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function headerConfig(contentType, auth, formData) {
 const header = {};

	if (contentType && typeof contentType === 'string') {
		header['Content-Type'] = contentType;
	} else {
		header['Content-Type'] = 'application/json';
	}

	if(formData === true) {
		header['Content-Type'] = 'multipart/form-data';
	}

	/* if (auth === true) {
		const token = localStorage.getItem('fbase_token');		
		header.Authorization = `Bearer ${token}`;
	} */

	return header;
}

const getFirebaseToken = () => {
	const auth = getAuth();	
	return new Promise((resolve) => {
	  onAuthStateChanged(auth, (user) => {
		if(user){
			user.getIdToken().then((token) => {				
			  return resolve(token);
		  });
		}
	  });
	});
  }

const instance = ({ contentType, auth, formData }) => {
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers: headerConfig(contentType, auth, formData)
	});

	if (auth === true) {
		instance.interceptors.request.use(async function (config) {
			const fbase_token = await getFirebaseToken();			
			config.headers['Authorization'] = `Bearer ${fbase_token}`;
			return config;
	  
		});

		instance.interceptors.response.use((response) => response, (error) => {
			if (window.location.pathname !== '/login' && (error.response.status === 401 || error.response.status === 403)) {
				localStorage.removeItem("user");
				window.location.href = '/login';
			} else {
				throw error;
			}			
		});
		  
	}

	return instance;
} 

export default instance;