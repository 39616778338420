import EventEmitter from 'events';
import { createHmac } from "crypto"
import hmacSHA256 from 'crypto-js/hmac-sha256';
const wait = (n) => new Promise((r) => setTimeout(r, n));

export class Connection extends EventEmitter {
  constructor(uid,appURL,appKey,appSecret) {
    super();
    
    this.uid = uid;    
    this.WSdomain = appURL;
    this.key = appKey;
    this.secret = appSecret;		
    
    this.connected = false;
    this.isReadyHook = false;
    this.isReady = new Promise((r) => (this.isReadyHook = r));
    this.authenticated = false;
    this.reconnecting = false;
    this.afterReconnect = '';

    this.inflightQueue = [];
    this.subscriptions = [];    
    this.callbacks = [];

    this.id = Math.floor(1000 + Math.random() * 9000);
    this.response_payload = "";    
  }

  nextId() { 
	  return Math.floor(1000 + Math.random() * 9000);
  }

  handleError = (e) => {
    //console.log(new Date(), "[DELTA] DELTA ERROR", e);
  };
  
  //Socket connection handler
  _connect() {
    if (this.connected) {
      return;
    }
    
    return new Promise((resolve, reject) => {
      this.ws = new WebSocket(`wss://${this.WSdomain}`);
      /* console.log(
        "Wss URL ------------------------------",
        `wss://${this.WSdomain}`
      );  */
      
      //socket on messge(response)
      this.ws.onmessage = this.handleWSMessage;

      //socket on open
      this.ws.onopen = () => {
        this.connected = true;	              
        
        //Enable heart beat
        this.ws.send(JSON.stringify({
          "type": "enable_heartbeat"
        }))        
        this.pingInterval = setInterval(this.ping, 35 * 1000);        

        this.emit("statusChange", "connected");
        resolve();
      };
      this.ws.onerror = this.handleError;      

      //socket on close
      this.ws.onclose = async (e) => {
        this.isReady = new Promise((r) => (this.isReadyHook = r));
        this.emit("statusChange", "closed");
        //console.log(new Date(), "[DELTA] CLOSED CON");
        this.inflightQueue.forEach((queueElement) => {
          queueElement.connectionAborted(
            new Error("DELTA connection closed.")
          );
        });
        this.inflightQueue = [];
        this.authenticated = false;
        this.connected = false;
        clearInterval(this.pingInterval);
        this.reconnect();
        this.isReadyHook();
      };
    });
  }

  //Terminate connection if no heart beat received
  ping = async () => {
    //console.log("termintate");
    this.terminate();	  
  };

  //Terminate a connection and immediatly try to reconnect
  terminate = async () => {
    //console.log(new Date(), "[DELTA] TERMINATED WS CON");
    this.ws.close();
    this.authenticated = false;
    this.connected = false;
  };

  //End a connection
  end = () => {
    //console.log(new Date(), "[DELTA] ENDED WS CON");
    clearInterval(this.pingInterval);
    this.ws.onclose = undefined;
    this.authenticated = false;
    this.connected = false;
    this.ws.close();
  };

  //Socket connection
  connect = async () => {
    try {
      await this._connect();
      //if (this.key) {
        await this.authenticate();
      //}
    } catch (error) {
      //console.log("Connection ERROR: ", error);
    }
  };

  //Socket reconnection
  reconnect = async () => {
    this.reconnecting = true;

    let hook;
    this.afterReconnect = new Promise((r) => (hook = r));
    await wait(500);
    //console.log(new Date(), "[DELTA] RECONNECTING...");
    await this.connect();
    hook();
    this.isReadyHook();

    /* if (this.subscriptions.length) {
      this.subscriptions.forEach((channel) => {
        this.subscribe(channel);
      });
    }  */   

    this.reconnecting = false;
  };

  get_time_stamp=()=>{
    var d, epoch;
    var tmLoc = new Date();
    d = tmLoc.getTime()+ tmLoc.getTimezoneOffset() * 60000;
    epoch=new Date("01/01/1970").getTime()
    var seconds = Math.abs(d - epoch) / 1000;
    return Number.parseInt(seconds).toString();
  };

  generate_signature=(secret, message)=>{
    var hash;
    hash = hmacSHA256(message, this.secret);    
    /* hmac.write(message);
    hmac.end();       // can't read from the stream until you call end()
    hash = hmac.read().toString('hex');  */
    return hash;
  };

  //Socket connection authentication
  authenticate = async () => {

    if (!this.connected) {
      await this.connect();
    }
	  
    var method = "GET";
    var timestamp = this.get_time_stamp();
    var path = "/live";
    var signature_data = method + timestamp + path;
    var signature = this.generate_signature(this.secret, signature_data);
	  
		var req={
		  "type": "auth",
		  "payload": {
			"api-key": this.key,
			"signature": signature,
			"timestamp": timestamp
		  }
    };
    
    const resp = await this.sendMessage(req);

    if (resp.error) {
      throw new Error(resp.error.message);
    }

    this.authenticated = true;
  };

  findRequest(id) {
    for (let i = 0; i < this.inflightQueue.length; i++) {
      const req = this.inflightQueue[i];
      if (id === req.id) {
        this.inflightQueue.splice(i, 1);
        return req;
      }
    }
  }

  //Socket connection response hanlder
  handleWSMessage = (e) => {
    let payload;
    if (this.callbacks.length) {
      this.callbacks.forEach((callback)=>{
        callback(e);
      })      
    }
    
    try {      
      payload = JSON.parse(e.data);
      this.response_payload = payload;
	    // console.log("payload message",payload)
    } catch (e) {
      //console.error("DELTA send bad json", e);
    }

    if (payload.type === "subscriptions") {
      //return console.log("subscriptions done:", payload.channels);      
      return;
    }

    if (payload.type === "heartbeat") {      
      clearInterval(this.pingInterval);
    }

    const request = this.findRequest(payload.id);

    if (!request) {
      if (payload.type) {        
        //return console.log("received response for:", payload.type);
        return;
      } else {        
        return;
        //return console.error("received response to request not send:", (payload.id)?payload.id:'',payload.type);
      }      
    }

    payload.requestedAt = request.requestedAt;
    payload.receivedAt = +new Date();
    request.onDone(payload);
  };

  //Socket connection sending request hanlder
  sendMessage = async (payload, fireAndForget) => {
    if (!this.connected) {
      if (!this.reconnecting) {
        throw new Error("Not connected.");
      }

      await this.afterReconnect;
    }

    let p;
    if (!fireAndForget) {
      let onDone;
      let connectionAborted;
      p = new Promise((r, rj) => {
        onDone = r;
        connectionAborted = rj;
      });

    var values=	Object.keys(payload).map(function(key){return payload[key]})
        this.inflightQueue.push({
          requestedAt: +new Date(),
          id: values[1].id,
          onDone,
          connectionAborted,
        });
    }    
    
    await this.ws.send(JSON.stringify(payload));

    return p;
  };

  //Socket connection sending request
  request = async (path, params) => {
    
    if (!this.connected) {
      if (!this.reconnecting) {
        await this.connect()
      } else {
        await this.afterReconnect;
      }      
    }
    
    const message = {	
     "type": "rpc",	
       "payload" : {	
          "method": path,	
          "params" : params,	
          "id": this.nextId().toString()	
        } 	
    };	
    
    return this.sendMessage(message);
  };

  subscribe = async (channel) => {
    if (!this.subscriptions.find((s) => s.channel.name == channel.name))
      this.subscriptions.push({ channel });

    if (!this.connected) {
      throw new Error("Not connected.");
    } else if (!this.authenticated) {
      throw new Error("Not authenticated.");
    }

    const message = {
        "type": "subscribe",
        "payload": {
          "channels": [
              channel
          ]
      }
    }
    //console.log(message);

    return this.sendMessage(message);
  };
}