import React, { useCallback, useEffect, useState } from "react"

import {
  Badge,  
  Card,
  CardBody,  
  Col,
  Container,  
  Row,  
  Table,
  Tooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { closeAdminBot, marketClose, stopBot } from "helpers/diagonal_helper"
import { formatUTCDate, CustomParseFloat, getCalendarTypeName, getFirstPositionName, formatUTCDateTime, getOrderStatusNameStyle, counterRenderer , getOrderLogs } from "services/common.service"
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import "react-rangeslider/lib/index.css";
import Countdown from "react-countdown"
import Swal from "sweetalert2"
import HistoryModal from "components/Modal/HistoryModal"

const ActiveDiagonals = () => {
 
  firebase.auth();
  //Current User  
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  
  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }

  //meta title
  // document.title="Active Diagonals";
  const firestoreDb = firebase.firestore();
  const [ttop, setttop] = useState(false);
  //Active Diagonals
  const [activeDiagonals, setActiveDiagonals] = useState([]);
  const [modalShow, setModalShow] = useState(false);	
  const [orderHistoryData, setOrderHistoryData] = useState([]);

  const getActiveDiagonals = useCallback(() => {	     
    const activeDiagonalsResult = firestoreDb.collectionGroup("diagonal_orders").where("admin_bot", '==', true).where("admin_id", '==', uid).orderBy("created_at", "desc");
    
    activeDiagonalsResult.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.dbid = doc.id;
        items.push(item);
        // Display if any notifications
        let currentdate = new Date().toISOString()
        let currentdateTime = new Date(currentdate).getTime();
        if (item.notification && item.notification.timestamp >= currentdateTime) {      
          if (item.notification.type === 'success') {          
            toast.success(item.notification.message, { toastId: item.notification.toast_id });
          } else {
            toast.error(item.notification.message, { toastId: item.notification.toast_id });    
          }        
        }            
      });
      
      // Combine orders by admin diagonal id
      const all_diagonals = items.reduce(function (row, obj) {
        row[obj.admin_diagonal_id] = row[obj.admin_diagonal_id] || [];
        row[obj.admin_diagonal_id][obj.diagonal_id] = row[obj.admin_diagonal_id][obj.diagonal_id] || [];
        row[obj.admin_diagonal_id][obj.diagonal_id].push(obj);
        return row;
      }, []);    
      
      // Find diagonal status - to enable market close
      const active_diagonals = Object.keys(all_diagonals).map(admin_diagonal_index => {         
        return Object.keys(all_diagonals[admin_diagonal_index]).map(diagonal_index => {          
          const rows = all_diagonals[admin_diagonal_index][diagonal_index];
          const filled_order = rows.find(element => element.status.toLowerCase() === 'closed' || element.filled_quantity > 0);          
          const diagonal_status = (filled_order) ? "closed" : "open";      
          return rows.map((row,index) => {
              row.diagonal_status = diagonal_status;          
            return row
          })          
        });
      });
      
      setActiveDiagonals(active_diagonals);		  
    });  
    
  }, []); 

  const getLogsBysymbol = async (uid,dbid,symbol) => {
    try {  
      var body =  {
        uid : uid,
        strategy : "diagonals",
        dbid : dbid
      }  
      let order_history= await getOrderLogs(body);
      if(order_history){
          setModalShow({
            dbid: dbid,
            show: true,
            symbol:symbol
          });
          setOrderHistoryData(order_history);
        } else {
          setOrderHistoryData([]);
          setModalShow({
            dbid: dbid,
            show: true,
            symbol:symbol
          });
        }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);        
      } else if (err.message) {
        toast.error(err.message);         
      } else if (err.request) {
        //console.log(err.message);
      } else {        
        //console.log(err.message);
      }
    }
  };
  // Stop diagonal
  const stopDiagonalBot = async (uid,diagonal_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async(result) => {
        if (result.isConfirmed) {
        toast.success('Stop diagonal was initiated.');
        const params = {
          uid: uid,
          diagonal_id: diagonal_id        
        };
        await stopBot(params).then((res) => {
            if (res.data !== "") {
              toast.success(res.data.message);                  
            }
        }).catch(err => {
          handleError(err);
        });
        }
      })
    } catch (err) {      
      handleError(err)
    }
  };
  
  const marketCloseDiagonal = async (uid,diagonal_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async(result) => {
        if (result.isConfirmed) {
          toast.success('Market close was initiated.');
          const params = {
            uid: uid,
            diagonal_id: diagonal_id        
          };
          await marketClose(params).then((res) => {
              if (res.data !== "") {
                toast.success(res.data.message);          
              }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err)  
    }
  };

  const CloseAdminBot = async (admin_diagonal_id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to close the bot ?",
        icon: 'warning',
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-secondary",
        reverseButtons: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then(async(result) => {
        if (result.isConfirmed) {
          toast.success('Closing bot was initiated.');
          const params = {        
            admin_diagonal_id: admin_diagonal_id        
          };
          await closeAdminBot(params).then((res) => {
              if (res.data !== "") {
                toast.success(res.data.message);          
              }
          }).catch(err => {
            handleError(err);
          });
        }
      })
    } catch (err) {
      handleError(err)
    }
  };

  const sendMsg = async (user_id) => {
    const { value: text } = await Swal.fire({
      input: 'textarea',
      title: 'Message',
      buttonsStyling: false,
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-secondary",
      reverseButtons: true,
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true
    })
    
    if (text) {
      const bodyparams = {
        user_id :user_id,
        msg : text
      }
      await sendUserMessage(bodyparams).then((res) => {
        toast.success('Message sent successfully');
      }).catch(err => {
        toast.error('Chat Not Found');
        throw err;
      });    
    }
  }

  useEffect(() => { 
    let mounted = true;
    if (mounted) {
      getActiveDiagonals();
    }         
    return () => {
      // actions to be performed when component unmounts
      mounted = false;
    }
  },[]);

  return (
    <React.Fragment>
      <div className="page-content">        
        <Container fluid>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Active Diagonals" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">Orders</h4>
                  <div className="table-responsive">
                    {/*********/}
                    <Table className="table table-bordered table align-middle table-nowrap table-check">
                        <thead >
                            <tr>
                                <th>Bot</th>
                                <th>User</th>
                                <th>Order Expiry</th>
                                <th>Side</th>
                                <th>Symbol</th>
                                <th>Sell/Buy Price</th>
                                <th>Filled/Qty</th>
                                <th>Order ID</th>
                                <th>Status</th>
                                <th>LTP</th>
                                <th className="text-decoration-underline" >
                                    <Tooltip
                                        placement="top"
                                        isOpen={ttop}
                                        target="TooltipTop"
                                        toggle={() => {
                                            setttop(!ttop);
                                        }}
                                    >
                                        Sell order: Price - Lowest ask <br /> Buy order: Highest bid - Price
                                    </Tooltip>
                                    <span id="TooltipTop">UPNL</span>
                                </th>
                                <th>History</th>
                                <th  className="text-center" colSpan={2}>Action</th>
                            </tr>
                        </thead>
                        {activeDiagonals.length > 0 ? (
                            activeDiagonals.map((users, index) => {
                                return (
                                    <tbody key={index}>
                                    {users.map((row, row_index) => {
                                      return (
                                        row.map((order, index, arr) => {
                                            
                                            return (
                                                <tr className="trow-hover" key={index}>
                                                    {row_index===0 && index === 0 ?
                                                        <td rowSpan={(users.length * row.length)}>
                                                            <div className="p-2">
                                                            <b className="text-primary font-size-15">{formatUTCDate(order.order_settings.short_expiry)}</b>
                                                            <div className="text-primary">
                                                              Expiry:
                                                              <span className="expiry-countdown font-size-11" >
                                                                <Countdown date={order.order_settings.market_close_time} renderer={counterRenderer} />
                                                              </span>
                                                            </div>
                                                            {order.order_settings.calendar_type ? <div className="font-size-12 mt-1">{getCalendarTypeName(order)}</div> : ''}
                                                            {order.order_settings.expiry_position_to_begin ? <div className="font-size-12">Start from: {getFirstPositionName(order)}</div> : ''}
                                                            {order.order_settings.take_profit_percentage ? <div className="font-size-12">Take Profit: {order.order_settings.take_profit_percentage}%</div> : ''}
                                                            {order.diagonal_total_pnl ? <div className="font-size-12" title={order.diagonal_total_pnl}>Current PNL is {order.diagonal_total_pnl.toFixed(2)} {order.region == 1 ?' USD':" USDT"}</div> : ''}
                                                            <div className="font-size-12 mt-2"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(order.created_at)}</div>   
                                                            <div className="bg-transparent border-top border-white p-2 mt-3">
                                                              <div className="contact-links d-flex font-size-20">
                                                                <div className="flex-fill text-center">
                                                                  <button type="button"
                                                                  onClick={() => CloseAdminBot(order.admin_diagonal_id)}className="btn btn-soft-danger btn-sm">
                                                                    Close Bot
                                                                    </button>
                                                                  </div>
                                                              </div>
                                                              </div>                                                         
                                                            </div>
                                                        </td>
                                                    : ''}
                                                    {index === 0 ?
                                                    <td className="td-vertical-align" rowSpan={row.length}>
                                                        {order.profile ?
                                                        <p className="font-size-11 text-captialize">{order.profile.first_name} {order.profile.last_name}</p> : <p></p>}
                                                        <p>{order.profile.role == 'USER' ? <i className="bx bxl-telegram" onClick={() => sendMsg(order.profile.uid)} hidden={order.profile.telegram_updated == 1 ? false : true} style={{ cursor: "pointer" }}></i>  : ''}</p>

                                                    </td>
                                                    : ''}
                                                    <td>
                                                        <div className="font-size-12">{formatUTCDate(order.expiry_date)}</div>
                                                        <div className="">
                                                          <span className="expiry-countdown font-size-11" >
                                                            <Countdown date={order.expiry_date} renderer={counterRenderer} />
                                                          </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Badge
                                                            color={order.direction === "buy" ? "success" : "danger"}
                                                            style={{ "textTransform": "uppercase" }}
                                                        >
                                                            {order.direction}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                    <a href={process.env.REACT_APP_DELTA_TRADE_LINK+'/BTC/'+order.symbol} target="_blank" rel="noreferrer" >
                                                        <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                            {order.symbol}
                                                        </Badge>   
                                                        </a>                                                         
                                                    </td>

                                                    <td>
                                                      <h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{order.average_fill_price ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6>
                                                      {order.highest_bid_now || order.lowest_ask_now ?
                                                          <div>
                                                            <span className="font-size-11">Bid: <span className="text-success">{order.highest_bid_now ? Number(order.highest_bid_now).toFixed(1) : '...'}</span></span>
                                                            <span className="">, </span>
                                                            <span className="font-size-11">Ask: <span className="text-danger">{order.lowest_ask_now ? Number(order.lowest_ask_now).toFixed(1) : '...'}</span></span>
                                                          </div>
                                                          : ""
                                                      }
                                                    </td>                                                        
                                                    <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.quantity).toFixed(0)}</td>
                                                    <td>{order.order_id ? order.order_id : "Nil"}</td>
                                                    <td> <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span></td>
                                                    <td>{order.last_trade_price?order.last_trade_price.toFixed(1):'...'}</td>
                                                    <td title={order.unrealized_pnl ? order.unrealized_pnl : ''}><small>{order.unrealized_pnl ? CustomParseFloat(order.unrealized_pnl,2) + `${order.region == 1 ?' USD':" USDT"}` : '...'}</small></td>
                                                    <td>
                                                    <div className="d-flex justify-content-center">
                                                            <a
                                                                onClick={() =>
                                                                  getLogsBysymbol(order.profile.uid,order.dbid,order.symbol)
                                                                }
                                                                className="text-info"
                                                                disabled={order.status.toLowerCase() === "pending" ? true : false}
                                                            >
                                                                <i className="mdi mdi-history font-size-18"></i>
                                                            </a>
                                                    </div>
                                                    </td>                                                        
                                                    {index === 0 ?
                                                      order.diagonal_status.toLowerCase() === 'closed' || order.market_action ?
                                                        <td className="td-vertical-align align-middle" rowSpan={row.length}>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection: "row",
                                                              justifyContent: "space-evenly",
                                                              alignItems: "center",
                                                              textAlign: "center"
                                                            }}
                                                          >
                                                            {!order.market_action && !order.market_action_status ?
                                                              <button
                                                                type="button"
                                                                onClick={() =>
                                                                  marketCloseDiagonal(order.profile.uid,order.diagonal_id)
                                                                }
                                                                className="btn btn-danger text-white"
                                                              >
                                                                Market Close
                                                              </button>
                                                              :
                                                              <span className="text-sm">Closing... <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span>
                                                              }
                                                          </div>
                                                        </td>
                                                        : 
                                                        <td className="td-vertical-align align-middle" rowSpan={row.length}>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              flexDirection: "row",
                                                              justifyContent: "space-evenly",
                                                              alignItems: "center",
                                                              textAlign: "center"
                                                            }}
                                                          >
                                                            {!order.is_stop_triggered ?
                                                              <button
                                                                type="button"
                                                                onClick={() =>
                                                                  stopDiagonalBot(order.profile.uid,order.diagonal_id)
                                                                }
                                                                className="btn btn-danger btn-lg text-white"
                                                              >
                                                                Stop
                                                              </button>
                                                              :
                                                              <span className="text-sm">Closing... <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle"></i></span>
                                                              }
                                                          </div>
                                                        </td>
                                                      :  ''}
                                                </tr>
                                            )
                                        })

                                      )})}
                                    </tbody>
                                )
                            })
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={13} style={{ textAlign: "center" }}>
                                        No orders found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                    {/*********/}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <HistoryModal
                show={modalShow.show}
                onHide={() => setModalShow({ symbol: "", show: false })}
                data={orderHistoryData}
                symbolname={modalShow.symbol}
        />
      </div>
    </React.Fragment>
  )
}

export default ActiveDiagonals