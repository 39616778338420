import React, { useCallback, useEffect, useState } from "react"

import {
  Badge,
  Card,
  CardBody,  
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,  
  TabContent,
  TabPane,
  Table,
  Tooltip,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { formatUTCDate, getCalendarTypeName, getFirstPositionName, formatUTCDateTime, getOrderStatusNameStyle, counterRenderer, getMarketActionTriggerName } from "services/common.service"
import firebase from "firebase/compat/app";

import "react-rangeslider/lib/index.css";
import Countdown from "react-countdown"
import Flatpickr from "react-flatpickr";
import api from 'helpers/api_helper';

const CompletedDiagonals = () => {
 
  firebase.auth();
  //Current User  
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;

  //meta title
  // document.title="Completed Diagonals";
  const firestoreDb = firebase.firestore();
  
  const [ttop1, setttop1] = useState(false);
  //Completed Diagonals
  const [completedDiagonals, setCompletedDiagonals] = useState([]);
  const defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 29)).toISOString();
  const defaultEndDate = new Date(new Date()).toISOString();
  const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
  const [filterEndDate, setFilterEndDate] = useState(defaultEndDate);
  const [selectionType, setSelectionType] = useState(2)

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const handleCreatedDateChange = (selectedDates, datestr) => {    
    if (selectedDates && selectedDates.length) {
      let converted_date = new Date(convert(selectedDates[0]));
      converted_date.setHours(0, 0, 0, 0);
      setFilterStartDate(converted_date.toISOString()); 
      if(selectedDates[1]){
        let converted_end_date = new Date(convert(selectedDates[1]));
        converted_end_date.setHours(23, 59, 59, 999);
        setFilterEndDate(converted_end_date.toISOString()); 
      }
    } else {
      setFilterStartDate(defaultStartDate); 
      setFilterEndDate(defaultEndDate);       
    }
  }

  // const completedDiagonalsResult = firestoreDb.collectionGroup("history").where("strategy", '==', 'diagonals').where("admin_bot", '==', true).where("admin_id", '==', uid).where("created_at", '>=', filterStartDate).where("created_at", '<=', filterEndDate).orderBy("created_at", "desc");

  const getCompletedDiagonals = async() => { 
    var body =  {
      uid : uid,
      filterStartDate : filterStartDate,
      filterEndDate : filterEndDate,
      type:selectionType
    }
    await api({ contentType: true, auth: true }).post('/abotex/diagonals/diagonal-history-data',body).then(async(res)  => {
    var completedDiagonalsResult = res.data.response.data;
      const items = [];    
      completedDiagonalsResult.forEach((doc) => {
        let item = doc.data;
        item.region = doc.type;     
        items.push(item)
      });
      
      const all_diagonals = items.reduce(function (row, obj) {
        const index =obj.admin_diagonal_id+'_sta';
        const st_index =obj.admin_diagonal_id+'_str';
        row[index] = row[index] || [];
        row[index][st_index] = row[index][st_index] || [];
        row[index][st_index].push(obj);
        return row;
      }, []);    
      
      const completed_diagonals = Object.keys(all_diagonals).map(admin_diagonal_index => {         
        return Object.keys(all_diagonals[admin_diagonal_index]).map(diagonal_index => {          
          return all_diagonals[admin_diagonal_index][diagonal_index];
        });
      });
      setCompletedDiagonals(completed_diagonals);	    
    });     
    
  }

  useEffect(() => { 
    let mounted = true;
    if (mounted) {      
      getCompletedDiagonals();
    }         
    return () => {
      // actions to be performed when component unmounts
      mounted = false;
    }
  },[filterStartDate,filterEndDate,selectionType]);

  const handleFilter = (e) => {
    setSelectionType(e.target.value)
  }

  return (
    <React.Fragment>
      <div className="page-content">        
        <Container fluid>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Completed Diagonals" />
          <Row>
            <Col lg="12">
              <Card className="border">
                <CardBody>
                <h5><b>Filter By</b></h5>
                  <Row>
                    {/* <Col xxl={1} lg={6} className="d-flex align-items-center">
                    </Col> */}
                    <Col xxl={2} lg={6} className="mt-2">
                      <Label htmlFor="Created">Created date</Label>
                      <Flatpickr className="form-control d-block"
                        placeholder="Created Date"
                        options={{
                          mode: "range",
                          dateFormat: "d/m/Y",
                          defaultDate: [filterStartDate, filterEndDate]
                        }}
                        onChange={handleCreatedDateChange}
                      />
                    </Col>
                     <Col xxl={2} lg={6} className="mt-2">
                      <Label htmlFor="Created">Region</Label>
                      <select className="form-select" id="selection_type"  onChange={(e)=>handleFilter(e)} value={selectionType}>
                          <option value='2'> All Users </option>
                          <option value='1'> Indian </option>
                          <option value='0'> Global </option>
                      </select>
                      </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-3">Orders</h4>
                  <div className="table-responsive">
                    {/*********/}
                    {filterStartDate && filterEndDate ? <p className="fw-bold">Showing data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)}</p>:''}
                    <Table className="table table-bordered table align-middle table-nowrap table-check caption-top-dark">
                        <thead >
                            <tr>
                                <th>Bot</th>
                                <th>User</th>
                                <th width="10%">Order Expiry</th>
                                <th>Side</th>
                                <th>Symbol</th>
                                <th>Sold/Bought Price</th>
                                <th>Filled/Qty</th>
                                <th>Order ID</th>
                                <th>Status</th>                                
                                <th className="text-decoration-underline" >
                                    <Tooltip
                                        placement="top"
                                        isOpen={ttop1}
                                        target="TooltipTop"
                                        toggle={() => {
                                            setttop1(!ttop1);
                                        }}
                                    >
                                        Booked PNL = sell price - buy price
                                    </Tooltip>
                                    <span id="TooltipTop">PNL</span>
                                </th>                                
                            </tr>
                        </thead>
                        {completedDiagonals.length > 0 ? (
                            completedDiagonals.map((users, index) => {
                                return (
                                    <tbody key={index}>
                                    {users.map((row, row_index) => {
                                      return (
                                        row.map((order, index, arr) => {
                                            
                                            return (
                                                <tr className="trow-hover" key={index}>
                                                    {row_index===0 && index === 0 ?
                                                        <td rowSpan={(users.length * row.length)}>
                                                          <div className="p-2 border">
                                                            <b className="text-primary font-size-15">{formatUTCDate(order.order_settings.short_expiry)}</b>
                                                            <div className="text-primary">
                                                              Expiry:
                                                              <span className="expiry-countdown font-size-11" >
                                                                <Countdown date={order.order_settings.market_close_time} renderer={counterRenderer} />
                                                              </span>
                                                            </div>
                                                            {order.region ? <div className="font-size-12">Region: India</div> : <div className="font-size-12">Region: Global</div>}
                                                            {order.order_settings.calendar_type ? <div className="font-size-12 mt-1">{getCalendarTypeName(order)}</div> : ''}
                                                            {order.order_settings.expiry_position_to_begin ? <div className="font-size-12">Start from: {getFirstPositionName(order)}</div> : ''}
                                                            {order.order_settings.take_profit_percentage ? <div className="font-size-12">Take Profit: {order.order_settings.take_profit_percentage}%</div> : ''}                                                            
                                                            <div className="font-size-12 mt-2"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(order.created_at)}
                                                            </div>
                                                          </div>
                                                        </td>
                                                    : ''}
                                                    {index === 0 ?
                                                    <td className="td-vertical-align" rowSpan={row.length}>
                                                        {order.profile ?
                                                        <p className="font-size-11 text-captialize">{order.profile.first_name} {order.profile.last_name}</p> : <p></p>}
                                                    </td>
                                                    : ''}
                                                    <td>
                                                        <div className="font-size-12">{formatUTCDate(order.expiry_date)}</div>
                                                        <div className="">
                                                          <span className="expiry-countdown font-size-11" >
                                                            <Countdown date={order.expiry_date} renderer={counterRenderer} />
                                                          </span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Badge
                                                            color={order.direction === "buy" ? "success" : "danger"}
                                                            style={{ "textTransform": "uppercase" }}
                                                        >
                                                            {order.direction}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                            {order.symbol}
                                                        </Badge>
                                                        {order.market_action_trigger ?
                                                          <div>
                                                            <span className="font-size-10 ">{getMarketActionTriggerName(order)} </span>
                                                          </div>
                                                        : ""
                                                        }                                                            
                                                    </td>

                                                    <td><h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{order.average_fill_price ? Number(order.average_fill_price).toFixed(2) : order.price ? Number(order.price).toFixed(2) : "..."}</h6></td>                                                        
                                                    <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.quantity).toFixed(0)}</td>
                                                    <td>{order.order_id ? order.order_id : "Nil"}</td>
                                                    <td> <span dangerouslySetInnerHTML={{ __html: getOrderStatusNameStyle(order) }}></span></td>
                                                    
                                                    <td title={order.booked_pnl ? order.booked_pnl : order.realized_pnl ? order.realized_pnl : ''}><small>{order.booked_pnl ? parseFloat(order.booked_pnl.toFixed(2)) + `${order.region == 1 ?' USD':" USDT"}` : order.realized_pnl ? parseFloat(order.realized_pnl) + `${order.region == 1 ?' USD':" USDT"}` : '...'}</small></td>                                                    
                                                </tr>
                                            )
                                        })

                                      )})}
                                    </tbody>
                                )
                            })
                        ) : (
                            <tbody>
                                <tr>
                                    <td colSpan={13} style={{ textAlign: "center" }}>
                                        No orders found
                                    </td>
                                </tr>
                            </tbody>
                        )}
                    </Table>
                    {/*********/}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CompletedDiagonals