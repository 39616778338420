import React, { useCallback, useEffect, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Table,
  Tooltip,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  CardText,
  Modal,
  Alert,
  UncontrolledTooltip
} from "reactstrap"
// import ToolTipData from '../../Settings/Settings.json'
const { default: axios } = require("axios");
import { MinimumTakeProfit, TaillingTakeProfit ,MidPrize} from "../../../helpers/help-msg";
import classnames from "classnames"
import { Link } from "react-router-dom"
import parse from 'html-react-parser';
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import Countdown from "react-countdown"
import { getStraddleExpiryDates, getStraddleStrikes, getUsersList, startBot } from "helpers/straddle_helper"
import { capitalizeFirst, counterRenderer, formatUTCDate } from "services/common.service"
import Select from "react-select"
import api from 'helpers/api_helper';
import Switch from "react-switch";
import { useForm } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import DatePicker from "react-flatpickr";
const CreateStraddle = () => {
  firebase.auth();
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors},
  } = useForm();

  //Current User  
  //tooltip
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
  const [ToolTipData, setToolTipData] = useState([]);
  useEffect(() => {
    try {
      const gettoottipData = async () => {

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
        // console.log(response)
        setToolTipData(JSON.parse(JSON.stringify(response.data)))

      }

      gettoottipData()
    } catch (error) {
      console.log(error)
    }

  }, [apiUrl])
  //tooltip
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let exchangeType = currentUser.profile.exchangeType && currentUser.profile.exchangeType=="Global"?0:1;
  let uid = currentUser.uid;

  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }

  //meta title
  //document.title = "Create Straddle";
  // const firestoreDb = firebase.firestore();
  const [pageLoader, setPageLoader] = useState(false);
  const [formLoader, setformLoader] = useState(false);
  const [activeTab, setactiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [visibleTailling, setVisibleTailling] = useState(false);
  const [visibleMin, setVisibleMin] = useState(false);
  const [MintakeProfitMsg, setMintakeProfitMsg] = useState();
  const [TaillingTakeProfitMsg, setTaillingTakeProfitMsg] = useState();
  const [isStraddlebotEnabled, setIsStraddlebotEnabled] = useState(false);
  const [straddleExpiryDates, setStraddleExpiryDates] = useState([]);
  const [SlTriggerStraddles, setSlTriggerStraddles] = useState(true);
  const [startBotParams, setStartBotParams] = useState({});
  const [TraillingStraddles, setTraillingStraddles] = useState(false);
  // const firestoreDbUser = firestoreDb.collection("users").doc(uid);
  const [straddleswait_time_reentry, setstraddleswait_time_reentry] = useState(0);
  const [straddlereentry_enabled, setstraddlereentry_enabled] = useState(false);
  const [time_to_close_orders_before_expiry_straddles, settime_to_close_orders_before_expiry_straddles] = useState(0);
  const [exchange_type, setExchange_type] = useState(exchangeType)
  const [StraddleMidPrice, setStraddleMidPrice] = useState("");
  const [visibleMidPrize, setVisibleMidPrize] = useState(false);
  const [MidPrizeMsg, setMidPrizeMsg] = useState();
  const [straddleAdvancedSettings, setStraddleAdvancedSettings] = useState(false)

  //Get Expiry dates list and set in options
  const getExpiryDates = async () => {
    try {
      const bodyParams = {
        currency: 'BTC'
      };
      await getStraddleExpiryDates(bodyParams).then((res) => {
        setStraddleExpiryDates(res.data.response.expiry_dates);
      }).catch(e => {
        throw e
      })
    } catch (e) {
      handleError(e);
    }
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  //Get Site Settings
  const getSiteSettings = async () => {
    setPageLoader(true);
    await api({ contentType: true, auth: true }).get('/delta/get-site-settings').then(res => {
      if (res.data.response && res.data.response.settings) {
        setIsStraddlebotEnabled(res.data.response.settings.enable_straddle);
      }
    }).catch(err => {
      throw err;
    })
    setPageLoader(false);
    // var site_settings = firestoreDb.collection("site-settings").doc('data');
    // await site_settings.get().then((res) => {
    //   if (res.exists) {
    //     const site_setting = res.data();
    //     setIsStraddlebotEnabled(site_setting.enable_straddle);
    //   }
    //   setPageLoader(false);
    // });
  }

  const emptyUsers = (e) => {
    setSelectionType('all-users')
    setSelectedUsers([]);
    let value = e.target.value=="Global"? 0:1;
    getAllUsersList(value);
    setExchange_type(value);
    setPassedSteps([1]);
}

const handleTabClick = (activeTab, formLoader) => {
  if (formLoader) return;

  if (activeTab === 3) {
    trigger().then((isValid) => {
      if (!isValid && errors && Object.keys(errors).length > 0) {
        return;
      }

      window.scrollTo(0, document.body.scrollHeight);
      toggleTab(activeTab + 1);
    });

    return;
  }

  toggleTab(activeTab + 1);
};

  const getStraddleOrderSettings = async () => {

    await api({ contentType: true, auth: true }).get('/delta/get-site-settings?type=straddles-settings').then(res => {
      if (res.data.response && res.data.response.settings) {
        const order_settings = res.data.response.settings;
        setBotSettings({
          ...botSettings,
          'stop_loss_percentage': (order_settings.stop_loss_percentage) ? order_settings.stop_loss_percentage : botSettings.stop_loss_percentage,
          'trigger_percentage': (order_settings.trigger_percentage) ? order_settings.trigger_percentage : botSettings.trigger_percentage,
          'stop_loss_index_percentage': (order_settings.stop_loss_index_percentage) ? order_settings.stop_loss_index_percentage : botSettings.stop_loss_index_percentage,
          'minimum_take_profit_percentage': (order_settings.minimum_take_profit_percentage) ? order_settings.minimum_take_profit_percentage : botSettings.minimum_take_profit_percentage,
          'trailing_take_profit_percentage': (order_settings.trailing_take_profit_percentage) ? order_settings.trailing_take_profit_percentage : botSettings.trailing_take_profit_percentage,
          'combined_sl': (order_settings.combined_sl) ? order_settings.combined_sl : botSettings.combined_sl,
          'combined_sl_percentage':(order_settings.combined_sl_percentage) ? order_settings.combined_sl_percentage : botSettings.combined_sl_percentage,
          'combined_trailing_take_profit_percentage':(order_settings.combined_trailing_take_profit_percentage) ? order_settings.combined_trailing_take_profit_percentage : botSettings.combined_trailing_take_profit_percentage,
          'combined_trailing_stop_price_percentage':(order_settings.combined_trailing_stop_price_percentage) ? order_settings.combined_trailing_stop_price_percentage : botSettings.combined_trailing_stop_price_percentage,
          'Sl_trigger_take_profit': (order_settings.Sl_trigger_take_profit) ? order_settings.Sl_trigger_take_profit : botSettings.Sl_trigger_take_profit
        });
        setStraddleMidPrice(order_settings.mid_price_percentage ? order_settings.mid_price_percentage : "0")
        setSlTriggerStraddles(order_settings && order_settings.Sl_trigger_take_profit !== undefined ? order_settings.Sl_trigger_take_profit : true)
        setTraillingStraddles(order_settings && order_settings.combined_sl !== undefined ? (order_settings.combined_sl == 1 ? true : false) : false)
        setstraddleswait_time_reentry(order_settings.wait_time_reentry ? order_settings.wait_time_reentry : "0");
        setstraddlereentry_enabled(order_settings.reentry_enabled ? (order_settings.reentry_enabled == 1 ? true : false) : false);
        settime_to_close_orders_before_expiry_straddles(order_settings.time_to_close_orders_before_expiry ? order_settings.time_to_close_orders_before_expiry : "0");

      }
    }).catch(err => {
      throw err;
    })

    // var docRef = firestoreDb.collection("site-settings").doc('straddles-settings');    
    // await docRef.get().then((doc) => {
    //     if (doc.exists) {
    //       const order_settings = doc.data();
    //       setBotSettings({
    //         ...botSettings,
    //         'stop_loss_percentage': (order_settings.stop_loss_percentage) ? order_settings.stop_loss_percentage : botSettings.stop_loss_percentage,
    //         'minimum_take_profit_percentage': (order_settings.minimum_take_profit_percentage) ? order_settings.minimum_take_profit_percentage : botSettings.minimum_take_profit_percentage,
    //         'trailing_take_profit_percentage': (order_settings.trailing_take_profit_percentage) ? order_settings.trailing_take_profit_percentage : botSettings.trailing_take_profit_percentage
    //       });
    //     }
    // })
  }

  //Get Users List
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectionType, setSelectionType] = useState('all-users');
  const [failedUserDetails, setFailedUserDetails] = useState([]);
  const getAllUsersList = async (exchangeType) => {
    try {
      const res = await getUsersList({"exchangeType":exchangeType});
      if (res.data.response.users_list) {
        const usersListResponse = res.data.response.users_list;
        const result = usersListResponse.map(item => {
          return {
            label: item.display_text ? capitalizeFirst(item.display_text) : "",
            value: item.uid
          }
        })
        setUsersList(result);
        setSelectedUsers(result);
      }

    } catch (e) {
      setUsersList([]);
      setSelectedUsers([]);
      handleError(e);
    }
  }

  const handleSelectionType = (e) => {
    if (e.target.value === 'all-users') {
      setSelectedUsers(usersList);
    } else {
      setSelectedUsers([])
    }
    setSelectionType(e.target.value);
  }

  const handleSelect = (selectedList, selectedItem) => {
    setSelectedUsers(selectedList);
  }
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return hours + ":" + minutes;
  }
  const handleDH = (e, strategy = '') => {
    var time = e[0]
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var H_M = toSeconds(hours, minutes, 0);
    if (strategy == 'straddleswait_time_reentry') {
      setstraddleswait_time_reentry(H_M)
    } else if (strategy == 'time_to_close_orders_before_expiry_straddles') {
      settime_to_close_orders_before_expiry_straddles(H_M)
    }
  }
  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  //Choose Expiry & Get Strikes   
  const [straddleStrikes, setStraddleStrikes] = useState('');

  const processStep1 = async () => {
    try {
      setformLoader(true);
      setStraddleStrikes("");

       if(usersList.length == 0){
            toast.error('No users available');
            setformLoader(false);
            return false;
        }

      if (!selectedUsers || !selectedUsers.length) {
        toast.error('Please select users', { toastId: 'straddle_step1_select_user_error' });
        setformLoader(false);
        return false;
      }

      //Selected users
      let user_selection_type = selectionType;
      let selected_user_uids;
      if (user_selection_type === 'selected-users') {
        let selected_user_uids_arr = selectedUsers.map(selected_user => selected_user.value);
        if (selected_user_uids_arr.length) {
          selected_user_uids = selected_user_uids_arr.join(",")
        }
      }

      const expiry_date = document.getElementById('expiry_date').value;
      let bodyParams; 
               
      if(exchangeType == exchange_type){
         bodyParams = {
            expiry_date: expiry_date
          };
      }else{
       bodyParams = {
              exchangeType : exchange_type,
              u_id:usersList[0].value,
              expiry_date: expiry_date
          };
      }
     

      await getStraddleStrikes(bodyParams).then(res => {
        setformLoader(false);
        setStartBotParams({
          ...startBotParams, 'user_selection_type': user_selection_type,
          'selected_user_uids': selected_user_uids, 'expiry_date': expiry_date
        });
        setStraddleStrikes(res.data.response.strikes);
        UpdateDbSetingsinRow(res.data.response.strikes)
        updateRowValue('Approximate Strike Values', 'calls', ((res.data.response.strikes.calls.best_bid+res.data.response.strikes.calls.best_ask)/2).toFixed(2));
        updateRowValue('Approximate Strike Values', 'puts', ((res.data.response.strikes.puts.best_bid+res.data.response.strikes.puts.best_ask)/2).toFixed(2));
      }).catch(err => {
        throw err
      });

      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Confirm Strike
  const processStep2 = async () => {
    try {
      setformLoader(true);

      const required_margin = Number(straddleStrikes.calls.order_margin) + Number(straddleStrikes.puts.order_margin);
      setStartBotParams({
        ...startBotParams,
        'calls': {
          symbol: straddleStrikes.calls.symbol,
          product_id: straddleStrikes.calls.product_id,
          best_ask: straddleStrikes.calls.best_ask,
          best_bid: straddleStrikes.calls.best_bid,
        },
        'puts': {
          symbol: straddleStrikes.puts.symbol,
          product_id: straddleStrikes.puts.product_id,
          best_ask: straddleStrikes.puts.best_ask,
          best_bid: straddleStrikes.puts.best_bid,
        },
        'strike_price': straddleStrikes.calls.strike_price,
        'required_margin': required_margin,
        'current_index_price': straddleStrikes.index_price
      });
      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Bot setup / settings
  const [botSettings, setBotSettings] = useState({
    stop_loss_percentage: 30,
    stop_loss_index_percentage: 0,
    minimum_take_profit_percentage: 10,
    trailing_take_profit_percentage: 10,
    trigger_percentage: 0,
    quantity: 1,
    combined_sl: false,
    reentry_enabled: 0,
    wait_time_reentry: "0",
    time_to_close_orders_before_expiry: "0",
    combined_sl_percentage:0,
    combined_trailing_take_profit_percentage:0,
    combined_trailing_stop_price_percentage:0,
  });

  const validation = {
    required: "This field is required",
    validate: (value) => {
      return value > 0 || "value > 0";
    },
  }
  const [rows, setRows] = useState([
    {name:"",botSettingsvalue:'',orgSettingsName:'',tooltip:'',target:'ASV', label: 'Approximate Strike Values', settings: null, calls: 1133, puts: 1123,formula: <><p><b>Calls</b> = ((Calls Best Bid + Calls Best Ask)/2)</p><p> <b>Puts</b> = (Puts Best Bid + Puts Best Ask)/2</p></> },
    {name:"make_limit_orders_at",botSettingsvalue:'',orgSettingsName:'Make Limit Orders x% from Mid/MarkPrice',tooltip:'visibleMidPrize',target:'ONCLICK', label: 'Make Limit Orders at', settings: StraddleMidPrice, calls: 0, puts: 0,max: 1000,step:0.5,formula: <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2 </p><p> <b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p> <p><b>Calls x%</b> = (Calls Best Ask - Calls Mid Price) * (Setting Values/100)</p><p><b> Puts x%</b> = (Puts Best Ask - Puts Mid Price) * (Setting Values/100)</p> <p><b>Calls</b> = Calls Mid Price + Calls x%</p><p> <b>Puts</b> = Puts Mid Price + Puts x%</p></> },
    {name:"trigger_percentage",botSettingsvalue:'trigger_percentage',orgSettingsName:'Stop Loss Activate Percentage',tooltip:'trigger',target:'stoploss', label: 'Activate SL at', settings:  botSettings.trigger_percentage, calls: 0, puts: 0,min:0,max:100,step:0.5,formula:  <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2 </p><p> <b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p><p><b>Calls</b> = (Calls Mid Price + (Calls Mid Price * (Setting Values/100)))</p><p> <b>Puts</b> = (Puts Mid Price + (Puts Mid Price * (Setting Values/100)))</p></>,validation:validation},
    {name:"stop_loss_percentage",botSettingsvalue:'stop_loss_percentage',orgSettingsName:'Market Stop Loss Percentage',tooltip:'stop_loss',target:'stoploss1', label: 'Market SL at', settings: botSettings.stop_loss_percentage, calls: 0, puts: 0,min:0,max:100,step:0.5,formula:<><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2 </p><p><b> Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p><p><b>Calls</b> = ((Calls Sell Price * (Setting Values/100)) + (Calls.Sell Price))</p><p><b>Puts</b> = ((Puts Sell Price * (Setting Values/100)) + (Puts.Sell Price))</p></>,validation:validation },
    {name:"stop_loss_index_percentage",botSettingsvalue:'stop_loss_index_percentage',orgSettingsName:'Index Price Range % for Stop Loss',tooltip:'index_price_range',target:'stoploss2', label: 'Activate SL if index changes to', settings: botSettings.stop_loss_index_percentage, calls: 0, puts: 0,min:0,max:100,step:0.5,formula: <><p><b>Calls</b> = ((Index Price)-((Index Price) * (Setting Values/100)))</p><p> <b>Puts</b> = ((Index Price)+((Index Price) * (Setting Values/100))</p></> },
    {name:"minimum_take_profit_percentage",botSettingsvalue:'minimum_take_profit_percentage',orgSettingsName:'Trailing Take Profit Percentage',tooltip:'visibleMin',target:'ONCLICK', label: 'Activate Trailing at', settings: botSettings.minimum_take_profit_percentage, calls: 0, puts: 0,min:0,max:100,step:0.5,formula: <><p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p> <b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></> ,validation:validation},
    {name:"trailing_take_profit_percentage",botSettingsvalue:'trailing_take_profit_percentage',orgSettingsName:'Trailing Stop Price Percentage',tooltip:'visibleTailling',target:'ONCLICK', label: 'Trail Exit at', settings:botSettings.trailing_take_profit_percentage, calls: 0, puts: 0,min:0,max: 100,step:0.5,formula: <> <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p> <b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></>,validation:validation },
    {name:"Sl_trigger_take_profit",botSettingsvalue:'Sl_trigger_take_profit',orgSettingsName:'Activate trailing take profit when stop loss triggered on one leg',tooltip:'stop_loss_trigger_market_take_profit',target:'SSLTMTP', label: 'Activate Trailing', 'ATTPWSLTOOL': '(radio buttons)', calls: '', puts: 'After SL hits one of the position',formula: <> <p><b>After SL hits one of the positions</b>– Activates trailing and take profit after stop loss hit on one leg.</p></> },
    {name:"combined_sl_percentage",botSettingsvalue:'combined_sl_percentage',orgSettingsName:'Combined Stop Loss %',tooltip:'stop_loss_combined',target:'straddles-combinedsl-trigger', label: 'Activate Combined SL at', settings: botSettings.combined_sl_percentage, calls: '', puts: 0,min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) + (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
    {name:"combined_trailing_take_profit_percentage",botSettingsvalue:'combined_trailing_take_profit_percentage',orgSettingsName:'Combined Trailing Take Profit %',tooltip:'combined_trailing_profit_percentage',target:'straddles-combined-trail-pro-trigger', label: 'Activate Combined Trailing at', settings: botSettings.combined_trailing_take_profit_percentage, calls: '', puts: 0,min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
    {name:"combined_trailing_stop_price_percentage",botSettingsvalue:'combined_trailing_stop_price_percentage',orgSettingsName:'Combined Trailing Stop Price %',tooltip:'combined_trailing_stop_price_percentage',target:'straddles-combined-trail-stop-trigger', label: 'Combined Trail Exit at', settings: botSettings.combined_trailing_stop_price_percentage, calls: '', puts: 0,min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
  ]);
  
  const handleRangeInput = (e, setting_name, isInteger = true) => {
    if ((e.target.value === null || e.target.value === undefined || e.target.value === '')) {
      setBotSettings({ ...botSettings, [setting_name]: '' });
      return false;
    }

    let newvalue = Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
    setBotSettings({ ...botSettings, [setting_name]: (isInteger) ? parseInt(newvalue) : newvalue });
  }

  const updateRowValue = (label, key, newValue) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.label === label
          ? { ...row, [key]: newValue } // Update the specific value
          : row // Keep other rows unchanged
      )
    );
  };

  const UpdateDbSetingsinRow = (straddleStrikes) => {
    const updatedRows = rows.map((row, i) => {
      
      const updatedRow = { ...row};
      const puts_mid_value = (straddleStrikes.puts.best_bid + straddleStrikes.puts.best_ask)/2
      const calls_mid_value = (straddleStrikes.calls.best_bid + straddleStrikes.calls.best_ask)/2
      const common_formula = <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2</p> 
      <p><b>Calls Mid Price</b> = ({straddleStrikes.calls.best_bid} + {straddleStrikes.calls.best_ask})/2</p>
      <p><b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p> 
      <p><b>Puts Mid Price</b> = ({straddleStrikes.puts.best_bid} + {straddleStrikes.puts.best_ask})/2</p></>

      // updatedRow.formula = constructFormula(row,straddleStrikes,puts_mid_value,calls_mid_value,botSettings[row.botSettingsvalue])
      if(row.botSettingsvalue === '' && row.orgSettingsName ===''){
        updatedRow.formula = <>
            <p><b>Calls</b> = (Calls Best Bid + Calls Best Ask)/2</p> 
            <p><b>Calls</b> = ({straddleStrikes.calls.best_bid} + {straddleStrikes.calls.best_ask})/2</p>
            <p><b>Puts</b> = (Puts Best Bid + Puts Best Ask)/2</p> 
            <p><b>Puts</b> = ({straddleStrikes.puts.best_bid} + {straddleStrikes.puts.best_ask})/2</p>
            </>
      }
      if (row.botSettingsvalue === 'stop_loss_percentage'){
        updatedRow.settings = botSettings[row.botSettingsvalue]
        updatedRow.calls = ((straddleStrikes.calls.sell_price * (Number(botSettings[row.botSettingsvalue])/100)) + Number(straddleStrikes.calls.sell_price)).toFixed(2)
        updatedRow.puts = ((straddleStrikes.puts.sell_price * (Number(botSettings[row.botSettingsvalue])/100)) + Number(straddleStrikes.puts.sell_price)).toFixed(2)
        updatedRow.formula = <>
            {common_formula}
            <p><b>Calls</b> = (Calls Sell Price * (Setting Values/100)) + Calls Sell Price</p> 
            <p><b>Calls</b> = ({straddleStrikes.calls.sell_price} * ({botSettings[row.botSettingsvalue]}/100)) + {straddleStrikes.calls.sell_price}</p>
            <p><b>Puts</b> = (Puts Sell Price * (Setting Values/100)) + Puts Sell Price</p> 
            <p><b>Puts</b> = ({straddleStrikes.puts.sell_price} * ({botSettings[row.botSettingsvalue]}/100)) + {straddleStrikes.puts.sell_price}</p>
            </>
      } 
      if (row.botSettingsvalue === 'minimum_take_profit_percentage'){
        updatedRow.settings = botSettings[row.botSettingsvalue]
        updatedRow.calls = (Number(straddleStrikes.calls.sell_price)-(straddleStrikes.calls.sell_price * (Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.puts = (Number(straddleStrikes.puts.sell_price)-(straddleStrikes.puts.sell_price * (Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.formula = <>
            <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
            <p><b>Calls</b> = (({straddleStrikes.calls.sell_price}) -({straddleStrikes.calls.sell_price} * ({botSettings[row.botSettingsvalue]}/100)))</p>
            <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
            <p><b>Puts</b> = (({straddleStrikes.puts.sell_price}) -({straddleStrikes.puts.sell_price} * ({botSettings[row.botSettingsvalue]}/100)))</p>
            </>
      }
      if (row.botSettingsvalue === 'trailing_take_profit_percentage'){
        updatedRow.settings = botSettings[row.botSettingsvalue]
        updatedRow.calls =(Number(straddleStrikes.calls.sell_price)-(straddleStrikes.calls.sell_price * (Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.puts = (Number(straddleStrikes.puts.sell_price)-(straddleStrikes.puts.sell_price * (Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.formula = <>
            <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
            <p><b>Calls</b> = (({straddleStrikes.calls.sell_price}) -({straddleStrikes.calls.sell_price} * ({botSettings[row.botSettingsvalue]}/100)))</p>
            <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
            <p><b>Puts</b> = (({straddleStrikes.puts.sell_price}) -({straddleStrikes.puts.sell_price} * ({botSettings[row.botSettingsvalue]}/100)))</p>
            </>
      }
      if (row.botSettingsvalue === 'combined_sl_percentage'){
        updatedRow.settings = botSettings[row.botSettingsvalue]
        updatedRow.puts = ((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))+((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.formula = <>
            <p>(((Calls Sell Price) + (Puts Sell Price)) + (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
            <p>((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) + ((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) * ({botSettings[row.botSettingsvalue]}/100)))</p>
            </>
      }
      if (row.botSettingsvalue === 'combined_trailing_take_profit_percentage'){
        updatedRow.settings = botSettings[row.botSettingsvalue]
        updatedRow.puts = ((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))-((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.formula = <>
            <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
            <p>((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) - ((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) * ({botSettings[row.botSettingsvalue]}/100)))</p>
            </>
      }
      if (row.botSettingsvalue === 'combined_trailing_stop_price_percentage'){
        updatedRow.settings = botSettings[row.botSettingsvalue]
        updatedRow.puts = ((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))-((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.formula = <>
            <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
            <p>((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) - ((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) * ({botSettings[row.botSettingsvalue]}/100)))</p>
            </>
      }
      if (row.botSettingsvalue === 'stop_loss_index_percentage'){
        updatedRow.settings = botSettings[row.botSettingsvalue]
        updatedRow.calls = ((Number(straddleStrikes.index_price)-(Number(straddleStrikes.index_price)*(Number(botSettings[row.botSettingsvalue])/100)))).toFixed(2)
        updatedRow.puts = ((Number(straddleStrikes.index_price)+(Number(straddleStrikes.index_price)*(Number(botSettings[row.botSettingsvalue])/100)))).toFixed(2)
        updatedRow.formula = <>
       <p><b>Calls</b> = ((Index Price)-((Index Price) * (Setting Values/100)))</p>
       <p><b>Calls</b> = (({ straddleStrikes.index_price})-(({ straddleStrikes.index_price}) * ({ botSettings[row.botSettingsvalue]}/100)))</p>
       <p><b>Puts</b> = ((Index Price)+((Index Price) * (Setting Values/100)))</p>
       <p><b>Puts</b> = (({ straddleStrikes.index_price})+(({ straddleStrikes.index_price}) * ({ botSettings[row.botSettingsvalue]}/100)))</p>
       </>
      }
      if (row.botSettingsvalue === 'trigger_percentage'){
        updatedRow.settings = botSettings[row.botSettingsvalue]
        updatedRow.calls = Number(calls_mid_value+((calls_mid_value)*(Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.puts = Number(puts_mid_value+((puts_mid_value)*(Number(botSettings[row.botSettingsvalue])/100))).toFixed(2)
        updatedRow.formula = <>
            {common_formula}
            <p><b>Calls</b> = Calls Mid Price + (Calls Mid Price * (Setting Values/100))</p>
            <p><b>Calls</b> = {calls_mid_value} + ({calls_mid_value} * ({botSettings[row.botSettingsvalue]}/100))</p>
            <p><b>Puts</b> = Puts Mid Price + (Puts Mid Price * (Setting Values/100))</p>
            <p><b>Puts</b> = {puts_mid_value} + ({puts_mid_value} * ({botSettings[row.botSettingsvalue]}/100))</p>
            </>
      }
      if (row.tooltip === 'visibleMidPrize'){
        updatedRow.settings = StraddleMidPrice
        const puts_75_percent = (straddleStrikes.puts.best_ask-puts_mid_value)*(StraddleMidPrice/100)
        const calls_75_percent = (straddleStrikes.calls.best_ask-calls_mid_value)*(StraddleMidPrice/100)
        updatedRow.calls = Number(calls_mid_value+calls_75_percent).toFixed(2)
        updatedRow.puts = Number(puts_mid_value+puts_75_percent).toFixed(2)
        updatedRow.formula = <>
            {common_formula}
            <p><b>Calls x%</b> = (Calls Best Ask - Calls Mid Price) * (Setting Values/100)</p>
            <p><b>Calls x%</b> = ({straddleStrikes.calls.best_ask} - {calls_mid_value}) * ({StraddleMidPrice}/100)</p>
            <p><b>Puts x%</b> = (Puts Best Ask - Puts Mid Price) * (Setting Values/100)</p>
            <p><b>Puts x%</b> = ({straddleStrikes.puts.best_ask} - {puts_mid_value}) * ({StraddleMidPrice}/100)</p>
            <p><b>Calls</b> = Calls Mid Price + Calls x%</p>
            <p><b>Calls</b> = ({((straddleStrikes.calls.best_bid + straddleStrikes.calls.best_ask)/2).toFixed(2)}) + {((straddleStrikes.calls.best_ask - calls_mid_value) * (StraddleMidPrice/100)).toFixed(2)}</p>
            <p><b>Puts</b> = Puts Mid Price + Puts x%</p>
            <p><b>Puts</b> = ({((straddleStrikes.puts.best_bid + straddleStrikes.puts.best_ask)/2).toFixed(2)}) + {((straddleStrikes.puts.best_ask - puts_mid_value) * (StraddleMidPrice/100)).toFixed(2)}</p>
            </>
        // return 
      }
      console.log(row.name, updatedRow.settings);

      if(row.name){
        setValue(row.name, updatedRow.settings || 0, { shouldValidate: true })
      }
      
      return updatedRow;
    })
    setRows(updatedRows);
  }
  const handleInputChange = (e,index, tooltip,botSettingsvalue) => {
    const updatedRows = rows.map((row, i) => {
      
      if (i === index) {
        console.log(e.target.getAttribute('max'),tooltip)
        let newvalue = e.target.value === null || e.target.value === undefined || e.target.value === '' ? '' : Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
        const updatedRow = { ...row, settings: newvalue };
        const puts_mid_value = (straddleStrikes.puts.best_bid + straddleStrikes.puts.best_ask)/2
        const calls_mid_value = (straddleStrikes.calls.best_bid + straddleStrikes.calls.best_ask)/2
        const common_formula = <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2</p> 
            <p><b>Calls Mid Price</b> = ({straddleStrikes.calls.best_bid} + {straddleStrikes.calls.best_ask})/2</p>
            <p><b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p> 
            <p><b>Puts Mid Price</b> = ({straddleStrikes.puts.best_bid} + {straddleStrikes.puts.best_ask})/2</p></>
        if (tooltip === 'visibleMidPrize'){
          e.target.value <= 1000 ? setStraddleMidPrice(newvalue) : setStraddleMidPrice(1000)  
          e.target.value <= 1000 ? setStraddleMidPrice(newvalue) : setStraddleMidPrice(100) 
          
          const puts_75_percent = (straddleStrikes.puts.best_ask-puts_mid_value)*(newvalue/100)
          const calls_75_percent = (straddleStrikes.calls.best_ask-calls_mid_value)*(newvalue/100)
          console.log(calls_mid_value,puts_75_percent, calls_75_percent,(puts_mid_value+puts_75_percent).toFixed(2),(calls_mid_value+calls_75_percent).toFixed(2))
          updatedRow.calls = Number(calls_mid_value+calls_75_percent).toFixed(2)
          updatedRow.puts = Number(puts_mid_value+puts_75_percent).toFixed(2)
          updatedRow.formula = <>
            {common_formula}
            <p><b>Calls x%</b> = (Calls Best Ask - Calls Mid Price) * (Setting Values/100)</p>
            <p><b>Calls x%</b> = ({straddleStrikes.calls.best_ask} - {calls_mid_value}) * ({Number(newvalue||0)}/100)</p>
            <p><b>Puts x%</b> = (Puts Best Ask - Puts Mid Price) * (Setting Values/100)</p>
            <p><b>Puts x%</b> = ({straddleStrikes.puts.best_ask} - {puts_mid_value}) * ({Number(newvalue||0)}/100)</p>
            <p><b>Calls</b> = Calls Mid Price + Calls x%</p>
            <p><b>Calls</b> = ({((straddleStrikes.calls.best_bid + straddleStrikes.calls.best_ask)/2).toFixed(2)}) + {((straddleStrikes.calls.best_ask - calls_mid_value) * (newvalue/100)).toFixed(2)}</p>
            <p><b>Puts</b> = Puts Mid Price + Puts x%</p>
            <p><b>Puts</b> = ({((straddleStrikes.puts.best_bid + straddleStrikes.puts.best_ask)/2).toFixed(2)}) + {((straddleStrikes.puts.best_ask - puts_mid_value) * (newvalue/100)).toFixed(2)}</p>
            </>
          // return 
        }
        if (botSettingsvalue === 'stop_loss_percentage'){
          updatedRow.calls = ((straddleStrikes.calls.sell_price * (newvalue/100)) + Number(straddleStrikes.calls.sell_price)).toFixed(2)
          updatedRow.puts = ((straddleStrikes.puts.sell_price * (newvalue/100)) + Number(straddleStrikes.puts.sell_price)).toFixed(2)
          updatedRow.formula = <>
            {common_formula}
            <p><b>Calls</b> = (Calls Sell Price * (Setting Values/100)) + Calls Sell Price</p> 
            <p><b>Calls</b> = ({straddleStrikes.calls.sell_price} * ({Number(newvalue||0)}/100)) + {straddleStrikes.calls.sell_price}</p>
            <p><b>Puts</b> = (Puts Sell Price * (Setting Values/100)) + Puts Sell Price</p> 
            <p><b>Puts</b> = ({straddleStrikes.puts.sell_price} * ({Number(newvalue||0)}/100)) + {straddleStrikes.puts.sell_price}</p>
            </>
        }
        if (botSettingsvalue === 'minimum_take_profit_percentage'){
          updatedRow.calls = (Number(straddleStrikes.calls.sell_price)-(straddleStrikes.calls.sell_price * (newvalue/100))).toFixed(2)
          updatedRow.puts = (Number(straddleStrikes.puts.sell_price)-(straddleStrikes.puts.sell_price * (newvalue/100))).toFixed(2)
          updatedRow.formula = <>
            <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
            <p><b>Calls</b> = (({straddleStrikes.calls.sell_price}) -({straddleStrikes.calls.sell_price} * ({Number(newvalue||0)}/100)))</p>
            <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
            <p><b>Puts</b> = (({straddleStrikes.puts.sell_price}) -({straddleStrikes.puts.sell_price} * ({Number(newvalue||0)}/100)))</p>
            </>
        }
        if (botSettingsvalue === 'trailing_take_profit_percentage'){
          updatedRow.calls =(Number(straddleStrikes.calls.sell_price)-(straddleStrikes.calls.sell_price * (newvalue/100))).toFixed(2)
          updatedRow.puts = (Number(straddleStrikes.puts.sell_price)-(straddleStrikes.puts.sell_price * (newvalue/100))).toFixed(2)
          updatedRow.formula = <>
            <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p>
            <p><b>Calls</b> = (({straddleStrikes.calls.sell_price}) -({straddleStrikes.calls.sell_price} * ({Number(newvalue||0)}/100)))</p>
            <p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p>
            <p><b>Puts</b> = (({straddleStrikes.puts.sell_price}) -({straddleStrikes.puts.sell_price} * ({Number(newvalue||0)}/100)))</p>
            </>
        }
        if (botSettingsvalue === 'combined_sl_percentage'){
          updatedRow.puts = ((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))+((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (newvalue/100))).toFixed(2)
          updatedRow.formula = <>
            <p>(((Calls Sell Price) + (Puts Sell Price)) + (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
            <p>((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) + ((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) * ({Number(newvalue||0)}/100)))</p>
            </>
        }
        if (botSettingsvalue === 'combined_trailing_take_profit_percentage'){
          updatedRow.puts = ((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))-((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (newvalue/100))).toFixed(2)
          updatedRow.formula = <>
            <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
            <p>((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) - ((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) * ({Number(newvalue||0)}/100)))</p>
            </>
        }
        if (botSettingsvalue === 'combined_trailing_stop_price_percentage'){
          updatedRow.puts = ((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price))-((Number(straddleStrikes.calls.sell_price)+Number(straddleStrikes.puts.sell_price)) * (newvalue/100))).toFixed(2)
          updatedRow.formula = <>
            <p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p>
            <p>((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) - ((({straddleStrikes.calls.sell_price}) + ({straddleStrikes.puts.sell_price})) * ({Number(newvalue||0)}/100)))</p>
            </>
        }
        if (botSettingsvalue === 'stop_loss_index_percentage'){
          updatedRow.calls = ((Number(straddleStrikes.index_price)-(Number(straddleStrikes.index_price)*(newvalue/100)))).toFixed(2)
          updatedRow.puts = ((Number(straddleStrikes.index_price)+(Number(straddleStrikes.index_price)*(newvalue/100)))).toFixed(2)
          updatedRow.formula = <>
            <p><b>Calls</b> = ((Index Price)-((Index Price) * (Setting Values/100)))</p>
            <p><b>Calls</b> = (({ straddleStrikes.index_price})-(({ straddleStrikes.index_price}) * ({ Number(newvalue||0)}/100)))</p>
            <p><b>Puts</b> = ((Index Price)+((Index Price) * (Setting Values/100)))</p>
            <p><b>Puts</b> = (({ straddleStrikes.index_price})+(({ straddleStrikes.index_price}) * ({ Number(newvalue||0)}/100)))</p>
            </>
        }
        if (botSettingsvalue === 'trigger_percentage'){
         updatedRow.calls = Number(calls_mid_value+((calls_mid_value)*(newvalue/100))).toFixed(2)
         updatedRow.puts = Number(puts_mid_value+((puts_mid_value)*(newvalue/100))).toFixed(2)
         updatedRow.formula = <>
            {common_formula}
            <p><b>Calls</b> = Calls Mid Price + (Calls Mid Price * (Setting Values/100))</p>
            <p><b>Calls</b> = {calls_mid_value} + ({calls_mid_value} * ({Number(newvalue||0)}/100))</p>
            <p><b>Puts</b> = Puts Mid Price + (Puts Mid Price * (Setting Values/100))</p>
            <p><b>Puts</b> = {puts_mid_value} + ({puts_mid_value} * ({Number(newvalue||0)}/100))</p>
            </>
        }
        console.log(newvalue,' ======= ',' ++ ', botSettingsvalue)
        if (tooltip !== 'visibleMidPrize'){
          setBotSettings({ ...botSettings, [botSettingsvalue]:  newvalue});
        }
        
        console.log(updatedRow,' updatedRow ')
        setValue(row.name, e.target.value, { shouldValidate: true })
        trigger(row.name)
        return updatedRow;
      }
      return row;
    });
    setRows(updatedRows);
  };

  const processStep3 = async () => {
    try {
      setformLoader(true);
      let stop_loss_percentage = Number(botSettings.stop_loss_percentage);
      let minimum_take_profit_percentage = Number(botSettings.minimum_take_profit_percentage);
      let stop_loss_index_percentage = Number(botSettings.stop_loss_index_percentage);
      let trailing_take_profit_percentage = Number(botSettings.trailing_take_profit_percentage);
      let trigger_percentage = Number(botSettings.trigger_percentage);
      // if (!stop_loss_percentage) {
      //   toast.error('Please select Market Stop Loss Percentage', { toastId: 'straddle_step3_1' });
      //   setformLoader(false);
      //   return false;
      // }
      // if (trigger_percentage >= stop_loss_percentage) {
      //   toast.error("SL % must be more than Stop Loss Activate Percentage");
      //   setformLoader(false);
      //   return false;
      // }

      // if (!minimum_take_profit_percentage) {
      //   toast.error('Please select Trailing Take Profit Percentage', { toastId: 'straddle_step3_2' });
      //   setformLoader(false);
      //   return false;
      // }

      // if (!trailing_take_profit_percentage) {
      //   toast.error('Please select Trailing Stop Price Percentage', { toastId: 'straddle_step3_3' });
      //   setformLoader(false);
      //   return false;
      // }
      let botparams;
      setStartBotParams({ ...botSettings, ...startBotParams })
      botparams = { ...botSettings, ...startBotParams }
      botparams.stop_loss_percentage = stop_loss_percentage;
      botparams.stop_loss_index_percentage = stop_loss_index_percentage;
      botparams.minimum_take_profit_percentage = minimum_take_profit_percentage;
      botparams.trailing_take_profit_percentage = trailing_take_profit_percentage;
      botparams.combined_sl = botSettings.combined_sl;
      botparams.trigger_percentage = trigger_percentage;
      botparams.Sl_trigger_take_profit = SlTriggerStraddles;
      botparams.combined_trailing_profit = TraillingStraddles == true ? 1 : 0;
      botparams.wait_time_reentry = straddleswait_time_reentry;
      botparams.time_to_close_orders_before_expiry = time_to_close_orders_before_expiry_straddles;
      botparams.reentry_enabled = straddlereentry_enabled === true ? 1 : 0;
      botparams.exchangeType = exchange_type;
      botparams.mid_price_percentage = StraddleMidPrice ? StraddleMidPrice : "0";
      
      //Calls sell Order

      await startBot(botparams).then(async (res) => {
        setformLoader(false);
        toast.success(res.data.message);
      }).catch(err => {
        throw err;
      });

      setformLoader(false);
      return true;
    } catch (err) {
      setformLoader(false);
      handleError(err);
      return false;
    }
  }

  //Handle Form Wizard
  const toggleTab = async (tab) => {
    if (!isStraddlebotEnabled) {
      toast.error('Straddle bot creation disabled by admin', { toastId: 'straddle_bot_disabled' });
      return false;
    }

    let modifiedSteps = passedSteps;
    if (activeTab !== tab) {
      if (tab > activeTab) {
        let is_processed = false;
        switch (activeTab) {
          case 1:
            //Choose Expiry
            is_processed = await processStep1();
            break;
          case 2:
            //Confirm Strikes
            is_processed = await processStep2();
            break;
          case 3:
            //Bot Setup and start bot
            is_processed = await processStep3();
            break;
        }

        const index = modifiedSteps.indexOf(tab);
        if (index > -1) {
          modifiedSteps = modifiedSteps.slice(0, index);
        }

        if (is_processed) {
          modifiedSteps.push(tab);
          if (tab >= 1 && tab <= 4) {
            setactiveTab(tab)
            setPassedSteps(modifiedSteps)
            window.scrollTo(0, 0);
          }
        } else {
          setPassedSteps(modifiedSteps)
        }
      } else {
        if (tab >= 1 && tab <= 4) {
          setactiveTab(tab)
          window.scrollTo(0, 0);
        }
      }
    }
  }

  useEffect(() => {
    setTaillingTakeProfitMsg(TaillingTakeProfit());
    setMintakeProfitMsg(MinimumTakeProfit())
    setMidPrizeMsg(MidPrize())
    getSiteSettings();
    getExpiryDates();
    getStraddleOrderSettings();
    getAllUsersList(exchangeType);
  }, []);

  const handleModel = (e, row) =>{
    if(row.target === 'ONCLICK'){
      if(row.tooltip === 'visibleMin'){
        setVisibleMin(!visibleMin)
      }else if (row.tooltip === 'visibleTailling'){
        setVisibleTailling(!visibleTailling)
      }else if (row.tooltip === 'visibleMidPrize'){
        setVisibleMidPrize(!visibleMidPrize)
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Derisnap" breadcrumbItem="Straddles" />
          {pageLoader ?
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="text-center my-3">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin me-2" />
                        Loading
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            :
            <>
              {isStraddlebotEnabled ?
                <Card>
                  <CardBody>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <NavItem
                            className={classnames({ current: activeTab === 1 })}
                          >
                            <NavLink
                              className={classnames({ current: activeTab === 1 })}
                              onClick={() => {
                                setactiveTab(1)
                              }}
                              disabled={!(passedSteps || []).includes(1)}
                            >
                              <span className="number">1.</span> Choose Expiry
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 2 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 2 })}
                              onClick={() => {
                                setactiveTab(2)
                              }}
                              disabled={!(passedSteps || []).includes(2)}
                            >
                              <span className="number">2.</span> Confirm Strikes
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 3 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 3 })}
                              onClick={() => {
                                setactiveTab(3)
                              }}
                              disabled={!(passedSteps || []).includes(3)}
                            >
                              <span className="number">3.</span> Bot Setup
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={classnames({ current: activeTab === 4 })}
                          >
                            <NavLink
                              className={classnames({ active: activeTab === 4 })}
                              onClick={() => {
                                setactiveTab(4)
                              }}
                              disabled={!(passedSteps || []).includes(4)}
                            >
                              <span className="number">4.</span> Bot Success
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <TabContent activeTab={activeTab} className="body">
                          <TabPane tabId={1}>
                            <div className="p-4 border">
                              <Form>
                                <Row>
                                  <Label
                                        htmlFor="formrow-exchange-type"
                                        md="6"
                                        className="col-form-label"
                                        >
                                        Exchange Region
                                        </Label>

                                                <Row className="mb-3">
                                                <Col lg="6">
                                                <div className="form-check form-check-inline">
                                                <input
                                                className="form-check-input"
                                                type="radio"
                                                name="exchange_type"
                                                id="india"
                                                value="India"
                                                defaultChecked={exchangeType === 1}
                                                onChange={(e)=>emptyUsers(e)}
                                                />
                                                <label className="form-check-label" htmlFor="india">
                                                India
                                                </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                <input
                                                className="form-check-input"
                                                type="radio"
                                                name="exchange_type"
                                                id="global"
                                                value="Global"
                                                defaultChecked={exchangeType === 0}
                                                onChange={(e)=>emptyUsers(e)}
                                                />
                                                <label className="form-check-label" htmlFor="global">
                                                Global
                                                </label>
                                            </div>
                                                </Col>                  

                                                </Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label htmlFor="users_selection_type">Users</Label>{" "}<i className="bx bx-info-circle" id="Users" />
                                      <UncontrolledTooltip placement="top" target="Users">
                                        {ToolTipData.users}
                                      </UncontrolledTooltip>
                                      <select id="users_selection_type" className="form-select" onChange={(e) => handleSelectionType(e)} value={selectionType} >
                                        <React.Fragment key='all-users'><option value='all-users'> All Users </option>
                                        </React.Fragment>
                                        <React.Fragment key='selected-users'>
                                          <option value='selected-users'> Specific Users </option>
                                        </React.Fragment>
                                      </select>
                                    </div>
                                  </Col>
                                  {selectionType === 'selected-users' ?
                                    <Col lg="6">
                                      <div className="mb-3">
                                        <Label>Select users</Label>
                                        <Select id="selected_users" value={selectedUsers} isMulti={true} onChange={handleSelect} options={usersList} classNamePrefix="select2-selection"></Select>
                                      </div>
                                    </Col>
                                    : ""
                                  }
                                </Row>
                                <Row>
                                  <Col lg="6">
                                    <div className="mb-3">
                                      <Label htmlFor="expiry_date">Expiration Date</Label>{" "}<i className="bx bx-info-circle" id="Expiration_Date" />
                                      <UncontrolledTooltip placement="top" target="Expiration_Date">
                                        {ToolTipData.expiration_date}
                                      </UncontrolledTooltip>
                                      <select id="expiry_date" className="form-select">
                                        {straddleExpiryDates.map((index) => (
                                          <React.Fragment key={index}>
                                            <option value={index} > {formatUTCDate(index)} </option>
                                          </React.Fragment>
                                        ))}
                                      </select>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </div>
                          </TabPane>
                          <TabPane tabId={2}>
                            <div className="p-4 border">
                              {straddleStrikes ?
                                <Row>
                                  <Col lg="4">
                                    <Card outline color="primary" className="border">
                                      <CardHeader className="bg-transparent">
                                        <h5 className="my-0 text-primary">
                                          <i className="mdi mdi-arrow-up-bold-outline me-1" />Calls
                                        </h5>
                                      </CardHeader>
                                      <CardBody>
                                        <CardTitle className="mt-0">{straddleStrikes.calls.symbol}</CardTitle>
                                        <ul className="list-group">
                                          <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{straddleStrikes.calls.best_ask}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{straddleStrikes.calls.best_bid}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {straddleStrikes.calls.sell_price}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : <span className="text-dark">{Number(straddleStrikes.calls.order_margin).toFixed(2)} (1 cont)</span></li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {straddleStrikes.calls.leverage} </li>
                                        </ul>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="4">
                                    <Card outline color="primary" className="border">
                                      <CardHeader className="bg-transparent">
                                        <h5 className="my-0 text-primary">
                                          <i className="mdi mdi-arrow-down-bold-outline me-1" />Puts
                                        </h5>
                                      </CardHeader>
                                      <CardBody>
                                        <CardTitle className="mt-0">{straddleStrikes.puts.symbol}</CardTitle>
                                        <ul className="list-group">
                                          <li className="list-group-item"><b className="text-body fw-bold">Best Ask</b> : <span className="text-danger">{straddleStrikes.puts.best_ask}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Best Bid</b> : <span className="text-success">{straddleStrikes.puts.best_bid}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Sell Price</b> : <span className="text-primary"> {straddleStrikes.puts.sell_price}</span> </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Order Margin</b> : {Number(straddleStrikes.puts.order_margin).toFixed(2)} (1 cont)</li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Leverage</b> : {straddleStrikes.puts.leverage} </li>
                                        </ul>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col lg="4">
                                    <Card outline color="primary" className="border" style={{ "height": "94%" }}>
                                      <CardHeader className="bg-transparent">
                                        <h5 className="my-0 text-primary">
                                          <i className="mdi mdi-information-outline me-1" />Other Informations
                                        </h5>
                                      </CardHeader>
                                      <CardBody>
                                        <CardTitle className="mt-0"></CardTitle>
                                        <ul className="list-group">
                                          <li className="list-group-item"><b className="text-body fw-bold">Contract Index</b> : {straddleStrikes.contract_index} </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Current Index Price</b>: {Number(straddleStrikes.index_price).toFixed(2)} </li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Required Margin</b>: {Number(Number(straddleStrikes.puts.order_margin) + Number(straddleStrikes.calls.order_margin)).toFixed(2)} (1 cont)</li>
                                          <li className="list-group-item"><b className="text-body fw-bold">Time to expire</b>:  <Countdown date={startBotParams.expiry_date} renderer={counterRenderer} /></li>
                                        </ul>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                                :
                                <Alert color="danger" role="alert">
                                  No strikes found, please check with some other expiry date
                                </Alert>
                              }
                            </div>
                          </TabPane>
                          <TabPane tabId={3}>
                          <div className="p-4 border">
                                      <Row>
                                        <Col lg="4">
                                          <div className="mb-3">
                                            <Label className="mt-3">Quantity</Label>{" "}<i className="bx bx-info-circle" id="QTY4" />
                                            <UncontrolledTooltip placement="top" target="QTY4">
                                              {ToolTipData.quantity}
                                            </UncontrolledTooltip>
                                            {/* <Slider value={botSettings.quantity } min={1} max={1000} onChange={(value) => setBotSettings({ ...botSettings, quantity: value })} /> */}
                                            <Input
                                              type="number" step="1" min="1" max="1000"
                                              className="form-control"
                                              id="quantity" value={botSettings.quantity}
                                              onChange={(e) => handleRangeInput(e, 'quantity')}
                                              onInput={(e) => handleRangeInput(e, 'quantity')}
                                            />
                                          </div>
                                        </Col>
                                        
                                      </Row>
                                      <Row className="border opacity-75  bg-light" >
                                          <Col lg="2">
                                            <h5 className="font-size-15 mt-5 mb-3 ">
                                              Re-entry Settings
                                            </h5>
                                          </Col>
                                          <Col lg="2" className="mt-3 mb-3">
                                            {/* <FormGroup className="mb-0"> */}
                                            <Label htmlFor="formrow-firstname-Input">
                                              Enable Re-Entry
                                            </Label>{" "}
                                            <i className="bx bx-info-circle" id="DHOSLIOLreentry_enabled" />
                                            <UncontrolledTooltip placement="top" target="DHOSLIOLreentry_enabled">
                                              {ToolTipData.reentry_enabled}
                                            </UncontrolledTooltip>
                                            {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                            {/* </FormGroup> */}
                                            {/* </Col> */}
                                            {/* <Col md="1"> */}
                                            <div>
                                              <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                className="mb-sm-8 mb-0"
                                                onChange={(e) => setstraddlereentry_enabled(e)}
                                                checked={straddlereentry_enabled}
                                              />
                                            </div>
                                          </Col>
                                          {straddlereentry_enabled?<>
                                          <Col lg="3" className="mt-3 ">
                                            <FormGroup className="mb-0">
                                              <Label htmlFor="formrow-firstname-Input">
                                                Waiting time for Re-Entry (H : M)
                                              </Label> {" "}
                                              <i className="bx bx-info-circle" id="wait_time_reentry" />
                                              <UncontrolledTooltip placement="top" target="wait_time_reentry">
                                                {ToolTipData.wait_time_reentry}
                                              </UncontrolledTooltip>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                name="wait_time_reentry"
                                                onChange={(e) => handleDH(e, 'straddleswait_time_reentry')}
                                                value={toHoursAndMinutes(straddleswait_time_reentry)}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i",
                                                  time_24hr: true,
                                                }}
                                              />
                                            </FormGroup>
                                          </Col><Col lg="3" className="mt-3 ">
                                            <FormGroup className="mb-0">
                                              <Label htmlFor="formrow-firstname-Input">
                                                Time to Close Orders Before Expiry (H : M)
                                              </Label> {" "}
                                              <i className="bx bx-info-circle" id="time_to_close_orders_before_expiry" />
                                              <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry">
                                                {ToolTipData.time_to_close_orders_before_expiry}
                                              </UncontrolledTooltip>
                                              <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                name="wait_time_reentry"
                                                onChange={(e) => handleDH(e, 'time_to_close_orders_before_expiry_straddles')}
                                                value={toHoursAndMinutes(time_to_close_orders_before_expiry_straddles)}
                                                options={{
                                                  enableTime: true,
                                                  noCalendar: true,
                                                  dateFormat: "H:i",
                                                  time_24hr: true,
                                                  maxTime: "12:00"
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col lg="2" className="mt-3">
                                            <FormGroup className="mb-0">
                                              <Label htmlFor="formrow-firstname-Input">
                                                Date to stop reentry
                                              </Label> {" "}
                                              <i className="bx bx-info-circle" id="stopdate_reentry" />
                                              <UncontrolledTooltip placement="top" target="stopdate_reentry">
                                                {ToolTipData.stop_date_reentry}
                                              </UncontrolledTooltip>
                                              {/*must select only future date*/}
                                              <DatePicker
                                                className="form-control d-block"
                                                placeholder="Select date"
                                                name="stopdate_reentry"
                                                onChange={(val) => {
                                                  const date = new Date(val);
                                                  const dateOnly = date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0');
                                                  setBotSettings({
                                                    ...botSettings,
                                                    stopdate_reentry: dateOnly,
                                                  });
                                                }}

                                                value={botSettings.stopdate_reentry}
                                                options={{
                                                  minDate: tomorrow // Set to current date to disable past dates
                                                }}
                                              />

                                            </FormGroup>
                                          </Col></>:''
                                          }
                                        </Row>
                                        <div  className="mb-1 mt-1" >
                                      <a href="#"
                                        onClick={() => {
                                          setStraddleAdvancedSettings(!straddleAdvancedSettings);
                                        }}
                                        className="mb-3" >
                                        Advanced settings
                                      </a>
                                      </div>

                                      {straddleAdvancedSettings && straddleStrikes ? <> <Row className="border">
                                        <div className="panel-body table-responsive">
                                        {/* <table className="table table-bordered"> */}
                                        <Table className={"table table-bordered table align-middle table-nowrap table-check table-responsive"}>
                                          <thead>
                                          <tr style={{textAlign:'left'}}>
                                              <th>Settings</th>
                                              <th>Setting Values</th>
                                              <th>Calls ({straddleStrikes.calls.symbol})</th>
                                              <th>Puts ({straddleStrikes.puts.symbol})</th>
                                              <th>Info</th>
                                            </tr>
                                          </thead>
                                          <tbody style={{textAlign: 'left'}}>
                                            {rows.map((row, index) => (
                                             <React.Fragment key={index}> 
                                             { index === 1 || index === 2  || index === 5 || index == 8 ? <tr  className="table-light"><td ></td><td></td><td></td><td></td><td></td></tr>:''}
                                              <tr key={index}>
                                                <td>{row.label}{" %  "}{row.target === 'ASV'  ? "" : <i className="bx bx-info-circle" id={row.target} onClick={(e) => { handleModel(e, row); 
                                                } }/>}{ row.target === 'ONCLICK' || row.target === 'ASV' ? '': <UncontrolledTooltip placement="top" target={row.target}>
                                                                                 <div className="tooltip-content">
                                                                          <div className="tooltip-header"><h6>{row.orgSettingsName}</h6></div>
                                                                      <hr className="m-2 p-0"/>
                                                                      <div style={{textAlign:"left"}} className="tooltip-body" dangerouslySetInnerHTML={{ __html: ToolTipData[row.tooltip] }}></div></div>
                                                                              </UncontrolledTooltip>}</td>
                                                                              {row.label === 'Activate Trailing' ? "" : (
                                                                                  <td>
                                                                                  {row.label === 'Approximate Strike Values' ? (
                                                                                    'Index Price - ' + straddleStrikes.index_price
                                                                                  ) : row.label === 'Activate Trailing' ? '' : (
                                                                                    <div className="d-flex align-items-center" style={{ width: 'auto', maxWidth: '80px' }}>
                                                                                      <Input
                                                                                        type="number"
                                                                                        max={row.max}
                                                                                        step={row.step}
                                                                                        min={row.min}
                                                                                        invalid={errors[row.name]?.message ? true : false}
                                                                                        {...register(row.name, (row.validation || {}))}
                                                                                        className="form-control"
                                                                                        id={row.tooltip}
                                                                                        value={row.settings}
                                                                                        style={{ width: '80px', backgroundImage: 'none' }}
                                                                                        onChange={(e) => handleInputChange(e, index, row.tooltip, row.botSettingsvalue)}
                                                                                        onInput={(e) => handleInputChange(e, index, row.tooltip, row.botSettingsvalue)}
                                                                                      />
                                                                                      {errors[row.name]?.message && (
                                                                                        <div style={{marginLeft: '10px'}}>
                                                                                          <small className="text-danger">{errors[row.name]?.message}</small>
                                                                                        </div>
                                                                                      )}
                                                                                    </div>
                                                                                  )}
                                                                                </td>
                                                                                )}
                                                {row.label === 'Activate Combined SL at' || row.label === 'Activate Combined Trailing at' || row.label === 'Combined Trail Exit at' || row.label === 'Activate SL if index changes to' || row.label === 'Activate Trailing' ? '' :<td >{row.calls}</td>}
                                                <td colSpan={row.label === 'Activate Combined SL at' || row.label === 'Activate Combined Trailing at' || row.label === 'Combined Trail Exit at' || row.label === 'Activate SL if index changes to' ? 2 : row.label === 'Activate Trailing' ? 3: 1} style={row.label === 'Activate Combined SL at' || row.label === 'Activate Combined Trailing at' || row.label === 'Combined Trail Exit at' || row.label === 'Activate SL if index changes to' ? {textAlign:'center'}:{}} >{row.label === 'Activate Trailing' ? (<><input
                                                                                  type="checkbox"
                                                                                  className="form-check-input"
                                                                                  id="straddlesMin"
                                                                                  checked={SlTriggerStraddles}
                                                                                  onChange={(event) => { setSlTriggerStraddles(event.target.checked); handleRangeInput(event, 'Sl_trigger_take_profit', false) }}
                                                                                />{" "}<label className="form-check-label font-size-10" htmlFor="straddlesMin">{row.puts}</label><p style={{fontSize:'8px'}}>Activates trailing and take profit after stop loss hit on one leg.
                                                                                </p></>): ( row.label === 'Activate SL if index changes to' ? 
                                                                                  <tr className="d-flex justify-content-between space-between-equal align-items-center" >
                                                                                    <td >{row.calls}</td>
                                                                                    <td ><b>or</b></td>
                                                                                    <td >{row.puts}</td>
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                  </tr>
                                                                                :row.label === 'Activate Combined SL at' || row.label === 'Activate Combined Trailing at' || row.label === 'Combined Trail Exit at' || row.label === 'Activate SL if index changes to' ? <tr className="d-flex justify-content-between space-between-equal align-items-center" >
                                                                                <td ></td>
                                                                                <td >{row.puts}</td>
                                                                                <td ></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                              </tr> : row.puts)}</td>
                                                                                <td>
                                                                                {<><i style={{ cursor: 'pointer', fontSize: '1rem', padding: '0.5rem' }} className="bx bx-detail" id={(row.target)+(row.tooltip)+(row.botSettingsvalue)} />
                                                                                <UncontrolledTooltip style={{maxWidth: "none",width: "auto"}} placement="top" target={(row.target)+(row.tooltip)+(row.botSettingsvalue)}>
                                                                                 <div className="tooltip-content">
                                                                          <div className="tooltip-header"><h6>Details</h6></div>
                                                                      <hr className="m-2 p-0"/>
                                                                      <div className="tooltip-body" style={{textAlign:'left'}}>{row.formula}</div></div>
                                                                              </UncontrolledTooltip></> }
                                                            {/* <span className="d-inline-block" tabIndex={index}  data-toggle="tooltip" title={row.formula}>
                                                            <button className="btn btn-secondary" style={{pointerEvents: 'none'}} type="button" disabled>Disabled button</button>
                                                          </span> */}
                                                         
                                                      </td>
                                                                              
                                              </tr></React.Fragment>
                                            ))}
                                            
                                          </tbody>
                                        </Table>
                                        
                                        {/* </table> */}
                                        </div>
                                          
                                          
                                        </Row>

                                      </> : ""}
                                    </div>
                          </TabPane>
                          <TabPane tabId={4}>
                            <div className="p-4 border">
                              <div className="row justify-content-center">
                                <Col lg="6">
                                  <div className="text-center">
                                    <div className="mb-4">
                                      <i className="mdi mdi-check-circle-outline text-success display-4" />
                                    </div>
                                    <div>
                                      <h5>Straddle Bot intitiated successfully</h5>
                                      {failedUserDetails.length > 0 ?
                                        <p>
                                          <Alert className="alert-danger" role="alert">
                                            <h4 className="alert-heading">Orders failed for below user(s):</h4>
                                            <ul className="list-unstyled fw-medium">                                                                         {failedUserDetails.map((failedUser, index) => {
                                              return (
                                                <li key={index}><span className="py-2 d-block"><i className="mdi mdi-chevron-right me-1"></i> {failedUser.profile.first_name + ' ' + failedUser.profile.last_name} - <a className="text-danger">{failedUser.error}</a></span></li>
                                              )
                                            })}
                                              <span className="badge badge-soft-danger badge-pill float-end ms-1 font-size-12">{failedUserDetails.length > 9 ? failedUserDetails.length : '0' + failedUserDetails.length}</span>
                                            </ul>
                                          </Alert>
                                        </p>
                                        : ""
                                      }
                                    </div>
                                  </div>
                                </Col>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                      <div className="actions clearfix mt-4">
                        <ul className={formLoader ? "disabled" : ""}>
                          {
                          errors && Object.keys(errors).length > 0 ?
                          (
                          <li className="text-center text-danger fw-semibold">Kindly fill all the required fields above.*</li>
                          )
                          : ''
                          }   
                          {activeTab !== 1 ?
                            <li
                              className={activeTab === 1 ? "previous disabled" : "previous"}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  { !formLoader ? toggleTab(activeTab - 1) : "" }
                                }}
                              >
                                Previous
                              </Link>
                            </li>
                            : ""
                          }
                          {activeTab !== 4 ?
                            <li
                              className="next"
                            >
                              <Link
                                to="#"
                                onClick={() => {setStraddleAdvancedSettings(true);handleTabClick(activeTab, formLoader)}}
                              >
                                {formLoader ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing...</span> : <span>{activeTab === 3 ? "Start Bot" : "Next"}</span>}
                              </Link>
                            </li>
                            : ""
                          }
                        </ul>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                :
                <Card>
                  <CardBody>
                    <span className="mb-4">Straddle strategy is disabled now. You can enable it from <Link to="/settings">Settings</Link> page.</span>
                  </CardBody>
                </Card>
              }
            </>
          }
        </Container>
      </div>
      <Modal
        isOpen={visibleMin}
        toggle={() => {
          setVisibleMin(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            To set the Minimum profit for an order.
          </h5>
          <button
            type="button"
            onClick={() => {
              setVisibleMin(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {parse('' + MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setVisibleMin(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
          setVisibleTailling(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            To set the Minimum profit for an order.
          </h5>
          <button
            type="button"
            onClick={() => {
              setVisibleTailling(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {parse('' + TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setVisibleTailling(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={visibleMidPrize}
        toggle={() => {
          setVisibleMidPrize(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
          Make Limit Orders x% from Mid/MarkPrice
          </h5>
          <button
            type="button"
            onClick={() => {
              setVisibleMidPrize(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {parse('' + MidPrizeMsg)}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              setVisibleMidPrize(false);
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default CreateStraddle