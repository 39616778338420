import { takeEvery, put, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  userRegister,
  //postJwtRegister,
} from "../../../helpers/auth_helper";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user,history } }) {
  try {
    const register = yield call(userRegister, user)    
    history.push("/login")
    yield put(registerUserSuccessful('An email verification link has been sent to your email. Please verify your email then login.'));
  } catch (error) {
    window.grecaptcha.reset();
    yield put(registerUserFailed(error));
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export default accountSaga;
