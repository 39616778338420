import React,{useState,useEffect} from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import avatar1 from "../../assets/images/users/user-2.png"
import profileImg from "../../assets/images/admin-banner.png"
import api from '../../helpers/api_helper';

const WelcomeComp = () => {
  const get_user_details = JSON.parse(localStorage.getItem("user"));
  const [active_users, setActive_users] = useState();
  const [inactive_users, setInActive_users] = useState();
  const [key_active_users, setKey_active_users] = useState();
  const [key_inactive_users, setKey_inActive_users] = useState();

  const get_all_users = async()=>
  {
    await api({ contentType: true, auth: true }).get('/abotex/user/all-users').then((res) => {
      const get_users = res.data.response.users;
      const filter_user = get_users.filter(item =>item.status && item.status.toLowerCase() == "active");
      const inactive_user = get_users.filter(item =>item.status && item.status.toLowerCase() == "inactive");
      const key_active_user = get_users.filter(item => item.apikey_updated == 1);
      const key_inactive_user = get_users.filter(item => item.apikey_updated == 0);
      setActive_users(filter_user.length);
      setInActive_users(inactive_user.length);
      setKey_active_users(key_active_user.length);
      setKey_inActive_users(key_inactive_user.length);
      }).catch(err => {
        throw err;      
      });
  }

  useEffect(() => {
    get_all_users();
    //get_running_orders();
  },[]);
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="9">
              <div className="text-white p-3">
                <h5 className="text-white">Welcome Back !</h5>
                <p className="text-capitalize">{get_user_details && get_user_details.profile && get_user_details.profile.first_name ? get_user_details.profile.first_name:'Derisnap'}</p>
              </div>
            </Col>
            <Col xs="3" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={avatar1}
                  alt=""
                  className="img-thumbnail rounded-circle"
                  style={{backgroundColor:'white'}}
                />
              </div>
              <h5 className="font-size-15 text-truncate text-capitalize">{get_user_details && get_user_details.profile && get_user_details.profile.first_name ? get_user_details.profile.first_name+" "+get_user_details.profile.last_name:''}</h5>
              <p className="mb-0 text-truncate">{get_user_details && get_user_details.profile? get_user_details.email:''}</p>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="3">
                    <h5 className="font-size-15">{active_users}</h5>
                    <p className="mb-0">Active Users</p>
                  </Col>
                  <Col xs="3">
                    <h5 className="font-size-15">{inactive_users}</h5>
                    <p className="mb-0">Inactive Users</p>
                  </Col>
                  <Col xs="3">
                    <h5 className="font-size-15">{key_active_users}</h5>
                    <p className="mb-0">Key Active Users</p>
                  </Col>
                  <Col xs="3">
                    <h5 className="font-size-15">{key_inactive_users}</h5>
                    <p className="mb-0">Key Inactive Users</p>
                  </Col>
                </Row>
                <div className="float-end mt-3">
                  <Link
                    to="/users"
                    className="btn btn-primary  btn-sm"
                  >
                    All Users <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
