import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import CryptoJS from "crypto-js";
import React from "react";
import { Connection } from './connection.service';
import api from '../helpers/api_helper';

export const formatUTCDate = (date) => {    
  return new Date(date).toLocaleString('en-gb', { day: 'numeric', month: '2-digit',  year: 'numeric' });
};

export const formatUTCDateTime = (date) => {    
  return new Date(date).toLocaleString('en-gb', { day: 'numeric', month: '2-digit',  year: 'numeric', hour: "2-digit",
  minute: "2-digit",
  second: "2-digit" }).replace(',','');
};

export const getUserWSConnectionObject = async () => {
  try {

    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    
    if (!uid) {
      return false;
    }
   
   const firestoreDb = firebase.firestore();
   const result = firestoreDb.collection("users").doc(uid).collection("config").doc('data');
    let config_data = false;
    await result.get().then((doc) => {
      if (doc.exists) {					
        config_data = doc.data()
      }
    });	

    let config = {        
      appKey: "",
      appSecret: "",
    }       
    if (config_data) {
      var key_secret = __KEY_SECRET__;
      var decry_appkey  = CryptoJS.AES.decrypt(config_data.appKey, key_secret);
      var decryptedAppkey = JSON.parse(decry_appkey.toString(CryptoJS.enc.Utf8));        
      var decry_appsecret  = CryptoJS.AES.decrypt(config_data.appSecret, key_secret);
      var decryptedAppsecret = JSON.parse(decry_appsecret.toString(CryptoJS.enc.Utf8)); 
      var exchangeType = config_data.exchangeType?config_data.exchangeType:"India";
      config = {        
        appKey: decryptedAppkey,
        appSecret: decryptedAppsecret,
      } 
    }       
      
    if(exchangeType == "India"){
      if (!process.env.REACT_APP_SOCKET_URL || process.env.REACT_APP_SOCKET_URL === "") {
        return false;
      }
    }else{
      if (!process.env.REACT_APP_GLOBAL_SOCKET_URL || process.env.REACT_APP_GLOBAL_SOCKET_URL === "") {
        return false;
      }
    } 

    if (config.appKey === "" || config.appSecret === "") {
      return false;
    }    
    //New connection
    let ws_connection;
    if(exchangeType == "India"){
      ws_connection = new Connection(uid, process.env.REACT_APP_SOCKET_URL, config.appKey, config.appSecret,exchangeType); 
     }else{
      ws_connection = new Connection(uid, process.env.REACT_APP_GLOBAL_SOCKET_URL, config.appKey, config.appSecret,exchangeType); 
     }
     if((currentUser.profile && currentUser.profile.exchangeType && currentUser.profile.exchangeType != exchangeType) || (currentUser.profile && !currentUser.profile.exchangeType)){
      currentUser.profile.exchangeType = exchangeType;
      localStorage.setItem("user",JSON.stringify(currentUser));
    }            
    return ws_connection;         
  } catch (e) {    
    return false;
  }
};

export const getOrderStatusNameStyle=(order)=>{
  let status_name = order.status;
  switch (order.status.toLowerCase()) {
    case "pending":
      status_name = `<span class="badge badge-soft-warning">Waiting</span>`;
      break;
    case "open":
      if (order.filled_quantity > 0) { 
        status_name = `<span class="badge badge-soft-info">Open Position</span>`;
      } else {
        status_name = `<span class="badge badge-soft-primary">Open Order</span>`;
      }      
      break;
    case "closed":
      if (order.bot_status.toLowerCase() === 'completed') {
        status_name = `<span class="badge badge-soft-success">Closed</span>`;
      } else {
        status_name = `<span class="badge badge-soft-info">Open Position</span>`;
      }      
      break;
    case "cancelled":
      status_name = `<span class="badge badge-soft-danger">Cancelled</span>`;
      break;
    case "filled":
      status_name = `<span class="badge badge-soft-success">Filled</span>`;
      break;
    default:
      status_name = `<span class="badge badge-soft-dark">`+capitalizeFirst(order.status)+`</span>`;
  }
  return status_name;
}

export const getOrderStatusName = (order) => {
  let status_name = order.status;
  switch (order.status.toLowerCase()) {
    case "pending":
      status_name = "Waiting";
      break;
    case "open":
      if (order.filled_quantity > 0) { 
        status_name = "Open Position";
      } else {
        status_name = "Open Order";
      }      
      break;
    case "closed":
      if (order.bot_status.toLowerCase() === 'completed') {
        status_name = "Closed";
      } else {
        status_name = "Open Position";
      }      
      break;
    case "cancelled":
      status_name = "Cancelled";
      break;
    case "filled":
      status_name = "filled";
      break;
    default:
      status_name = order.status;
  }
  return status_name;
}

export const getMarketActionName = (order) => {
  let market_action_name = order.market_action;
  switch (order.market_action.toLowerCase()) {
    case "market_close":
      market_action_name = "Market Close";
      break;
    case "market_close_single":
      market_action_name = "Market Close";
      break;
    default:
      market_action_name = order.market_action;
  }
  return market_action_name;
}

export const getMarketActionTriggerName = (order) => {
  let market_action_trigger_name;
  switch (order.market_action_trigger.toLowerCase()) {
    case "manual":
      if(order.status === 'closed')
      market_action_trigger_name = "Manually stopped";
      break;
    case "short_expiry_hours":
      market_action_trigger_name = "Market close time reached";
      break;
    case "market_close_time":
      market_action_trigger_name = "Market close time reached";
      break;
    case "take_profit_percentage":
      market_action_trigger_name = "Take Profit % reached";
      break;
    case "stop_loss":
      market_action_trigger_name = "Stop loss reached";
      break;
    case "trigger":
      market_action_trigger_name = "Trigger price reached";
      break;
    case "market_close_time_reached":
        market_action_trigger_name = "ReEntry Market Close Time Reached";
        break;
    case "minimum_take_profit":
      market_action_trigger_name = "Profit reduced & stop price reached";
      break;
    case "closed_from_delta":
      market_action_trigger_name = "Position closed from delta";
      break;
    case "liquidation_order":
      market_action_trigger_name = "Liquidation order";
      break;
    case "expired":
      market_action_trigger_name = "Symbol expired";
    break;
    default:
      market_action_trigger_name = order.market_action_trigger;
  }
  
  return market_action_trigger_name;
}

export const getCalendarTypeName = (order,option='') => {
  let calendar_type_name;
  let calendar_type = option;
  if (order) {
    calendar_type = order.order_settings.calendar_type.toLowerCase();
  } 
  
  switch (calendar_type) {
    case "short_calendar":
      calendar_type_name = "Short Calendar";
      break;
    case "long_calendar":
      calendar_type_name = "Long Calendar";
      break;
    default:
      calendar_type_name = calendar_type;
  }    
  
  return calendar_type_name;
}

export const getFirstPositionName = (order,option='') => {
  let first_position_name;
  let expiry_position_to_begin = option;
  if (order) {
    expiry_position_to_begin = order.order_settings.expiry_position_to_begin.toLowerCase();
  }

  switch (expiry_position_to_begin) {
    case "short_expiry_position":
      first_position_name = "Short Expiry";
      break;
    case "long_expiry_position":
      first_position_name = "Long Expiry";
      break;
    default:
      first_position_name = expiry_position_to_begin;
  }      
  return first_position_name;
}

export const getFillTypeName = (order,option='') => {
  let fill_type_name;
  let fill_type = option;
  if (order) {
    fill_type = order.order_settings.fill_type.toLowerCase();
  }

  switch (fill_type) {
    case "instant_fill":
      fill_type_name = "Instant Fill";
      break;
    case "wait_and_fill":
      fill_type_name = "Wait & Fill";
      break;
    default:
      fill_type_name = fill_type;
  }      
  return fill_type_name;
}

export const CustomParseFloat = (str,val) => {
  str = str.toString();
  if (str.indexOf(".") !== -1) {
    str = str.slice(0, (str.indexOf(".")) + val + 1); 
  }  
  return str;   
}

export const getContractValue = (symbol) => {
  switch (symbol) {
    case "BTCUSDT":
      return 0.001;
    case "ETHUSDT":
      return 0.01;
    default:
      return 0.001
  }
}
export const capitalizeFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const getSeconds = (hrs, mins) => {
  let total_secs;
  if (hrs == '00') {
    total_secs = Number(mins) * 60;
  }
  else {
    const total_hours = Number(hrs) * 3600;
    const total_minutes = Number(mins) * 60;
    total_secs = total_hours + total_minutes;
  }
  return total_secs;
}
export const getSecondsFromTime= (time) => {
  let time_arr=time.split(":")
  let hrs=time_arr[0];
  let mins=time_arr[1];
  let total_secs;
  if (hrs == '00') {
    total_secs = Number(mins) * 60;
  }
  else {
    const total_hours = Number(hrs) * 3600;
    const total_minutes = Number(mins) * 60;
    total_secs = total_hours + total_minutes;
  }
  return total_secs;
}
export const trendFormValidation = (trendSettings)=>{
  let msg="";
  if(trendSettings.trend_method=="supertrend" && trendSettings.st_atr_length==""){
    msg=("ATR length value can not be empty");
  }else if(trendSettings.trend_method=="supertrend" && trendSettings.st_factor==""){
    msg=("Factor value can not be empty");
  }else if(trendSettings.trend_method=="supertrend" && Number(trendSettings.st_atr_length) > 200){
    msg=("ATR length must be less than 200");
  }else if(trendSettings.trend_method=="supertrend" && Number(trendSettings.st_factor) >=  Number(trendSettings.st_atr_length)){
    msg=("Factor value must be less than ATR value");
  }else if(trendSettings.st_min_distance!="" &&  trendSettings.st_max_distance !="" && Number(trendSettings.st_min_distance) >=  Number(trendSettings.st_max_distance)){
    msg=("Minimum supertrend distance must be less than maximum supertrend distance");
  }else if(trendSettings.trend_method=="sma" && trendSettings.short_term==""){
    msg=("Short term field can not be empty");
  }else if(trendSettings.trend_method=="sma" && trendSettings.long_term==""){
    msg=("Long term field can not be empty");
  }else if(trendSettings.trend_method=="sma" && trendSettings.short_term!="" && trendSettings.long_term!="" && Number(trendSettings.short_term) >= Number(trendSettings.long_term)){
    msg=("Short term value must be less than long term value");
  }else if(trendSettings.trend_method=="ema" && trendSettings.ema_short_term==""){
    msg=("Short term field can not be empty");
  }else if(trendSettings.trend_method=="ema" && trendSettings.ema_long_term==""){
    msg=("Long term field can not be empty");
  }else if(trendSettings.trend_method=="ema" && trendSettings.ema_short_term!="" && trendSettings.ema_long_term!="" && Number(trendSettings.ema_short_term) >= Number(trendSettings.ema_long_term)){
    msg=("Short term value must be less than long term value");
  }else if(trendSettings.trend_method=="macd" && trendSettings.macd_short_term==""){
    msg=("Short term field can not be empty");
  }else if(trendSettings.trend_method=="macd" && trendSettings.macd_long_term==""){
    msg=("Long term field can not be empty");
  }else if(trendSettings.trend_method=="macd" && trendSettings.macd_signal_term==""){
    msg=("Signal term field can not be empty");
  }else if(trendSettings.trend_method=="macd" && Number(trendSettings.macd_long_term) <= Number(trendSettings.macd_short_term)){
    msg=("Short term value must be less than long term value");
  }
  return msg;
}
export const get_actual_index = (strike_price, index_price) => {

  return Math.abs(((strike_price - index_price) / index_price) * 100).toFixed(2) + '%';
} 
export const getStrangleMarketAction = (order) => {
  let market_action_trigger_name = "";
  if (order.closed_by_portal && order.closed_by_portal == 1) {
    market_action_trigger_name = "Closed In Delta exchange portal.";
    if (order.reason) {
      market_action_trigger_name = market_action_trigger_name + 'Reason:' + order.reason;
    }
  } else {
    if((order.order_type.toLowerCase() == "trailing_stop_loss" || order.order_type.toLowerCase() == "stop_loss") && order.trailing_stop_loss_price && order.trailing_stop_loss_price > 0){     
        market_action_trigger_name = "Triggered Trailing Take Profit";
        market_action_trigger_name = market_action_trigger_name + ' @ ' + formatUTCDateTime(order.triggered_at)
    }else{
    switch (order.order_type.toLowerCase()) {
      case "roll":
        market_action_trigger_name = "Triggered Roll";
        break;
      case "stop_loss":
        market_action_trigger_name = "Triggered Stop Loss";
        break;
      case "trigger":
        market_action_trigger_name = "Triggered Buy";
        break;
      case "take_profit":
        market_action_trigger_name = "Triggered Take Profit";
        break;
      case "market_close":
        market_action_trigger_name = "Triggered Market Close";
        break;
      default:
        market_action_trigger_name = order.market_action_trigger ? order.market_action_trigger : '';
    }
    }
    if (market_action_trigger_name != '') {
      if (order.triggered_at) {
        market_action_trigger_name = market_action_trigger_name + ' @ ' + formatUTCDateTime(order.triggered_at)
      } else {
        market_action_trigger_name = market_action_trigger_name + ' @ ' + formatUTCDateTime(order.updated_at)
      }
    }
  }
  
  return market_action_trigger_name;
}
export const getOrderStatusStyle=(order,type="")=>{
  let statusname="";
  if(order.status.toLowerCase()=="open"){
    statusname='<span class="badge badge-soft-primary">Open Order</span>';
  }else if(order.status.toLowerCase() === "closed" && order.direction.toLowerCase() === 'sell' && (!order.closed_by_portal) && !order.booked_pnl && !order.realized_pnl){
    statusname='<span class="badge badge-soft-info">Open Position</span>';
  }else if(order.status.toLowerCase() == 'closed' && order.direction == 'buy' && order.order_type !== 'market_close' && order.roll && order.roll == 1 && type!="history"){
    statusname='<span class="badge badge-soft-success">Closed And Waiting For Roll</span>';
  }else if(order.status.toLowerCase()=="closed"){
     statusname='<span class="badge badge-soft-success">Closed</span>';
  }else if(order.status.toLowerCase()=="cancelled"){
    statusname='<span class="badge badge-soft-danger">Cancelled</span>';
 }else{
    statusname='<span class="badge badge-soft-light">'+capitalizeFirst(order.status)+'</span>';
  }
  return statusname;
}
export const getTrendmethodStyle=(name)=>{
  
  let method=[];
  if(typeof name!=="undefined"){
  if(name.toLowerCase()=="sma"){
    method=[{'name':'SMA','fullform':'Simple Moving Average Crossovers'}];
  }else if(name.toLowerCase()=="ema"){
    method=[{'name':'EMA','fullform':'Exponential Moving Average Crossovers'}];
  }else if(name.toLowerCase()=="macd" ){
    method=[{'name':'MACD','fullform':'MACD (Moving average convergence/divergence) and signal line Crossovers'}];
  }else if(name.toLowerCase()=="supertrend" ){
    method=[{'name':'Supertrend','fullform':'Supertrend using ATR (Average True Range )'}];
  }
}else{
  method=[{'name':'SMA','fullform':'Simple Moving Average Crossovers'}];
}
  return method;
} 
export const getPrices = (order,type) => {
  const contractValue = getContractValue('BTCUSDT');
  let order_sell_price=order.average_fill_price?order.average_fill_price:order.sold_price?order.sold_price:0
    if(order_sell_price ==0){
      return '-'
    }
    if(type=="stop_loss" && order.settings && order.settings.stop_loss_percentage && !order.settings.combined_sl){
      return (Number(order_sell_price) * ((100 + Number(order.settings.stop_loss_percentage)) / 100)).toFixed(2);
    }else if(type=="stop_loss" && order.settings && order.settings.stop_loss_percentage && order.settings.combined_sl){
      return order.combined_stoplossprice?order.combined_stoplossprice:'...';
    }else if(type=="trigger"&& order.settings && order.settings.trigger_percentage){
      return (Number(order_sell_price) * ((100 + Number(order.settings.trigger_percentage)) / 100)).toFixed(2);
    }else if(type=="take_profit"&& order.settings && order.settings.take_profit_percentage){
      return (Number(order_sell_price) * ((100 - Number(order.settings.take_profit_percentage)) / 100)).toFixed(2);
    }else if(type=="min_take_profit"&& order.settings && order.settings.min_take_profit_percentage){
      return (Number(order_sell_price) * ((100 - Number(order.settings.min_take_profit_percentage)) / 100)).toFixed(2);
    }else if(type=="booked_pnl"){
        const pnl = order.booked_pnl ? order.booked_pnl * Number(contractValue) *  Number(order.filled_quantity) : order.realized_pnl ? order.realized_pnl : '...';
        return pnl
    }else if(type=="unrealized_pnl"){
      if ((order.status.toLowerCase() === "closed" || order.filled_quantity > 1) && order.direction === "sell") {
        let sold_price = order.average_fill_price?order.average_fill_price:order.price;
        if (order.direction === 'buy') {
            sold_price =order.average_fill_price?order.average_fill_price:order.sold_price
        }
        let unrealized_pnl = parseFloat((Number(sold_price) - Number(order.lowest_ask_now)) * Number(contractValue) * Number(order.filled_quantity));
        return unrealized_pnl;
      } else {
        return '...';
      }
    }else{
      return '-';
    }
}
export const counterRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
      // Render a completed state
      return <span className='text-danger'>Expired!!</span>;
  } else {
      // Render a countdown
      return <span>{days}d {hours}h {minutes}m {seconds}s</span>;
  }
}; 
export const timeFormat = (seconds) => {
  var totalNumberOfSeconds = 60000;
 var hours = parseInt( totalNumberOfSeconds / 3600 );
 var minutes = parseInt( (totalNumberOfSeconds - (hours * 3600)) / 60 );
 var seconds = Math.floor((totalNumberOfSeconds - ((hours * 3600) + (minutes * 60))));
 if(seconds >0){
 var result = (hours < 10 ? "0" + hours : hours) + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m " + (seconds  < 10 ? "0" + seconds : seconds)+ "s";
 }else{
  var result = (hours < 10 ? "0" + hours : hours) + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m";
 }
 return result;
}
export const getExpiryDates = async(data) => {
  return new Promise(async(resolve, reject) => {    
    await api({ contentType: true, auth: true }).post('/delta/get-expiry-dates', data).then(res => {       
      resolve(res)
    }).catch(error => {      
      reject(error)      
    })  
  })
}
export const getOrderLogs=async(data) => {
  return new Promise(async(resolve, reject) => {   
    let order_history = []; 
    await api({ contentType: true, auth: true }).post('/delta/get-order-logs', data).then(result => {
      if(result.data){  
          if (result.data.response.data) {
            order_history = result.data.response.data;
          
            order_history.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
            //return order_history
          }    
        }else{
          order_history = [];
          //return order_history
        }
      
      });
      resolve(order_history)
    }).catch(error => {      
      reject(error)      
    })  
 
}
