import React, { useCallback, useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row, Table, Tooltip, Badge, Label,Popover,
    PopoverHeader,
    PopoverBody,
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import {  formatUTCDate,formatUTCDateTime, getStrangleMarketAction, getOrderStatusStyle, getPrices, counterRenderer,capitalizeFirst,timeFormat } from "../../../services/common.service";
//Import Breadcrumb
import Countdown from "react-countdown"
import "react-rangeslider/lib/index.css"
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Flatpickr from "react-flatpickr";
import api from '../../../services/api.service';
const ListOptions = (props) => {
    firebase.auth();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    const currentdate = new Date().toISOString();
    
    //for settings
    const [transactionData, setTransactionData] = useState([]);
    const [ttop1, setttop1] = useState(false);

    const defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 29)).toISOString();
    const defaultEndDate = new Date(new Date()).toISOString();
    const [filterStartDate, setFilterStartDate] = useState(defaultStartDate);
    const [filterEndDate, setFilterEndDate] = useState(defaultEndDate);
    const [popovertop, setpopovertop] = useState(false);
    const [selectionType, setSelectionType] = useState(2)
    const popovertopchange = targetName => {
        if (!popovertop[targetName] || !popovertop[targetName].popover) {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: true
                }
            });
        } else {
            setpopovertop({
                ...popovertop,
                [targetName]: {
                    popover: false
                }
            });
        }
    };

    const convert = (str) => {
        var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    const handleCreatedDateChange = (selectedDates, datestr) => {    
        if (selectedDates && selectedDates.length) {
        let converted_date = new Date(convert(selectedDates[0]));
        converted_date.setHours(0, 0, 0, 0);
        setFilterStartDate(converted_date.toISOString()); 
        if(selectedDates[1]){
            let converted_end_date = new Date(convert(selectedDates[1]));
            converted_end_date.setHours(23, 59, 59, 999);
            setFilterEndDate(converted_end_date.toISOString()); 
        }
        } else {
        setFilterStartDate(defaultStartDate); 
        setFilterEndDate(defaultEndDate);       
        }
    }
    const ispopoverOpen = targetName => {
        return popovertop[targetName] ? popovertop[targetName].popover : false;
    };
    // const historyRef = firebase.firestore().collectionGroup("history").where("strategy", '==', 'strangles').where("settings.created_by",'==',uid).where("settings.created_at", '>=', filterStartDate).where("settings.created_at", '<=', filterEndDate).orderBy("settings.created_at", "desc").orderBy("strangle_id", "desc").orderBy("roll_index", "asc").orderBy("symbol", "desc").orderBy("direction","desc").orderBy("updated_at", "desc"); 

    const getTransactions = async() => {
        
        var body =  {
            uid : uid,
            filterStartDate : filterStartDate,
            filterEndDate : filterEndDate,
            type:selectionType
          }
          await api({ contentType: true, auth: true }).post('/abotex/strangles/strangles-history-data',body).then(async(res)  => {
          var completedResult = res.data.response.data;
            const items = [];
            completedResult.forEach((doc) => {
                let item = doc.data;
                // let sold_price = item.price;
                item.region = doc.type;     
                // if (item.direction === 'buy') {
                //     sold_price =(item.average_fill_price) ?(item.average_fill_price): (item.sold_price) ? item.sold_price : item.price
                // }
                items.push(item)
            });

            const all_strangles = items.reduce(function (row, obj) {
                row[obj.admin_strangle_id] = row[obj.admin_strangle_id] || [];
                row[obj.admin_strangle_id][obj.strangle_id] = row[obj.admin_strangle_id][obj.strangle_id] || [];
                row[obj.admin_strangle_id][obj.strangle_id].push(obj);
                return row;
              }, []);    
              
            const completed_strangles = Object.keys(all_strangles).map(admin_strangle_index => {         
                return Object.keys(all_strangles[admin_strangle_index]).map(strangle_index => {          
                    return all_strangles[admin_strangle_index][strangle_index];
                });
            });
            
            setTransactionData(completed_strangles);
        });

    }

    const handleFilter = (e) => {
        setSelectionType(e.target.value)
    }
    
    useEffect(() => { 
        let mounted = true;
        if (mounted) {      
            getTransactions();
        }         
        return () => {
          // actions to be performed when component unmounts
          mounted = false;
        }
    }, [filterStartDate, filterEndDate,selectionType]);
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Breadcrumbs title="DeriSnap" breadcrumbItem="Completed Strangles" />
                    <Row>
                        <Col lg="12">
                            <Card className="border">
                                <CardBody>
                                <h5 className="mb-3"><b>Filter By</b></h5>

                                <Row>
                                    {/* <Col xxl={1} lg={6} className="d-flex align-items-center">
                                    </Col> */}
                                    <Col xxl={2} lg={6} className="mt-2">
                                    <Label htmlFor="Created">Created date</Label>
                                    <Flatpickr className="form-control d-block"
                                        placeholder="Created Date"
                                        options={{
                                        mode: "range",
                                        dateFormat: "d/m/Y",
                                        defaultDate: [filterStartDate, filterEndDate]
                                        }}
                                        onChange={handleCreatedDateChange}
                                    />
                                    </Col>
                                    <Col xxl={2} lg={6} className="mt-2">
                                    <Label htmlFor="Created">Region</Label>
                                    <select className="form-select" id="selection_type"  onChange={(e)=>handleFilter(e)} value={selectionType}>
                                        <option value='2'> All Users </option>
                                        <option value='1'> Indian </option>
                                        <option value='0'> Global </option>
                                    </select>
                                    </Col>
                                </Row>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-3">Orders</h4>

                                 
                                        <div className="table-responsive">
                                        {/*********/}
                                        {filterStartDate && filterEndDate ? <p className="fw-bold">Showing data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)}</p>:''}
                                                <Table className="table table-bordered table align-middle table-nowrap table-check caption-top-dark">
                                                    <thead>
                                                        <tr>
                                                            <th>Expiry Date</th>
                                                            <th>User</th>
                                                            <th>Side</th>
                                                            <th>Symbol</th>
                                                            <th>Sell/Buy Price</th>
                                                            <th>Trigger/SL/Take Profit Price</th>
                                                            <th>Qty</th>
                                                            <th>Order ID</th>
                                                            <th>Status</th>
                                                            <th className="text-decoration-underline" >
                                                                <Tooltip
                                                                    placement="top"
                                                                    isOpen={ttop1}
                                                                    target="TooltipTop1"
                                                                    toggle={() => {
                                                                        setttop1(!ttop1);
                                                                    }}
                                                                >
                                                                    Booked PNL = sell price - buy price
                                                                </Tooltip>
                                                                <span id="TooltipTop1">Booked PNL</span>
                                                            </th>

                                                        </tr>
                                                    </thead>
                                                    {transactionData.length > 0 ? (
                                                    transactionData.map((users,index) => {   const orders_count = users.reduce((a, c) => a + c.length, 0); 
                                                        return (
                                                                <tbody key={index}>
                                                                      {users.map((row,row_index) => { 
                                                                                return(
                                                                                row.map((order, index, arr) => { 
                                                                            let booked_pnl = order.direction === 'sell' ? getPrices(order, "booked_pnl") : "..."
                                                                            let market_action = getStrangleMarketAction(order);     
                                                                            const roll_time_clock = order.settings.Roll_time_clock ?  order.settings.Roll_time_clock.split(":") : '';                      
                                                                            return (
                                                                   
                                                                            <tr className="trow-hover" key={index}>
                                                                                {row_index===0 && index === 0 ?
                                                                                    <td className="p-2 " rowSpan={orders_count}>
                                                                                        <div className="border p-2">
                                                                                        <b className="text-primary font-size-15">{formatUTCDate(order.expiry_date)}</b>
                                                                                        <div style={{ "float": "right" }}><a
                                                                                                id={"Popovertop-" + order.strangle_id}
                                                                                                color="secondary"
                                                                                                onClick={() => {
                                                                                                    popovertopchange(`${order.strangle_id}`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="bx bxs-info-circle text-dark font-size-15"></i>
                                                                                            </a>
                                                                                                <Popover
                                                                                                    placement="right"
                                                                                                    isOpen={ispopoverOpen(`${order.strangle_id}`)}
                                                                                                    target={"Popovertop-" + order.strangle_id}
                                                                                                    toggle={() => {
                                                                                                        popovertopchange(`${order.strangle_id}`);
                                                                                                    }}
                                                                                                >
                                                                                                    <PopoverHeader style={{backgroundColor:"black",color:"white",borderBottomColor:"white"}}><b>Info</b></PopoverHeader>
                                                                                                    <PopoverBody style={{backgroundColor:"black",color:"white"}}>
                                                                                                        Index %: <b>{order.settings.index_percentage}</b> <br />
                                                                                                        {!order.admin_bot? <>Stop Loss Activate % : <b>{order.settings.trigger_percentage}</b> <br /></>:''}
                                                                                                        Market Stop Loss % {order.settings.combined_sl?" (Combined)":""}: <b>{order.settings.stop_loss_percentage}</b> <br />
                                                                                                        Index Price Range % for Stop Loss: <b>{order.settings.stop_loss_index_percentage}</b> <br />
                                                                                                        Trailing Take Profit  % {order.settings.combined_trailing_profit?" (combined)":""}: <b>{order.settings.min_take_profit_percentage ? order.settings.min_take_profit_percentage : 0}</b> <br />
                                                                                                        Trailing Stop Price %: <b>{order.settings.trailing_take_profit_percentage ? order.settings.trailing_take_profit_percentage : 0}</b> <br />
                                                                                                        Take Profit %: <b>{order.settings.take_profit_percentage}</b> <br />
                                                                                                        Quantity: <b>{order.settings.quantity}</b> <br />
                                                                                                        Waiting time to fill Qty (H : M): <b>{order.settings.waitTimeForFillAllQty ? timeFormat(order.settings.waitTimeForFillAllQty) : '--'}</b> <br />
                                                                                                        {
                                                                                                            order.settings.roll_status  === 1 ?
                                                                                                            <>
                                                                                                        When to Roll? (H : M): <b>{order.settings.Roll_time_clock && order.settings.Roll_time_clock ? (roll_time_clock[0]+ 'h ' +roll_time_clock[1] + 'm') : '--'}</b> <br />
                                                                                                        Distance from index (Roll): <b>{order.settings.index_distance}</b> <br />
                                                                                                        Premium Roll % (Roll): <b>{order.settings.roll_percentage}</b> <br />
                                                                                                            </>: '' 
                                                                                                        }
                                                                                                        Automatic market fill: <b>{order.settings.autoMarketFill === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        Universal stop: <b>{order.settings.universal_stop === 1 ? 'true' : 'false'}</b> <br />
                                                                                                        {order.settings.reentry_enabled ? (
                                                                                                                <>Re-Entry Enabled: <b>true</b><br/>Waiting time for Re-Entry: <b>{order.settings.wait_time_reentry ?timeFormat(order.settings.wait_time_reentry) :"--" }</b></>
                                                                                                            ) : <>Re-Entry Enabled: <b>false</b><br/></>}
                                                                                                    {order.settings.combined_sl_percentage ? <>Combined stop loss: <b>{Number(order.settings.combined_sl_percentage)}%</b><br/></>: ''} 
                                                                                                    {order.settings.combined_trailing_profit_percentage ? <>Combined trailing take profit: <b>{Number(order.settings.combined_trailing_profit_percentage)}%</b><br/></> : ''} 
                                                                                                    {order.settings.combined_trailing_stop_price_percentage ? <>Combined trailing stop price: <b>{Number(order.settings.combined_trailing_stop_price_percentage)}%</b><br/></> : ''} 
                                                                                                    {order.settings.combined_take_profit_percentage ? <>Combined take profit: <b>{Number(order.settings.combined_take_profit_percentage)}%</b><br/></> : ''} 
                                                                                                    </PopoverBody>
                                                                                                </Popover>
                                                                                            </div>
                                                                                        <div className="text-primary">Expiry: <span className="expiry-countdown font-size-11" ><Countdown date={order.expiry_date} renderer={counterRenderer} /></span></div>
                                                                                        
                                                                                        
                                                                                        {order.settings.current_index_price ? <div className="align-text-bottom created-date">Index Price: {order.settings.current_index_price}</div> : ''}
                                                                                        {order.region ? <div className="align-text-bottom created-date">Region: India</div> : <div className="align-text-bottom created-date">Region: Global</div>}
                                                                                        <div className="font-size-12"><i className="far fa-calendar-alt text-primary me-1"></i>Created at: {formatUTCDateTime(order.settings.created_at)}</div>
                                                                                        {(order.settings.automatic_bot && order.settings.automatic_bot ==1) ?  <Badge pill color="warning" className="me-1">Automated Admin Bot</Badge> : ''}
                                                                                        {order.settings.reentry_order?<><Badge pill color="success" className="me-1">Re-Entry Order</Badge></> :''}
                                                                                        </div>
                                                                                        
                                                                                    </td>
                                                                                    : ''}
                                                                                    {index === 0 ?
                                                                                    <td rowSpan={row.length}>
                                                                                        {order.profile ?
                                                                                        <p className="font-size-11">{capitalizeFirst(order.profile.first_name) } <br /> {capitalizeFirst(order.profile.last_name)}</p> : <p></p>}
                                                                                      
                                                                                    </td>
                                                                                    : ''}
                                                                                <td>
                                                                                    <Badge
                                                                                        color={order.direction === "buy" ? "success" : "danger"}
                                                                                        style={{ "textTransform": "uppercase" }}
                                                                                    >
                                                                                        {order.direction}
                                                                                    </Badge>
                                                                                </td>
                                                                                <td>
                                                                                    <Badge color={order.direction === "buy" ? "success" : "danger"}>
                                                                                        {order.symbol}
                                                                                    </Badge>
                                                                                    {market_action != '' ?
                                                                                        <div>
                                                                                            <span className="text-danger font-size-11 "> {market_action} </span>
                                                                                        </div>
                                                                                        : ''}
                                                                                        {order.reentry_stopped ? <div><span className="font-size-10 ">Re-Entry Stopped</span></div> : ""}
                                                                                </td>

                                                                                <td><h6 className={order.direction === "buy" ? "text-success" : "text-danger"}>{Number(order.price).toFixed(2)}</h6></td>
                                                                                <td><div className="text-success font-size-10">
                                                                                <span className="text-secondary"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Stop Loss Activate: {getPrices(order, "trigger")}</span><br />
                                                                                <span className="text-secondary"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Market Stop Loss{order.settings.combined_sl?" (Combined)":""}: {getPrices(order, "stop_loss")}</span><br />
                                                                                <span className="text-secondary"><i className="fa fa-caret-right  font-size-16 align-middle text-primary me-1"></i>Take profit Price: {getPrices(order, "take_profit")}</span>
                                                                                 
                                                                                </div>
                                                                                </td>
                                                                                <td>{Number(order.filled_quantity).toFixed(0)}/{Number(order.settings.quantity).toFixed(0)}</td>
                                                                                <td className="font-size-11">{order.order_id !== "" ? order.order_id : "Nil"}</td>
                                                                                <td> <span dangerouslySetInnerHTML={{ __html: getOrderStatusStyle(order) }}></span></td>
                                                                                <td><small title={order.realized_pnl ? order.realized_pnl : booked_pnl !== '...' ? booked_pnl : ''}>{order.realized_pnl ? order.realized_pnl + `${order.region == 1 ?' USD':" USDT"}` : booked_pnl !== '...' ? parseFloat(booked_pnl).toFixed(4) + `${order.region == 1 ?' USD':" USDT"}` : '...'}</small></td>
                                                                               
                                                                            </tr>
                                                                         )
                                                                        })                          
                                                                    )})}    
                                                                </tbody>
                                                              )
                                                            })
                                                        ) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ "textAlign": "center" }}>
                                                                    No orders found
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                                {/*********/}
                                            </div>
                                       
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*Listing orders */}
           
        </React.Fragment>
    );
}
export default ListOptions;
